export const getters = {
  semesters(state) {
    return state.semesters;
  },
  semestersById(state) {
    const byId = {};
    state.semesters.forEach((e) => (byId[e.id] = e));
    return byId;
  },
};
