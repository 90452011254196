import tripTypeApi from '@/apis/tripType';

export const GET_TRIP_TYPES = 'getTripTypes';
export const GET_TRIP_TYPE = 'getTripType';
export const SAVE_TRIP_TYPE = 'saveTripType';
export const DELETE_TRIP_TYPES = 'deleteTripTypes';

export const actions = {
  async [GET_TRIP_TYPES]({ commit }, options) {
    const result = await tripTypeApi.getTripTypes(options);
    commit('setTripTypes', result);
  },
  async [GET_TRIP_TYPE](_, id) {
    const result = await tripTypeApi.getTripType(id);
    return result;
  },
  async [SAVE_TRIP_TYPE](_, tripType) {
    return await tripTypeApi.saveTripType(tripType);
  },
  async [DELETE_TRIP_TYPES](_, id) {
    return await tripTypeApi.deleteTripTypes(id);
  },
};
