export const state = {
  tripRequestConfig: {},
  driverConfig: {},
  invoiceConfig: {},
  notifyConfig: {},
  vehicleOwnerConfig: {},
  cffConfig: {},
  customFormFieldTypes: {},
  budgetCodeConfig: {},
  calendarConfig: {},
  leadDaysConfig: {},
  specialTravelNeeds: [],
  specialIndicators: [],
  districtWideRates: [],
  fuelPrices: [],
  additionalInvoiceCosts: [],
  permissions: {},
  tripDateTimes: [],
};
