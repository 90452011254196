/** @typedef {import('vue-router').Route} Route */
/** @typedef {import('vue-router').NavigationGuardNext} NavigationGuardNext */

import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

import { isUserAccessAllowed } from '@/util/roles';
import { showDevFeatures } from '@/util';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
  },
  {
    path: '/development',
    name: 'Development',
    component: () => import('../views/Development/Development.vue'),
    meta: {
      checkRole: ['superAdmin'],
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    components: { default: () => import('../views/Dashboard') },
    meta: {
      checkRole: [
        'approver',
        'childNutritionistDirector',
        'finance',
        'limitedAdmin',
        'nurse',
        'nutritionist',
        'overnightOOSOnly',
        'readOnly',
        'requester',
        'schoolFinance',
        'siteAdmin',
        'siteAdminReadOnly',
        'siteAdminReportsOnly',
        'specialNeedsVehicleOwner',
        'superAdmin',
        'transportationAdmin',
        'vehicleOwner',
        'fundingManager',
        'driver',
      ],
      title: 'Dashboard',
    },
  },
  {
    path: '/trip-requests',
    name: 'Trip Requests',
    components: {
      default: () => import('../views/Trips/TripList.vue'),
    },
    meta: {
      title: 'Trips',
    },
  },
  {
    path: '/trip-requests/report/:uuid',
    name: 'Trip Reports',
    components: {
      default: () => import('../components/Report.vue'),
    },
    meta: {
      checkYellowfin: true,
    },
  },
  {
    path: '/trip-request/:id',
    name: 'Trip Request Detail',
    components: {
      default: () => import('../views/TripRequestForm/Detail.vue'),
    },
    props: { default: true, submenu: false },
    meta: {
      checkTrip: true,
      title: `Trip #`,
    },
  },
  {
    path: '/trip-request/:id/print',
    name: 'Printable Trip Request',
    components: {
      default: () => import('../views/TripRequestForm/Print.vue'),
    },
    props: { default: true },
    meta: {
      checkTrip: true,
    },
  },
  {
    path: '/assignments',
    name: 'Assignments',
    components: {
      default: () => import('../views/Assignments/List.vue'),
    },
    meta: {
      checkRole: [
        'childNutritionistDirector',
        'finance',
        'limitedAdmin',
        'readOnly',
        'requester',
        'schoolFinance',
        'siteAdmin',
        'siteAdminReadOnly',
        'specialNeedsVehicleOwner',
        'superAdmin',
        'transportationAdmin',
        'vehicleOwner',
        'fundingManager',
        'approver',
      ],
      title: 'Assignments',
    },
  },
  {
    path: '/assignments/report/:uuid',
    name: 'Assignment Reports',
    components: {
      default: () => import('../components/Report.vue'),
    },
    meta: {
      checkYellowfin: true,
    },
  },
  {
    path: '/invoices',
    name: 'Invoices',
    components: {
      default: () => import('../views/Invoices/InvoiceDashboard.vue'),
    },
    meta: {
      checkRole: [
        'superAdmin',
        'transportationAdmin',
        'limitedAdmin',
        'finance',
        'vehicleOwner',
        'specialNeedsVehicleOwner',
        'siteAdmin',
        'schoolFinance',
        'fundingManager',
        'approver',
      ],
      title: 'Invoices',
    },
  },
  {
    path: '/tables',
    name: 'Setup Tables',
    components: {
      default: () => import('../views/Tables/index.vue'),
    },
    meta: {
      checkRole: ['superAdmin', 'transportationAdmin', 'limitedAdmin', 'driverTableOnly'],
      checkUserPermissions: { setupTables: true },
    },
    children: [
      {
        path: 'users',
        name: 'users',
        component: () => import('../views/Tables/UserTable.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin'],
          title: 'Users',
        },
      },
      {
        path: 'roster',
        name: 'roster',
        component: () => import('../views/Tables/RosterTable.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin'],
          checkAppPermissions: { elite: true },
          title: 'Roster',
        },
      },
      {
        path: 'adult-roster',
        name: 'adult-roster',
        component: () => import('../views/Tables/RosterAdultTable.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin'],
          checkAppPermissions: { elite: true },
        },
      },
      {
        path: 'trip-types',
        name: 'trip-types',
        component: () => import('../views/Tables/TripTypeTable.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin', 'limitedAdmin'],
          checkUserPermissions: { setupTables: true },
          title: 'Trip Types',
        },
      },
      {
        path: 'trip-events',
        name: 'trip-events',
        component: () => import('../views/Tables/TripEventTable.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin', 'limitedAdmin'],
          checkUserPermissions: { setupTables: true },
          title: 'Trip Events',
        },
      },
      {
        path: 'special-indicators-needs',
        name: 'special-indicators-needs',
        component: () => import('../views/Tables/SpecialIndicatorsNeeds.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin', 'limitedAdmin'],
          checkUserPermissions: { setupTables: true },
          title: 'Special Indicators',
        },
      },
      {
        path: 'destinations',
        name: 'destinations',
        component: () => import('../views/Tables/DestinationTable.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin', 'limitedAdmin'],
          checkUserPermissions: { setupTables: true },
          title: 'Destinations',
        },
      },
      {
        path: 'locations',
        name: 'locations',
        component: () => import('../views/Tables/LocationTable.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin', 'limitedAdmin'],
          checkUserPermissions: { setupTables: true },
          title: 'Locations',
        },
      },
      {
        path: 'funding-sources',
        name: 'funding-sources',
        component: () => import('../views/Tables/FundingSourceTable.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin', 'limitedAdmin'],
          checkUserPermissions: { setupTables: true },
          title: 'Funding Sources',
        },
      },
      {
        path: 'budget-codes',
        name: 'budget-codes',
        component: () => import('../views/Tables/BudgetCodeTable.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin', 'limitedAdmin'],
          checkUserPermissions: { setupTables: true },
          title: 'Budget Codes',
        },
      },
      {
        path: 'vehicles',
        name: 'vehicles',
        component: () => import('../views/Tables/VehicleTable.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin', 'limitedAdmin'],
          checkUserPermissions: { setupTables: true },
          title: 'Vehicles',
        },
      },
      {
        path: 'vehicle-types',
        name: 'vehicle-types',
        component: () => import('../views/Tables/VehicleTypeTable.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin', 'limitedAdmin'],
          checkUserPermissions: { setupTables: true },
          title: 'Vehicle Types',
        },
      },
      {
        path: 'vehicle-condition-rates',
        name: 'vehicle-condition-rates',
        component: () => import('../views/Tables/VehicleConditionRatesTable.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin', 'limitedAdmin'],
          checkUserPermissions: { setupTables: true },
          title: 'Vehicle Condition Rates',
        },
      },
      {
        path: 'approved-charters',
        name: 'approved-charters',
        component: () => import('../views/Tables/ApprovedCharterTable.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin', 'limitedAdmin'],
          checkUserPermissions: { setupTables: true },
          title: 'Approved Charters',
        },
      },
      {
        path: 'contractors',
        name: 'contractors',
        component: () => import('../views/Tables/ContractorTable.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin', 'limitedAdmin'],
          checkUserPermissions: { setupTables: true },
        },
      },
      {
        path: 'rental-dealership-agencies',
        name: 'rental-dealership-agencies',
        component: () => import('../views/Tables/RentalDealershipTable.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin', 'limitedAdmin'],
          checkUserPermissions: { setupTables: true },
          title: 'Contractors',
        },
      },
      {
        path: 'drivers',
        name: 'drivers',
        component: () => import('../views/Tables/DriverTable.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin', 'limitedAdmin', 'driverTableOnly'],
          checkUserPermissions: { setupTables: true },
          title: 'Drivers',
        },
      },
      {
        path: 'mechanics',
        name: 'mechanics',
        component: () => import('../views/Tables/MechanicTable.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin', 'limitedAdmin', 'driverTableOnly'],
          checkUserPermissions: { setupTables: true },
          title: 'Mechanics',
        },
      },
      {
        path: 'driver-logs',
        name: 'driver-logs',
        component: () => import('../views/Tables/DriverLog.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin', 'limitedAdmin'],
          checkUserPermissions: { setupTables: true },
          title: 'Driver Logs',
        },
      },
      {
        path: 'additional-vehicle-charges',
        name: 'additional-vehicle-charges',
        component: () => import('../views/Tables/AdditionalVehicleChargesTable.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin', 'limitedAdmin'],
          checkUserPermissions: { setupTables: true },
        },
      },
      {
        path: 'additional-invoice-costs',
        name: 'additional-invoice-costs',
        component: () => import('../views/Tables/AdditionalInvoiceCostsTable.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin', 'limitedAdmin'],
          checkUserPermissions: { setupTables: true },
          title: 'Additional Charges',
        },
      },
      {
        path: 'third-party-payment-options',
        name: 'third-party-payment-options',
        component: () => import('../views/Tables/ThirdPartyPaymentOptionsTable.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin', 'limitedAdmin'],
          checkUserPermissions: { setupTables: true },
          title: 'Payment Options',
        },
      },
    ],
  },
  {
    path: '/driver',
    name: 'Driver Assignments',
    components: {
      default: () => import('../views/Driver/List.vue'),
    },
    meta: {
      isDevFeature: true,
      title: 'Driver Portal',
    },
    beforeEnter: (to, from, next) => {
      const { roles } = store.getters['user/me'];
      const hasAccess = isUserAccessAllowed({ module: 'Driver', userRoles: roles });
      if (!hasAccess) {
        next('/forbidden');
        return;
      }

      next();
    },
  },
  {
    path: '/driver/:id',
    name: 'Driver Assignment Details',
    components: {
      default: () => import('../views/Driver/Assignment.Details.vue'),
      submenu: () => import('../views/Driver/Menu.vue'),
    },
    props: { default: true, submenu: true },
  },
  {
    path: '/config',
    name: 'Configuration',
    components: {
      default: () => import('../views/Config/index.vue'),
    },
    meta: {
      checkRole: ['superAdmin', 'transportationAdmin', 'limitedAdmin'],
      checkUserPermissions: { settings: true },
    },
    children: [
      {
        path: 'trip-request/labels',
        name: 'trip-request/labels',
        component: () => import('../views/Config/TripRequestForm/Labels.vue'),
        meta: { title: 'Settings' },
      },
      {
        path: 'trip-request/messages',
        name: 'trip-request/messages',
        component: () => import('../views/Config/TripRequestForm/Messages.vue'),
        meta: { title: 'Settings' },
      },
      {
        path: 'trip-request/display-hide',
        name: 'trip-request/display-hide',
        component: () => import('../views/Config/TripRequestForm/DisplayHide.vue'),
        meta: { title: 'Settings' },
      },
      {
        path: 'trip-request/custom-form-fields',
        name: 'trip-request/custom-form-fields',
        component: () => import('../views/Config/TripRequestForm/CustomFormFields.vue'),
        meta: { title: 'Settings' },
      },
      {
        path: 'trip-request/dates-times',
        name: 'trip-request/dates-times',
        component: () => import('../views/Config/TripRequestForm/DatesTimes.vue'),
        meta: { title: 'Settings' },
      },
      {
        path: 'trip-request/chaperone-rules',
        name: 'trip-request/chaperone-rules',
        component: () => import('../views/Config/TripRequestForm/ChaperoneRules.vue'),
        meta: { title: 'Settings' },
      },
      {
        path: 'trip-request/trip-ticket-permission-slip',
        name: 'trip-request/trip-ticket-permission-slip',
        component: () => import('../views/Config/TripRequestForm/TripTicketPermissionSlip.vue'),
        meta: { title: 'Settings' },
      },
      {
        path: 'trip-request/trip-estimator',
        name: 'trip-request/trip-estimator',
        component: () => import('../views/Config/TripRequestForm/TripEstimator'),
        meta: { title: 'Settings' },
      },
      {
        path: 'trip-request/other',
        name: 'trip-request/other',
        component: () => import('../views/Config/TripRequestForm/Other.vue'),
        meta: { title: 'Settings' },
      },
      {
        path: 'roles',
        name: 'roles',
        component: () => import('../views/Config/Roles.Permissions.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin'],
        },
      },
      {
        path: 'semesters',
        name: 'semesters',
        component: () => import('../views/Config/Semesters.vue'),
        meta: { title: 'Settings' },
      },
      {
        path: 'fiscal-years',
        name: 'fiscal-years',
        component: () => import('../views/Config/FiscalYears.vue'),
        meta: { title: 'Settings' },
      },
      {
        path: 'approval-levels',
        name: 'approval-levels',
        component: () => import('../views/Config/ApprovalLevel.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin'],
        },
      },
      {
        path: 'budget-code',
        name: 'budget-code',
        component: () => import('../views/Config/BudgetCodeStructure.vue'),
        meta: {
          checkRole: ['superAdmin'],
        },
      },
      {
        path: 'calendars',
        name: 'calendars',
        component: () => import('../views/Config/Calendars.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin'],
        },
      },
      {
        path: 'notifications',
        name: 'notifications',
        component: () => import('../views/Config/Notifications.vue'),
        meta: {
          checkRole: ['superAdmin', 'transportationAdmin'],
        },
      },
      {
        path: 'driver',
        name: 'driver',
        component: () => import('../views/Config/Drivers.vue'),
        meta: { title: 'Settings' },
      },
      {
        path: 'vehicle-owners',
        name: 'vehicle-owners',
        component: () => import('../views/Config/VehicleOwners.vue'),
        meta: { title: 'Settings' },
      },
      {
        path: 'invoice',
        name: 'invoice',
        component: () => import('../views/Config/Invoice.vue'),
      },
      {
        path: 'invoice/invoice-custom-form-fields',
        name: 'invoice-custom-form-fields',
        component: () => import('../views/Config/InvoiceCustomFormFields.vue'),
      },
    ],
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    component: () => import('../views/Config/MyAccount.vue'),
  },
  {
    path: '/ezr-sync',
    name: 'EZR Sync',
    component: () => import('../views/System/EZRSync'),
    meta: {
      checkRouting: true,
    },
  },
  {
    path: '/import',
    components: {
      default: () => import('../views/Config/Import/index.vue'),
    },
    meta: {
      checkRole: ['superAdmin'],
    },
    children: [
      {
        path: '',
        name: 'csv',
        component: () => import('../views/Config/Import/CSV.vue'),
      },
      {
        path: 'traversa',
        name: 'traversa',
        component: () => import('../views/Config/Import/Traversa.vue'),
      },
    ],
  },
  {
    path: '/audit',
    name: 'Audit History',
    component: () => import('../views/System/Audit.vue'),
  },
  {
    path: '/calendars',
    name: 'Calendars',
    components: {
      default: () => import('../views/Calendar'),
    },
    meta: { checkCalendar: true },
  },
  {
    path: '/admin',
    name: 'admin',
    meta: {
      checkRole: ['superAdmin'],
    },
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: () => import('../views/Errors/Forbidden.vue'),
  },
];

const client = store.getters['app/client'];
const router = new VueRouter({
  routes,
  base: `${client}#`,
});

/**
 * Before each handler
 * @param {Route} to
 * @param {Route} from
 * @param {NavigationGuardNext} next
 * @return {void}
 */
const beforeEachHandler = async (to, from, next) => {
  const me = store.getters['user/me'];
  const clientConfig = store.getters['app/clientConfig'];

  if (!clientConfig || !Object.keys(clientConfig).length) {
    await store.dispatch('app/getClientConfig', client);
  }

  if (to.fullPath == '/') {
    if (client == 'admin' && me.isTTAdmin) return next();
    else return next(getDefaultPath());
  }

  if (!Object.keys(to).length || to.path === from.path) return next();

  let ok = true;

  if (to.matched.length > 1) {
    for (let index = 0; index < to.matched.length; index++) {
      ok = await metaMatcher(me, to.matched[index]);

      if (!ok) break;
    }
  } else {
    ok = await metaMatcher(me, to);
  }

  if (ok) return next();

  return next(getDefaultPath());
};

/**
 * Get default path
 * @return {string}
 */
const getDefaultPath = () => {
  const me = store.getters['user/me'];

  if (!me) return '/';

  if (me.roles && me.roles.length == 1 && me.is.driverTableOnly && !me.is.approver) return '/tables/drivers';
  else if (me.roles && me.roles.length == 1 && me.is.reportOnly && !me.is.approver) return '/reports';
  else return '/dashboard';
};

/**
 * Meta Matcher
 * @param {User.me} me
 * @param {Route} to
 * @return {Promise<boolean>}
 */
const metaMatcher = async (me, to) => {
  const { meta = {} } = to;

  if (meta.isDevFeature) {
    if (!showDevFeatures()) return false;
  }

  let hasRole = !meta.checkRole;
  let hasTripPermission = !meta.checkTrip;
  let hasUserPermission = me.is.superAdmin || !meta.checkUserPermissions;
  let hasCalendarPermission = !meta.checkCalendar;
  let hasYellowfinPermission = !meta.checkYellowfin;

  let ok = hasRole && hasTripPermission && hasUserPermission && hasCalendarPermission && hasYellowfinPermission;

  // if (ok) return ok;

  if (meta.checkRole) {
    hasRole = false;

    for (let role of meta.checkRole) {
      if (!me.is[role]) continue;

      hasRole = true;
      break;
    }

    ok = hasRole;
  }

  if (meta.checkTrip) {
    if (to.params.id != 0) {
      const tripRequests = store.getters['tripRequest/tripRequests'];

      let trip = tripRequests.find((e) => e.id == to.params.id);

      if (!trip) {
        ({ tripRequest: trip } = await store.dispatch('tripRequest/getTripRequest', to.params.id));
      }

      if (trip && trip.permissions && trip.permissions.canView) hasTripPermission = true;

      ok = hasTripPermission;
    } else ok = true;
  }

  const laRole = me.roles?.find((e) => e.roleId == 3);

  if (!hasUserPermission && meta.checkUserPermissions) {
    const canViewSettings = (laRole && laRole.settings) || me.can.config.admin;
    const canViewSetupTables = (laRole && laRole.setupTables) || me.can.view.tables;
    if (meta.checkUserPermissions.settings && canViewSettings) hasUserPermission = true;
    if (meta.checkUserPermissions.setupTables && canViewSetupTables) hasUserPermission = true;

    ok = hasUserPermission;
  }

  const clientConfig = store.getters['app/clientConfig'];
  if (meta.checkAppPermissions) {
    const tier = clientConfig.data.modules.tier ? clientConfig.data.modules.tier : 'pro';

    ok = tier === 'elite';
  }

  if (meta.checkCalendar) {
    let calConf = store.getters['config/calendarConfig'];

    if (!calConf.showCalendarTab) calConf = await store.dispatch('config/getConfig', 'calendar');

    ok =
      (calConf.showCalendarTab.includes(9) && me.is.approver) ||
      me.roles.map((e) => e.roleId).some((r) => calConf.showCalendarTab.includes(r));
  }

  if (meta.checkYellowfin) {
    ok = !!me.yellowfinId;
  }

  if (meta.checkRouting) {
    ok = !!clientConfig.data.modules.routing;
  }

  return ok;
};

router.beforeResolve((to, from, next) => {
  const site = 'EZActivityTrips';
  if (to.meta && to.meta.title) {
    const number = to.path.includes('trip-request/') ? to.path.split('/').slice(-1) : '';
    document.title = `${to.meta.title}${number} | ${site}`;
  } else {
    document.tile = site;
  }
  next();
});

router.beforeEach(beforeEachHandler);

export default router;
