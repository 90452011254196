import Vue from 'vue';
import Vuex from 'vuex';

import App from './modules/App/store';
import ApprovalLevel from './modules/ApprovalLevel/store';
import AdditionalTransportation from './modules/AdditionalTransportation/store';
import Approver from './modules/Approver/store';
import Assignment from './modules/Assignment/store';
import Attachment from './modules/Attachment/store';
import BudgetCode from './modules/BudgetCode/store';
import Calendar from './modules/Calendar/store';
import Config from './modules/Config/store';
import Destination from './modules/Destination/store';
import Driver from './modules/Driver/store';
import DriverLog from './modules/DriverLog/store';
import FiscalYear from './modules/FiscalYear/store';
import FundingSource from './modules/FundingSource/store';
import Geo from './modules/Geo/store';
import Location from './modules/Location/store';
import Roster from './modules/Roster/store';
import Mechanic from './modules/Mechanic/store';
import Semester from './modules/Semester/store';
import Stop from './modules/Stop/store';
import System from './modules/System/store';
import ThirdPartyPayment from './modules/ThirdPartyPayment/store';
import TripEvent from './modules/TripEvent/store';
import TripRequest from './modules/TripRequest/store';
import TripType from './modules/TripType/store';
import User from './modules/User/store';
import Vehicle from './modules/Vehicle/store';
import VehicleType from './modules/VehicleType/store';
import VehicleConditionRate from './modules/VehicleConditionRate/store';
import AdditionalVehicleCharge from './modules/AdditionalVehicleCharge/store';
import AdditionalInvoiceCost from './modules/AdditionalInvoiceCost/store';
import Zone from './modules/Zone/store';
import Invoice from './modules/Invoice/store';
import InvoicePayment from './modules/InvoicePayment/store';
import Staff from './modules/Staff/store';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    app: App,
    approvalLevel: ApprovalLevel,
    additionalTransportation: AdditionalTransportation,
    approver: Approver,
    assignment: Assignment,
    attachment: Attachment,
    budgetCode: BudgetCode,
    calendar: Calendar,
    config: Config,
    destination: Destination,
    driver: Driver,
    driverLog: DriverLog,
    fiscalYear: FiscalYear,
    fundingSource: FundingSource,
    geo: Geo,
    location: Location,
    roster: Roster,
    mechanic: Mechanic,
    semester: Semester,
    stop: Stop,
    system: System,
    thirdPartyPayment: ThirdPartyPayment,
    tripEvent: TripEvent,
    tripRequest: TripRequest,
    tripType: TripType,
    user: User,
    vehicle: Vehicle,
    vehicleType: VehicleType,
    vehicleConditionRate: VehicleConditionRate,
    additionalVehicleCharge: AdditionalVehicleCharge,
    additionalInvoiceCost: AdditionalInvoiceCost,
    zone: Zone,
    invoice: Invoice,
    invoicePayment: InvoicePayment,
    staff: Staff,
  },
  strict: debug,
});
