import adminApi from '@/apis/admin';
import attachmentApi from '@/apis/attachment';

export const APP_LOADING = 'appLoading';
export const GET_CLIENT_CONFIG = 'getClientConfig';
export const CHECK_TEMPLATES = 'checkTemplates';

export const CLEAR_CONFIRMATION = 'clearConfirmation';
export const CLEAR_SNACKBAR = 'clearSnackbar';
export const TRIGGER_CONFIRMATION = 'triggerConfirmation';
export const TRIGGER_SNACKBAR = 'triggerSnackbar';

export const actions = {
  async [GET_CLIENT_CONFIG]({ commit }, name) {
    const c = await adminApi.getClient(name);
    commit('setClientConfig', c);
  },
  async [CHECK_TEMPLATES]({ commit }) {
    const tt = await attachmentApi.checkRecordsAttachments('tripticket');
    commit('setHasTripTicketTemplate', !!tt.length);
    const ps = await attachmentApi.checkRecordsAttachments('permissionslip');
    commit('setHasPermissionSlipEnglishTemplate', !!ps.filter((e) => e == 1).length);
    commit('setHasPermissionSlipSpanishTemplate', !!ps.filter((e) => e == 2).length);
    const te = await attachmentApi.checkRecordsAttachments('tripestimate');
    commit('setHasTripEstimateTemplate', !!te.length);
  },

  async [CLEAR_CONFIRMATION]({ commit }) {
    commit('setConfirmation', { messsage: '' });
  },
  async [CLEAR_SNACKBAR]({ commit }) {
    commit('setSnackbar', { messsage: '' });
  },
  async [TRIGGER_CONFIRMATION]({ commit }, confirmationOptions) {
    commit('setConfirmation', confirmationOptions);
  },
  async [TRIGGER_SNACKBAR]({ commit }, snackbarOptions) {
    commit('setSnackbar', snackbarOptions);
  },
};
