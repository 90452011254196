import vehicleConditionRatesApi from '@/apis/vehicleConditionRate';

export const GET_CONDITIONS = 'getVehicleConditionRates';
export const GET_CONDITION = 'getVehicleConditionRate';
export const SAVE_CONDITION = 'saveVehicleConditionRate';
export const DELETE_CONDITIONS = 'deleteVehicleConditionRates';

export const actions = {
  async [GET_CONDITIONS]({ commit }, options) {
    const result = await vehicleConditionRatesApi.getVehicleConditionRates(options);
    commit('setVehicleConditionRate', result);
  },
  async [GET_CONDITION](_, id) {
    const result = await vehicleConditionRatesApi.getVehicleConditionRate(id);
    return result;
  },
  async [SAVE_CONDITION](_, vehicleConditionRate) {
    return await vehicleConditionRatesApi.saveVehicleConditionRate(vehicleConditionRate);
  },
  async [DELETE_CONDITIONS](_, ids) {
    return await vehicleConditionRatesApi.deleteVehicleConditionRates(ids);
  },
};
