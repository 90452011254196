import thirdPartyPaymentApi from '@/apis/thirdPartyPayment';

export const actions = {
  async getThirdPartyPayments({ commit }, options) {
    const result = await thirdPartyPaymentApi.getThirdPartyPayments(options);
    commit('setThirdPartyPayment', result);
  },
  async saveThirdPartyPayment({ dispatch, commit },thirdPartyPayment) {
    try {
      commit('setLoading', true);
      const response =  await thirdPartyPaymentApi.saveThirdPartyPayment(thirdPartyPayment);
      dispatch('getThirdPartyPayments', { forTable: true });
      return response;
    } finally {
      commit('setLoading', false);
    }
  },
  async deleteThirdPartyPayments({ commit, dispatch },ids) {
    try {
      commit('setLoading', true);
      const response = await thirdPartyPaymentApi.deleteThirdPartyPayments(ids);
      dispatch('getThirdPartyPayments', { forTable: true });
      return response;
    } finally {
      commit('setLoading', false);
    }
  },
};
