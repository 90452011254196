export default {
  getAdditionalInvoiceCosts,
  saveAdditionalInvoiceCost,
  deleteAdditionalInvoiceCosts,
};

import api from '@/shared/api.service';

async function getAdditionalInvoiceCosts(options) {
  return await api.get('/api/additional-invoice-cost' + (options && options.forTable ? '?table=1' : ''));
}

async function saveAdditionalInvoiceCost(params) {
  return await api.post('/api/additional-invoice-cost', params);
}

async function deleteAdditionalInvoiceCosts(ids) {
  return await api.delete('/api/additional-invoice-cost', {
    params: { ids },
  });
}
