import configApi from '@/apis/config';

export const GET_CONFIG = 'getConfig';
export const GET_CONFIGS = 'getConfigs';
export const SET_CONFIG = 'setConfig';

export const GET_TRIP_DATE_TIMES = 'getTripDateTimes';
export const SAVE_TRIP_DATE_TIME = 'saveTripDateTime';
export const DELETE_TRIP_DATE_TIMES = 'deleteTripDateTimes';

export const GET_SPECIAL_INDICATORS = 'getSpecialIndicators';
export const SAVE_SPECIAL_INDICATOR = 'saveSpecialIndicator';
export const DELETE_SPECIAL_INDICATORS = 'deleteSpecialIndicators';

export const GET_SPECIAL_TRAVEL_NEEDS = 'getSpecialTravelNeeds';
export const SAVE_SPECIAL_TRAVEL_NEED = 'saveSpecialTravelNeed';
export const DELETE_SPECIAL_TRAVEL_NEEDS = 'deleteSpecialTravelNeeds';

export const GET_DISTRICT_WIDE_RATES = 'getDistrictWideRates';
export const SAVE_DISTRICT_WIDE_RATE = 'saveDistrictWideRate';
export const DELETE_DISTRICT_WIDE_RATES = 'deleteDistrictWideRates';

export const GET_FUEL_PRICES = 'getFuelPrices';
export const GET_FUEL_PRICES_BY_DATE = 'getFuelPricesByDate';
export const SAVE_FUEL_PRICE = 'saveFuelPrice';
export const DELETE_FUEL_PRICES = 'deleteFuelPrices';

export const GET_ADDITIONAL_INVOICE_COSTS = 'getAdditionalInvoiceCosts';
export const SAVE_ADDITIONAL_INVOICE_COST = 'saveAdditionalInvoiceCost';
export const DELETE_ADDITIONAL_INVOICE_COSTS = 'deleteAdditionalInvoiceCosts';

const configMap = {
  driver: 'setDriverConfig',
  invoice: 'setInvoiceConfig',
};

export const actions = {
  async [GET_CONFIG]({ commit }, name) {
    const result = await configApi.getConfig(name);
    const configName = name.charAt(0).toUpperCase() + name.slice(1);
    commit(`set${configName}Config`, result);
    return result;
  },
  async [GET_CONFIGS]({ commit }) {
    const configs = [
      'budgetCode',
      'calendar',
      'customFormFields',
      'customFormFieldTypes',
      'districtWideRates',
      'driver',
      'fuelPrices',
      'invoice',
      'leadDays',
      'notify',
      'permissions',
      'specialIndicators',
      'specialTravelNeeds',
      'tripDateTimes',
      'tripRequestForm',
      'vehicleOwner',
    ];

    const allConfigs = await configApi.getAllConfigs({ configs });

    commit('setBudgetCodeConfig', allConfigs.budgetCode);
    commit('setCalendarConfig', allConfigs.calendar);
    commit('setCustomFormFieldsConfig', allConfigs.customFormFields);
    commit('setCustomFormFieldTypes', allConfigs.customFormFieldTypes);
    commit('setDistrictWideRates', allConfigs.districtWideRates);
    commit('setDriverConfig', allConfigs.driver);
    commit('setFuelPrices', allConfigs.fuelPrices);
    commit('setInvoiceConfig', allConfigs.invoice);
    commit('setLeadDaysConfig', allConfigs.leadDays);
    commit('setNotifyConfig', allConfigs.notify);
    commit('setPermissionsConfig', allConfigs.permissions);
    commit('setSpecialIndicators', allConfigs.specialIndicators);
    commit('setSpecialTravelNeeds', allConfigs.specialTravelNeeds);
    commit('setTripDateTimes', allConfigs.tripDateTimes);
    commit('setTripRequestFormConfig', allConfigs.tripRequestForm);
    commit('setVehicleOwnerConfig', allConfigs.vehicleOwner);
  },

  async [SET_CONFIG]({ commit }, { name, value }) {
    const res = await configApi.setConfig(name, value);

    const configName = configMap[name];
    if (res.done && configName) commit(configName, value);

    return res;
  },

  async [GET_TRIP_DATE_TIMES](_, type) {
    return await configApi.getTripDateTimes(type);
  },
  async [SAVE_TRIP_DATE_TIME](_, ttm) {
    return await configApi.saveTripDateTime(ttm);
  },
  async [DELETE_TRIP_DATE_TIMES](_, ids) {
    return await configApi.deleteTripDateTimes(ids);
  },

  async [GET_SPECIAL_INDICATORS](_) {
    return await configApi.getSpecialIndicators();
  },
  async [SAVE_SPECIAL_INDICATOR](_, si) {
    return await configApi.saveSpecialIndicator(si);
  },
  async [DELETE_SPECIAL_INDICATORS](_, ids) {
    return await configApi.deleteSpecialIndicators(ids);
  },

  async [GET_SPECIAL_TRAVEL_NEEDS](_) {
    return await configApi.getSpecialTravelNeeds();
  },
  async [SAVE_SPECIAL_TRAVEL_NEED](_, stn) {
    return await configApi.saveSpecialTravelNeed(stn);
  },
  async [DELETE_SPECIAL_TRAVEL_NEEDS](_, ids) {
    return await configApi.deleteSpecialTravelNeeds(ids);
  },

  async [GET_DISTRICT_WIDE_RATES]({ commit }) {
    const result = await configApi.getDistrictWideRates();
    commit('setDistrictWideRates', result);
    return result;
  },
  async [SAVE_DISTRICT_WIDE_RATE](_, stn) {
    return await configApi.saveDistrictWideRate(stn);
  },
  async [DELETE_DISTRICT_WIDE_RATES](_, ids) {
    return await configApi.deleteDistrictWideRates(ids);
  },

  async [GET_FUEL_PRICES](_) {
    return await configApi.getFuelPrices();
  },
  async [GET_FUEL_PRICES_BY_DATE](_, begin) {
    return await configApi.getFuelPricesByDate(begin);
  },
  async [SAVE_FUEL_PRICE](_, stn) {
    return await configApi.saveFuelPrice(stn);
  },
  async [DELETE_FUEL_PRICES](_, ids) {
    return await configApi.deleteFuelPrices(ids);
  },

  async [GET_ADDITIONAL_INVOICE_COSTS](_) {
    return await configApi.getAdditionalInvoiceCosts();
  },
  async [SAVE_ADDITIONAL_INVOICE_COST](_, stn) {
    return await configApi.saveAdditionalInvoiceCost(stn);
  },
  async [DELETE_ADDITIONAL_INVOICE_COSTS](_, ids) {
    return await configApi.deleteAdditionalInvoiceCosts(ids);
  },
};
