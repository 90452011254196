import stopApi from '@/apis/stop';

export const GET_STOPS = 'getStops';

export const actions = {
  async [GET_STOPS]({ commit }, options) {
    const result = await stopApi.getStops(options);
    commit('setStops', result);
  },
};
