import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

export default {
  init() {
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response) error = error.response.data;
        return Promise.reject(error);
      }
    );
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = '/';
  },

  setHeader() {
    const client = window.location.pathname.substring(1);
    Vue.axios.defaults.headers.common['client'] = client;
  },

  async get(url, params) {
    this.setHeader();
    return await Vue.axios.get(url, { ...params }).then((r) => r.data);
  },

  async post(url, data, config = {}) {
    this.setHeader();
    return await Vue.axios.post(url, data, config).then((r) => r.data);
  },

  async put(url, data) {
    this.setHeader();
    return await Vue.axios.put(url, data).then((r) => r.data);
  },

  async delete(url, params) {
    this.setHeader();
    return await Vue.axios.delete(url, { ...params }).then((r) => r.data);
  },
};
