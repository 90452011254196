import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import EZActivityTripsTheme from '@/themes/EZActivityTripsTheme';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    defaultTheme: 'EZActivityTripsTheme',
    themes: {
      EZActivityTripsTheme,
    },
  },
});
