import approverApi from '@/apis/approver';

export const GET_APPROVERS = 'getApprovers';
export const GET_APPROVER = 'getApprover';
export const SAVE_APPROVER = 'saveApprover';
export const DELETE_APPROVER = 'deleteApprover';

export const actions = {
  async [GET_APPROVERS]({commit}) {
    const result = await approverApi.getApprovers();
    commit('setApprovers', result)
    return result;
  },
  async [GET_APPROVER](_, id) {
    const result = await approverApi.getApprover(id);
    return result;
  },
  async [SAVE_APPROVER](_, approver) {
    return await approverApi.saveApprover(approver);
  },
  async [DELETE_APPROVER](_, id) {
    return await approverApi.deleteApprover(id);
  },
};
