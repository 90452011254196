export const getters = {
  fundingSources(state) {
    return state.fundingSources;
  },
  filterFundingSources(state, _, rootState) {
    return ({ tripTypeId, locationId, existingFundingSourceIds, selected }) => {
      const me = rootState.user.me;
      const tripRequestConfig = rootState.config.tripRequestConfig;
      return state.fundingSources.filter((e) => {
        // allow selected
        if (e.id === selected) return true;

        // trip type filter and active filter
        if (!tripTypeId || !e.displayOnTripTypes || !e.displayOnTripTypes.includes(tripTypeId) || !e.active)
          return false;

        // location filter
        if (
          locationId &&
          (tripRequestConfig.display.showAllFS ? !me.is.superAdmin && !me.is.transportationAdmin : true) &&
          e.locationSpecific &&
          !e.locations?.includes(locationId)
        ) {
          return false;
        }

        // unique filtering
        if (existingFundingSourceIds.has(e.id)) return false;

        return true;
      });
    };
  },
  fundingSourcesById(state) {
    const byId = {};
    state.fundingSources.forEach((e) => (byId[e.id] = e));
    return byId;
  },
  fundingSourcesByName(state) {
    const byName = {};
    state.fundingSources.forEach((e) => (byName[e.name.toLowerCase()] = e));
    return byName;
  },
};
