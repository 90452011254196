import destinationApi from '@/apis/destination';

export const DELETE_DESTINATIONS = 'deleteDestinations';
export const GET_DESTINATION = 'getDestination';
export const GET_DESTINATION_CATEGORIES = 'getDestinationCategories';
export const GET_DESTINATIONS = 'getDestinations';
export const GET_PROSPECTIVE_DESTINATIONS = 'getProspectiveDestinations';
export const QUERY_DESTINATIONS = 'queryDestinations';
export const REMOVE_PROSPECT_FROM_DESTINATIONS = 'removeProspectFromDestinations';
export const SAVE_DESTINATION = 'saveDestination';

export const actions = {
  async [GET_DESTINATIONS]({ commit }, options = {}) {
    const result = await destinationApi.getDestinations(options);

    commit('setDestinations', result);

    return result;
  },
  async [QUERY_DESTINATIONS](_, options) {
    return await destinationApi.getDestinations(options);
  },
  async [GET_PROSPECTIVE_DESTINATIONS]({ commit }, options = {}) {
    const result = await destinationApi.getDestinations({ prospects: 1, ...options });

    commit('setProspectiveDestinations', result);
  },
  async [GET_DESTINATION](_, id) {
    const result = await destinationApi.getDestination(id);
    return result;
  },
  async [GET_DESTINATION_CATEGORIES]() {
    const result = await destinationApi.getDestinationCategories();
    return result;
  },
  async [SAVE_DESTINATION](_, destination) {
    return await destinationApi.saveDestination(destination);
  },
  async [REMOVE_PROSPECT_FROM_DESTINATIONS](_, ids) {
    return await destinationApi.removeProspectFromDestinations(ids);
  },
  async [DELETE_DESTINATIONS](_, ids) {
    return await destinationApi.deleteDestinations(ids);
  },
};
