export const mutations = {
  setTripRequestFormConfig(state, config) {
    state.tripRequestConfig = config;
  },
  setDriverConfig(state, config) {
    state.driverConfig = config;
  },
  setInvoiceConfig(state, config) {
    state.invoiceConfig = config;
  },
  setVehicleOwnerConfig(state, config) {
    state.vehicleOwnerConfig = config;
  },
  setCustomFormFieldsConfig(state, config) {
    state.cffConfig = config;
  },
  setBudgetCodeConfig(state, config) {
    state.budgetCodeConfig = config;
  },
  setCalendarConfig(state, config) {
    state.calendarConfig = config;
  },
  setLeadDaysConfig(state, config) {
    state.leadDaysConfig = config;
  },
  setSpecialTravelNeeds(state, config) {
    state.specialTravelNeeds = config;
  },
  setSpecialIndicators(state, config) {
    state.specialIndicators = config;
  },
  setDistrictWideRates(state, config) {
    state.districtWideRates = config;
  },
  setFuelPrices(state, config) {
    state.fuelPrices = config;
  },
  setAdditionalInvoiceCosts(state, config) {
    state.additionalInvoiceCosts = config;
  },
  setPermissionsConfig(state, config) {
    state.permissions = config;
  },
  setTripDateTimes(state, config) {
    state.tripDateTimes = config;
  },
  setNotifyConfig(state, config) {
    state.notifyConfig = config;
  },
  setCustomFormFieldTypes(state, config) {
    state.customFormFieldTypes = config;
  },
};
