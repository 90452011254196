const modulesMap = {
  Driver: {
    'Transportation Admin': true,
    Driver: true,
  },
};

/**
 * Determines if a user has access to a specific module based on their roles.
 * Super Admins have access to all modules.
 *
 * @param {Object} params - The parameters object.
 * @param {string} params.module - The name of the module to check access for.
 * @param {Array<{name: string}>} [params.userRoles=[]] - The roles of the user, each role is an object with a `name` property.
 * @returns {boolean} Returns true if the user has access to the module, false otherwise.
 */
export const isUserAccessAllowed = ({ module, userRoles = [] }) => {
  if (!module || !userRoles.length) return false;

  // Super Admins have access to all
  if (userRoles.some((role) => role.name === 'Super Admin')) return true;

  const moduleRoles = modulesMap[module];
  if (!moduleRoles) return false;

  return userRoles.some((role) => moduleRoles[role.name]);
};
