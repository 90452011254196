export default {
  getAuditHistory,
};

//////////////

import api from '@/shared/api.service';

async function getAuditHistory(options) {
  return await api.get(`/api/audit/history?from=${options.fromDate}&to=${options.toDate}`);
}
