export default {
  login,
  logout,
  getRedirectURL,
};

/////////////

import api from '@/shared/api.service';

async function login() {
  return await api.post('/api/yellowfin/login');
}

async function logout() {
  return await api.post('/api/yellowfin/logout');
}

async function getRedirectURL() {
  return await api.post('/api/yellowfin/redirect');
}
