<template>
  <main class="mainLayout">
    <v-snackbar v-model="showSnackbar" :timeout="5000" top right multi-line>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="closeSnackbar()"> Close </v-btn>
      </template>
    </v-snackbar>
    <div class="d-flex flex-column align-center mt-10">
      <TravelTrackerLogo class="login-image my-10" />
      <section v-if="!token">
        <v-card v-if="!newUser && !forgotPassword" class="card">
          <v-card-text class="pa-5">
            <v-row class="justify-center mb-3">
              <div class="d-flex flex-column justify-center">
                <div v-if="client === 'admin'">
                  <h4 class="text-h5 text-center font-weight-bold mt-5 mb-3">Admin Portal</h4>
                </div>
                <div v-else>
                  <h4 class="text-h5 text-center font-weight-bold mt-5 mb-3">Log In</h4>
                  <h6 class="text-subtitle-1 text-center">
                    New member?
                    <a
                      @click="showRegistration()"
                      class="cursor-pointer font-weight-bold blue-grey--text text--darken-3"
                      >Create an account</a
                    >
                  </h6>
                </div>
              </div>
            </v-row>
            <div class="d-flex flex-column justify-center pt-3">
              <v-text-field
                type="text"
                label="Email"
                v-model="credentials.username"
                required
                :error-messages="loginError"
                ref="email"
                prepend-inner-icon="mdi-account"
                outlined
              />
              <v-text-field
                type="password"
                label="Password"
                v-model="credentials.password"
                required
                prepend-inner-icon="mdi-lock"
                outlined
                @keyup.enter="submit()"
              />
              <v-btn color="primary" type="button" large ripple @click="submit()" :disabled="disableLoginButton">
                {{ loginButtonText }}
              </v-btn>
            </div>
            <div class="mt-8">
              <p class="text-subtitle-2 text-center">© 2023 - {{ currentYear }} TransAct Communications, LLC.</p>
              <div class="d-flex justify-center my-5">
                <a href="https://transact.com/privacy-policy" target="_blank" class="text-decoration-none"
                  ><v-icon small>mdi-link-variant</v-icon> Privacy Policy</a
                >
                <span class="mx-3">|</span>
                <a href="https://transact.com/terms-of-use" target="_blank" class="text-decoration-none"
                  ><v-icon small>mdi-link-variant</v-icon> Terms of Use</a
                >
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="p-5 d-flex justify-center blue-grey lighten-5">
            <a @click="showForgotPassword()" class="cursor-pointer text-center blue-grey--text text--darken-3"
              >Forgot your password?</a
            >
          </v-card-actions>
        </v-card>

        <form id="forgot-password" v-if="forgotPassword" @submit.prevent="sendPasswordResetEmail()">
          <v-card class="card">
            <v-card-text class="pa-5">
              <v-row class="justify-center mb-3">
                <div class="d-flex flex-column justify-center align-center">
                  <v-btn @click="showLoginPage()" text class="blue-grey--text text--darken-3 my-2">
                    <v-icon left>mdi-arrow-left</v-icon>
                    Back to Login
                  </v-btn>
                  <h4 class="text-h5 text-center font-weight-bold">Forgot Password</h4>
                </div>
              </v-row>

              <div class="d-flex flex-column justify-center">
                <p class="forgot-text">
                  Enter your email address. You will receive an email with a link to reset your password.
                </p>

                <v-text-field
                  type="email"
                  class="validate"
                  label="Email"
                  ref="forgotPasswordEmail"
                  v-model="forgotPasswordEmail"
                  prepend-inner-icon="mdi-email"
                  :error-messages="passwordResetMessage"
                  outlined
                  required
                />

                <v-btn
                  color="primary"
                  type="submit"
                  large
                  ripple
                  :disabled="!forgotPasswordEmail.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/g)"
                >
                  Retrieve Password
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </form>

        <form @submit.prevent="register()" v-if="newUser">
          <v-card class="register-card">
            <v-card-text class="pa-5">
              <v-row class="justify-center mb-4">
                <div class="d-flex flex-column align-center">
                  <v-btn text class="blue-grey--text text--darken-3 my-2" @click="showLoginPage()">
                    <v-icon left>mdi-arrow-left</v-icon>
                    Back to Login
                  </v-btn>
                  <h4 class="text-h5 text-center font-weight-bold">Register</h4>
                </div>
              </v-row>
              <v-row>
                <v-col class="pb-0">
                  <div class="d-flex flex-column justify-center mb-3">
                    <v-text-field
                      type="email"
                      label="Email"
                      ref="registerEmail"
                      placeholder="Let's start with your email"
                      :error-messages="registerEmailError"
                      :class="{ invalid: registrationFoundEmail == 6 }"
                      :disabled="Number.isInteger(registrationFoundEmail)"
                      v-model="user.email"
                      @keyup.enter="checkEmail()"
                      required
                      outlined
                    />
                    <v-btn
                      color="primary"
                      type="button"
                      large
                      ripple
                      @click="checkEmail()"
                      :disabled="
                        Number.isInteger(registrationFoundEmail) ||
                        !user.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/g)
                      "
                    >
                      Next
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="register-card mt-4" v-if="Number.isInteger(registrationFoundEmail)">
            <v-card-text class="pa-5">
              <v-row>
                <div class="col s12 strong orange-text text-darken-3" v-show="registrationFoundEmail == 2">
                  This email is already registered with us. Original full name will be used. Please enter the original
                  password to confirm this registration.
                </div>
                <div class="col s12" v-if="user.relationship == 'Other'">
                  <label>Other Relationship</label>
                  <input v-model="user.otherRelationship" />
                </div>
              </v-row>

              <v-row v-show="registrationFoundEmail != 2">
                <v-col class="pb-0">
                  <v-text-field
                    type="text"
                    label="First Name"
                    v-model="user.firstName"
                    :required="!registrationFoundEmail"
                    outlined
                  />
                </v-col>
                <v-col class="pb-0">
                  <v-text-field type="text" label="Middle Name (optional)" v-model="user.midName" outlined />
                </v-col>
                <v-col class="pb-0">
                  <v-text-field
                    type="text"
                    label="Last Name"
                    v-model="user.lastName"
                    :required="!registrationFoundEmail"
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col class="col-12 col-md-6 pb-0">
                  <v-text-field
                    type="text"
                    label="Phone Number (optional)"
                    placeholder="XXX-XXX-XXXX"
                    v-model="user.phone"
                    maxlength="14"
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pb-0">
                  <v-text-field
                    type="password"
                    label="Password"
                    :class="{ invalid: user.password && !passwordIsValid }"
                    v-model="user.password"
                    required
                    outlined
                  />
                </v-col>
                <v-col v-show="registrationFoundEmail != 2" class="pb-0">
                  <v-text-field
                    type="password"
                    label="Retype Password"
                    :class="{ invalid: user.retype && user.password != user.retype }"
                    v-model="user.retype"
                    @blur="validatePassword()"
                    :required="!registrationFoundEmail"
                    outlined
                  />
                </v-col>
                <span v-if="showRegistationError" class="error"
                  >Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and 1
                  symbol</span
                >
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-row>
                <v-col class="px-4 mb-4 d-flex flex-row-reverse">
                  <v-btn color="primary" class="mx-2" type="submit" large ripple :disabled="!validated()">Submit</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </form>
      </section>

      <section v-else-if="token">
        <form id="reset-password" @submit.prevent="resetPassword()">
          <v-card>
            <v-card-text v-show="!resetSuccess">
              <v-row>
                <v-col class="pb-0 d-flex flex-column align-center">
                  <h4 class="text-h5 font-weight-bold">Password Reset</h4>
                  <span>Please enter your new password below.</span>
                </v-col>
              </v-row>
              <v-row class="d-flex flex-column align-center">
                <v-col class="pb-0">
                  <input id="reset-token" :value="token" hidden />
                  <v-text-field
                    outlined
                    prepend-inner-icon="mdi-lock"
                    label="New Password"
                    type="password"
                    v-model="credentials.password"
                    required
                  />
                </v-col>
                <v-col class="d-flex justify-center pt-0">
                  <v-btn color="primary" type="submit">Reset Password</v-btn>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text v-show="resetSuccess">
              <v-row>
                <v-col class="pb-0 d-flex flex-column align-center">
                  <h4 class="text-h5 font-weight-bold">Password Reset Successfully</h4>
                  <v-btn color="primary" class="cursor-pointer" @click="showLoginPage()">Please Login</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </form>
      </section>
    </div>
  </main>
</template>

<script>
import TravelTrackerLogo from '@/components/shared/Logo.vue';
import api from '../../shared/api.service';

const defaultUser = {
  email: '',
  firstName: '',
  midName: '',
  lastName: '',
  password: '',
  retype: '',
  phone: '',
  otherRelationship: '',
};
const regex = /^[[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

export default {
  name: 'gdicLogin',
  props: ['token', 'client'],
  components: { TravelTrackerLogo },
  data() {
    return {
      newUser: false,
      forgotPassword: false,
      forgotPasswordEmail: '',
      credentials: {
        username: '',
        password: '',
      },
      resetSuccess: false,
      registrationFoundEmail: null,
      loginError: '',
      registerEmailError: '',
      disableLoginButton: false,
      loginButtonText: 'Login',
      passwordResetMessage: '',
      showRegistationError: false,
      passwordIsValid: false,
      user: JSON.parse(JSON.stringify(defaultUser)),
      showSnackbar: false,
      snackbarText: '',
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.email.focus();
    });

    let name = 'token';
    name = name.replace(/[[\]]/g, '\\$&');
    const url = window.location.href;
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results || !!results[2]) return;
    const found = decodeURIComponent(results[2].replace(/\+/g, ' '));
    if (found && found.length === 96) {
      localStorage.setItem('token', found);
      const match = window.location.href.match(/\.com\/(\w+)/);
      if (match[1]) window.location.href = '/' + match[1];
    }
  },
  computed: {
    currentYear() {
      const date = new Date();
      return date.getFullYear();
    },
  },
  methods: {
    showForgotPassword() {
      this.forgotPassword = true;
      this.$nextTick(() => {
        this.$refs.forgotPasswordEmail.focus();
      });
    },
    showLoginPage() {
      this.loginError = '';
      this.user = JSON.parse(JSON.stringify(defaultUser));
      this.registrationFoundEmail = null;
      this.$nextTick(() => {
        this.resetSuccess = false;
        this.showRegistationError = false;
        this.newUser = false;
        this.forgotPassword = false;
      });
    },
    showRegistration() {
      this.newUser = true;
      this.$nextTick(() => {
        this.$refs.registerEmail.focus();
      });
    },
    closeSnackbar() {
      this.showSnackbar = false;
      this.snackbarText = '';
    },
    validated() {
      if (this.registrationFoundEmail == 6) return false;
      if (!this.registrationFoundEmail) {
        return (
          this.user.email &&
          this.user.email.match(regex) &&
          this.user.password == this.user.retype &&
          this.passwordIsValid &&
          this.user.firstName &&
          this.user.lastName
        );
      }
      if (this.registrationFoundEmail == 2) {
        return this.user.email && this.user.password;
      }
    },
    validatePassword() {
      if (this.registrationFoundEmail == 2) {
        this.passwordIsValid = true;
        return;
      }

      var pwd = this.user.password;
      if (!pwd.match(/[a-zA-Z]/) || !pwd.match(/[0-9]/) || pwd.length < 8) {
        this.showRegistationError = true;
        this.passwordIsValid = false;
        return;
      }
      this.passwordIsValid = true;
    },
    async findUser(emailAddress) {
      return await api.post('/api/user/find', { email: emailAddress });
    },
    async checkEmail() {
      this.registerEmailError = '';
      if (!this.user.email) return;
      if (!this.user.email.match(regex)) {
        return (this.registerEmailError = 'Invalid Email');
      }

      const response = await this.findUser(this.user.email);
      this.registrationFoundEmail = response.found;
      if (this.registrationFoundEmail === 6) this.showRegistationError = true;
    },
    async submit() {
      if (!this.credentials.username || !this.credentials.password) return;

      this.loginButtonText = 'SIGNING IN';
      this.disableLoginButton = true;

      const userAccess = await this.findUser(this.credentials.username);
      if (this.client != 'admin' && userAccess?.notInClientDB) {
        this.loginError = 'User not found';
        return this.resetButton();
      }

      let success = false;
      api
        .post('/login', this.credentials)
        .then((response) => {
          if (response.message) {
            this.loginError = 'Incorrect Credentials';
          } else {
            this.loginError = '';
            success = true;
            location.reload();
          }
        })
        .catch(() => {
          this.loginError = 'Incorrect Credentials';
        })
        .finally(() => {
          if (!success) this.resetButton();
        });
    },
    register() {
      if (!this.user.email || !this.user.password) return;
      this.user.email = this.user.email.toLowerCase();
      api.post('/api/register', this.user).then((response) => {
        this.user = JSON.parse(JSON.stringify(defaultUser));
        this.credentials = {
          username: '',
          password: '',
        };
        if (response.id) {
          this.snackbarText = 'Registration succeeded, please login';
          this.showSnackbar = true;
        }
        this.showLoginPage();
      });
    },
    sendPasswordResetEmail() {
      this.passwordResetMessage = '';

      if (!this.client) this.client = window.location.pathname.replace('/', '');

      api
        .post('/api/forgot', { email: this.forgotPasswordEmail, client: this.client })
        .then((response) => {
          if (response.done) {
            this.snackbarText = 'The password reset email has been sent.';
            this.showSnackbar = true;
            this.forgotPasswordEmail = '';
          } else {
            this.passwordResetMessage = response.message ?? 'Could not send password reset email';
          }
        })
        .catch(() => {
          this.passwordResetMessage = 'Could not send password reset email';
        });
    },
    passwordReset() {
      const newPassword = this.credentials.password;
      if (!newPassword.match(/[a-zA-Z]/) || !newPassword.match(/[0-9]/) || newPassword.length < 8) {
        this.snackbarText = 'Password must contain letters numbers and at least 8 characters.';
        this.showSnackbar = true;
        return;
      }
      api.post('/api/forgot/reset', { token: this.token, newPassword }).then((response) => {
        this.credentials = {
          username: '',
          password: '',
        };
        this.resetSuccess = true;
      });
    },
    resetButton() {
      this.disableLoginButton = false;
      this.loginButtonText = 'LOGIN';
    },
    getParameterByName(name) {
      if (!name) return;
      const url = window.location.href;
      name = name.replace(/[[\]]/g, '\\$&');
      const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
  },
  watch: {
    user: {
      deep: true,
      immediate: true,
      handler: function (user) {
        if (user.phone.length > 0) {
          if (user.phone.slice(-1).match(/[A-Za-z]/)) {
            this.$nextTick(() => {
              user.phone = user.phone.replace(/.$/, '');
            });
          } else {
            let phone = (user.phone || '').replace(/\D/g, '').substring(0, 10);
            const mask = phone.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            phone = !mask[2] ? mask[1] : '(' + mask[1] + ') ' + mask[2] + (mask[3] ? '-' + mask[3] : '');
            if (user.phone != phone) user.phone = phone;
          }
        }
        if (!user.email) {
          this.registrationFoundEmail = null;
        }
        this.validated();
      },
    },
    credentials: {
      deep: true,
      handler: function (credentials) {
        if (!credentials.username.length || (!credentials.password.length && this.loginError)) {
          this.loginError = '';
        }
      },
    },
    forgotPasswordEmail: {
      handler: function (email) {
        if (!email.length) this.passwordResetMessage = '';
      },
    },
  },
};
</script>

<style scoped>
.mainLayout {
  background: linear-gradient(to bottom, #00294d 0%, #009eec 100%);
  display: grid;
  height: 100vh;
  justify-content: center;
  align-items: start;
}
.login-image {
  width: 350px;
  filter: brightness(0) invert(1);
}
@media only screen and (min-width: 600px) {
  .card {
    width: 400px;
  }
  .register-card {
    width: 600px;
  }
  .login-image {
    width: 600px;
    max-height: 100px;
  }
}
.error {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  color: white;
}
.cursor-pointer:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>
