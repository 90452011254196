export default {
  getStops,
  deleteStop,
  saveStop,
  updateStops,
};

//////////////

import api from '@/shared/api.service';

async function getStops() {
  return await api.get('/api/stop');
}

async function deleteStop(id) {
  return await api.delete('/api/stop', {
    params: { id: id },
  });
}

async function saveStop(params) {
  return await api.post('/api/stop', params);
}

async function updateStops(params) {
  return await api.put('/api/stop', params);
}
