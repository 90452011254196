import tripEventApi from '@/apis/tripEvent';

export const GET_TRIP_EVENTS = 'getTripEvents';
export const GET_TRIP_EVENT = 'getTripEvent';
export const SAVE_TRIP_EVENT = 'saveTripEvent';
export const DELETE_TRIP_EVENTS = 'deleteTripEvents';

export const actions = {
  async [GET_TRIP_EVENTS]({commit },options) {
    const result = await tripEventApi.getTripEvents(options);
    commit('setTripEvents', result)
  },
  async [GET_TRIP_EVENT](_, id) {
    const result = await tripEventApi.getTripEvent(id);
    return result;
  },
  async [SAVE_TRIP_EVENT](_, tripEvent) {
    return await tripEventApi.saveTripEvent(tripEvent);
  },
  async [DELETE_TRIP_EVENTS](_, ids) {
    return await tripEventApi.deleteTripEvents(ids);
  },
};
