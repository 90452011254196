import colors from 'vuetify/lib/util/colors';

const EZActivityTripsTheme = {
  dark: false,
  colors: {
    background: colors.blueGrey.lighten5,
    surface: colors.blueGrey.lighten5,
    primary: '#009eec',
    'primary-darken-1': '#1668b9',
    secondary: '#f78e1e',
    'secondary-darken-1': '#d8770f',
    error: colors.red.base,
    info: colors.blue.base,
    success: colors.green.base,
    warning: colors.amber.base,
  },
};

export default EZActivityTripsTheme;
