export default {
  getSemesters,
  createSemester,
  updateSemester,
  deleteSemester,
};

////////////

import api from '@/shared/api.service';

async function getSemesters() {
  return await api.get('/api/semester');
}

async function createSemester(semester) {
  return await api.post('/api/semester', semester);
}

async function updateSemester(semester) {
  return await api.put(`/api/semester/${semester.id}`, semester);
}

async function deleteSemester(id) {
  return await api.delete(`/api/semester/${id}`);
}
