function computeStaffBenefits(benefitConfig, staffs, retirementConfigMap) {
  let benefits = staffs.reduce(
    (acc, staff) => {
      const benefit = staff.staffType === 'driver' ? acc.driver : acc.assistant;

      const newValue = {
        retirement: 0,
        ss: benefit.ss + _round(benefitConfig.cDriverBenefitSS * staff.total),
        workerComp: benefit.workerComp + _round(benefitConfig.cDriverBenefitWorkerComp * staff.total),
        medical: benefit.medical + _round(benefitConfig.cDriverBenefitMedical * staff.total),
      };

      const willComputeRetirement = retirementConfigMap[staff.staffType];

      if (willComputeRetirement)
        newValue.retirement = benefit.retirement + _round(benefitConfig.cDriverBenefitRetirement * staff.total);

      return {
        driver: staff.staffType === 'driver' ? newValue : acc.driver,
        assistant: staff.staffType === 'assistant' ? newValue : acc.assistant,
      };
    },
    {
      driver: { retirement: 0, ss: 0, workerComp: 0, medical: 0 },
      assistant: { retirement: 0, ss: 0, workerComp: 0, medical: 0 },
    }
  );

  return benefits;
}

function getTimeDifferenceInMinutes(date, startTime, endTime) {
  const start = new Date(`${date}T${_formatTime(startTime)}`);
  const end = new Date(`${date}T${_formatTime(endTime)}`);
  return (end - start) / (1000 * 60);
}

const _formatTime = (timeString) => {
  const [hours, minutes] = timeString.split(':');
  const formattedHours = hours.padStart(2, '0');
  return `${formattedHours}:${minutes}`;
};

const _round = (number, decimalPlaces = 2) => {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(number * factor) / factor;
};

module.exports = {
  computeStaffBenefits,
  getTimeDifferenceInMinutes,
};
