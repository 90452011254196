export default {
  getTripEvents,
  saveTripEvent,
  deleteTripEvents,
};

//////////////

import api from '@/shared/api.service';

async function getTripEvents(options) {
  return await api.get('/api/trip-event' + (options && options.forTable ? '?table=1' : ''));
}

async function saveTripEvent(params) {
  return await api.post('/api/trip-event', params);
}

async function deleteTripEvents(ids) {
  return await api.delete('/api/trip-event', {
    params: { ids },
  });
}
