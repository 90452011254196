export default {
  saveApprover,
  deleteApprover,
  getApprovers
};



import api from '@/shared/api.service';
async function getApprovers() {
  return await api.get('/api/approver');
}
async function saveApprover(obj) {
  return await api.post('/api/approver', obj);
}

async function deleteApprover(id) {
  return await api.delete(`/api/approver/${id}`);
}
