export default {
  getClients,
  getClient,
  getClientUsers,
  deleteClientUser,
  createClient,
  updateClient,
  deleteClient,
  impersonateUser,
  searchDistricts,
  getDistrictBoundary,
  getAdminUsers,
  addAdminUser,
  syncEzr,
  getSyncMap,
  deleteSyncMap,
  testOneRosterCredentials,
  findMigrationFolder,
  runLegacyMigration,
  sendTestText,
};

////////////

import api from '@/shared/api.service';

async function getClients() {
  return await api.get('/api/admin/client');
}

async function getClient(client) {
  try {
    return await api.get(`/api/admin/client/${client}`);
  } catch (error) {
    if (error?.errcode && error?.errcode === '613CA956') window.location.reload();
  }
}

async function getClientUsers(client) {
  return await api.get(`/api/admin/client/users/${client}`);
}

async function deleteClientUser(client, id) {
  return await api.delete(`/api/admin/client/users/${client}/${id}`);
}

async function createClient(data) {
  return await api.post('/api/admin/client', data);
}

async function updateClient(data) {
  return await api.put('/api/admin/client', data);
}

async function deleteClient(client) {
  return await api.delete(`/api/admin/client/${client}`);
}

async function impersonateUser(data) {
  return await api.post('/api/admin/authenticate', data);
}

async function searchDistricts(keyword) {
  return await api.post('/api/admin/district/search', keyword);
}

async function getDistrictBoundary(params) {
  return await api.post('/api/admin/district/boundary', params);
}

async function getAdminUsers() {
  return await api.get('/api/admin/user');
}

async function addAdminUser(data) {
  return await api.post('/api/admin/user', data);
}

async function syncEzr(client) {
  return await api.post(`/api/admin/client/sync/${client}`);
}

async function getSyncMap() {
  return await api.get(`/api/admin/sync-map`);
}

async function deleteSyncMap(ids) {
  return await api.delete(`/api/admin/sync-map`, { ids });
}

async function testOneRosterCredentials(credentials) {
  return await api.post('api/admin/testOneRosterCredentials', credentials);
}

async function findMigrationFolder(client) {
  return await api.post(`/api/admin/client/findMigrationFolder`, { name: client });
}

async function runLegacyMigration(client, routing) {
  return await api.post('/api/admin/client/migrate', { client, routing });
}

async function sendTestText(client, number) {
  return await api.post('/api/notification/testText', { client, number });
}
