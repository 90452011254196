export const GRADES = ['Pre-K', 'K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

export const ROLES = {
  SUPER_ADMIN: 1,
  TRANSPORTATION_ADMIN: 2,
  LIMITED_ADMIN: 3,
  FINANCE: 4,
  DRIVER: 5,
  DRIVER_TABLE_ONLY: 6,
  READ_ONLY: 7,
  OVERNIGHT_OOS_ONLY: 8,
  APPROVER: 9,
  SITE_ADMIN: 10,
  SITE_ADMIN_READ_ONLY: 11,
  SITE_ADMIN_REPORTS_ONLY: 12,
  SCHOOL_FINANCE: 13,
  VEHICLE_OWNER: 14,
  SPECIAL_NEEDS_VEHICLE_OWNER: 15,
  NURSE: 16,
  NUTRITIONIST: 17,
  REPORTS_ONLY: 18,
  REQUESTER: 19,
  CHILD_NUTRITIONIST: 20,
  FUNDING_MANAGER: 23,
};

export const TRIP_TYPES = {
  FIELD_TRIP: 1,
  ATHLETIC: 2,
  SPECIAL_NEEDS: 3,
  STAFF: 4,
};

export const TRIP_CATEGORIES = {
  TRIP_WITH_STUDENTS: 1,
  STAFF_ONLY: 2,
}

export const TRIP_STATUS = {
  DRAFT: 0,
  SUBMITTED: 1,
  COMPLETED: 2,
  RESUBMIT: -1,
  DENIED: -2,
  CANCELLED: -3,
};
