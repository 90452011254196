import tripRequestApi from '@/apis/tripRequest';
import assignmentApi from '@/apis/assignment';

export const GET_CALENDAR_TRIP_REQUESTS = 'getCalendarTripRequests';
export const GET_CALENDAR_ASSIGNMENTS = 'getCalendarAssignments';

export const actions = {
  async [GET_CALENDAR_TRIP_REQUESTS]({ commit }, options) {
    const result = await tripRequestApi.getTripRequestsForCalendar(options);
    commit('setCalendarTripRequests', result);
  },
  async [GET_CALENDAR_ASSIGNMENTS]({ commit }, options) {
    const result = await assignmentApi.getAssignmentsForCalendar(options);
    commit('setCalendarAssignments', result);
  },
};
