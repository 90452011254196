<template>
  <v-container fluid class="px-8">
    <v-toolbar no-gutters height="50" class="mb-4 mt-2 px-4 menuBar" elevation="4" rounded v-if="!hideMenu">
      <TableMenu />
    </v-toolbar>
    <v-row :class="[flipTable ? 'flex-row-reverse' : '']">
      <v-col cols="12" :md="!noForm ? '6' : '12'">
        <v-container fluid>
          <v-row>
            <v-col cols="12" class="d-flex align-center">
              <div v-if="title" class="text-h5">{{ enhancedTitle }}</div>
              <v-spacer></v-spacer>
              <template v-if="baseTable">
                <v-scroll-x-transition>
                  <v-btn
                    v-if="canEditSetupTables"
                    v-show="selected.length"
                    class="mx-2"
                    dark
                    color="red"
                    @click="$emit('deleteItems', selected)"
                  >
                    <v-icon>mdi-delete</v-icon>
                    {{ deleteLabel || 'Delete' }} {{ selected.length }} {{ title }}{{ selected.length > 1 ? 's' : '' }}
                  </v-btn>
                </v-scroll-x-transition>
              </template>
              <template v-else>
                <v-scroll-x-transition>
                  <v-btn
                    v-if="canEditSetupTables"
                    v-show="selected.length"
                    class="mx-2"
                    dark
                    color="primary"
                    @click="$emit('changeStatus', selected, true)"
                  >
                    Set as Active
                  </v-btn>
                </v-scroll-x-transition>
                <v-scroll-x-transition>
                  <v-btn
                    v-if="canEditSetupTables"
                    v-show="selected.length"
                    class="mx-2"
                    dark
                    color="primary"
                    @click="$emit('changeStatus', selected, false)"
                  >
                    Set as Inactive
                  </v-btn>
                </v-scroll-x-transition>
              </template>
              <v-btn
                v-if="!hideCreate && canCreate"
                class="mx-2"
                fab
                small
                color="primary"
                @click="$emit('createItem')"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
            <template v-else>
              <v-row dense>
                <v-col cols="12" v-if="showSearch" class="px-3">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    class="mt-0 pt-0"
                    label="Search"
                    single-line
                    solo
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-card elevation="2" class="rounded-md mx-2" outlined>
                    <v-data-table
                      v-model="selected"
                      fixed-header
                      :headers="headers"
                      :items="items"
                      item-key="id"
                      :item-class="rowClass"
                      :show-select="!hideRowSelect"
                      :search="search"
                      :hide-default-footer="!pagination"
                      :disable-pagination="!pagination"
                      :class="[`table`, pagination ? `showPagination` : ``, showSearch ? `showSearch` : ``]"
                      @click:row="(row) => handleRowSelected(row)"
                      @toggle-select-all="selectAllToggle"
                      :footer-props="{
                        itemsPerPageOptions: [50, 100, 150],
                      }"
                    >
                      <template
                        v-if="!me.is.superAdmin && !me.is.transportationAdmin"
                        v-slot:[`header.data-table-select`]
                      ></template>
                      <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
                        <v-simple-checkbox
                          class="lighten-4 pa-0"
                          :value="isSelected"
                          :readonly="cannotDeleteItem(item)"
                          :disabled="!canEditSetupTables || cannotDeleteItem(item)"
                          @input="select($event)"
                          v-ripple
                        ></v-simple-checkbox>
                      </template>
                      <template v-for="(_, name) in $slots" v-slot:[name]>
                        <slot :name="name" />
                      </template>
                      <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
                        <slot :name="name" v-bind="data" />
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-row>
        </v-container>
      </v-col>

      <v-col cols="12" md="6" v-if="!noForm">
        <slot name="itemForm"></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import TableMenu from './Menu.vue';
export default {
  name: 'SetupTable',
  inject: ['eventHub'],
  components: { TableMenu },
  props: {
    title: String,
    items: Array,
    hideMenu: {
      type: Boolean,
      default: false,
    },
    flipTable: {
      type: Boolean,
      default: false,
    },
    headers: Array,
    loading: Boolean,
    hideCreate: Boolean,
    singularTitle: {
      type: Boolean,
      default: false,
    },
    hideRowSelect: {
      type: Boolean,
      default: false,
    },
    showSearch: Boolean,
    clearItem: {
      type: Boolean,
      default: true,
    },
    pagination: {
      type: Boolean,
      default: false,
    },
    deleteLabel: String,
    noForm: Boolean,
  },
  data() {
    return {
      showForm: false,
      editedItem: {},
      search: '',
      canHighlight: false,
      selected: [],
      page: 1,
      perPage: 10,
    };
  },
  computed: {
    ...mapGetters('user', ['me', 'canEditSetupTables']),
    ...mapGetters('app', ['isRoutingClient']),
    baseTable() {
      return this.$route.name != 'vehicles';
    },
    canCreate() {
      if (this.$route.name == 'drivers') return this.canEditSetupTables || this.me.is.driverTableOnly;
      else return this.canEditSetupTables;
    },
    enhancedTitle() {
      if (this.singularTitle) return this.title;
      if (this.title[this.title.length - 1] == 'y') return this.title.substring(0, this.title.length - 1) + 'ies';
      return this.title + 's';
    },
    tableHeight() {
      return window.innerHeight - (this.showSearch ? 215 : 165) - (this.pagination ? 60 : 0) - (this.hideMenu ? 0 : 70);
    },
  },
  methods: {
    selectAllToggle({ items }) {
      if (this.selected.length != items.length - items.filter((e) => this.cannotDeleteItem(e)).length) {
        this.selected = [];
        items.forEach((item) => {
          if (!this.cannotDeleteItem(item)) this.selected.push(item);
        });
      } else this.selected = [];
    },
    handleRowSelected(row) {
      this.canHighlight = false;
      this.editedItem = row;
      this.$emit('editItem', row);
    },
    handlePerPage(num) {
      this.perPage = num;
    },
    handlePageChange(page) {
      this.page = page;
    },
    rowClass(row) {
      if (this.canHighlight) return '';
      else return row.id == this.editedItem.id ? 'row-selected' : '';
    },
    cannotDeleteItem(item) {
      return item.cannotDelete || item.synced;
    },
  },
  watch: {
    items() {
      this.selected = [];
    },
    clearItem(value) {
      this.canHighlight = value;
    },
  },
};
</script>

<style scoped>
.table-action {
  margin-left: 10px;
}
.row-selected {
  background-color: #98c2eb;
}
.table {
  margin-top: -5px !important;
}
.table ::v-deep .v-data-table__wrapper {
  height: calc(100vh - 230px);
}
.table.showPagination ::v-deep .v-data-table__wrapper {
  height: calc(100vh - 250px);
}
.table.showSearch ::v-deep .v-data-table__wrapper {
  height: calc(100vh - 250px);
}
.table.showSearch.showPagination ::v-deep .v-data-table__wrapper {
  height: calc(100vh - 265px);
}
.menuBar ~ .row .table.showSearch.showPagination ::v-deep .v-data-table__wrapper {
  height: calc(100vh - 350px);
}
</style>
