import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { state } from './state';

const ApprovalLevelModule = {
  actions,
  getters,
  mutations,
  state,
  namespaced: true,
};

export default ApprovalLevelModule;
