export default {
  getStudentRoster,
  getAdultRoster,
  saveStudent,
};

/////////////

import api from '@/shared/api.service';

const route = '/api/roster';

async function getStudentRoster(options) {
  const roster = '?type=student';
  const orderBy = options?.orderBy ? `&orderBy=${options.orderBy}` : '';
  const location = options?.location ? `&location=${options.location}` : '';
  return await api.get(route + roster + orderBy + location);
}

async function getAdultRoster(options) {
  const roster = '?type=adult';
  const orderBy = options?.orderBy ? `&orderBy=${options.orderBy}` : '';
  const location = options?.location ? `&location=${options.location}` : '';
  return await api.get(route + roster + orderBy + location);
}

async function saveStudent(params) {
  return await api.post(route, params);
}
