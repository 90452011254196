import { groupBy } from 'lodash';

export const getters = {
  assignments(state) {
    return state.assignments;
  },
  assignmentsGrouped(state) {
    const g = groupBy(state.assignments, 'tripRequestId');
    return Object.keys(g).map((e) => ({
      tripRequestId: e,
      assignments: g[e],
    }));
  },
  assignmentById(state) {
    return (id) => {
      return state.assignments.find((e) => e.id == id);
    };
  },
  assignmentsById(state) {
    const byId = {};
    state.assignments.forEach((e) => (byId[e.id] = e));
    return byId;
  },
  assignmentsByTripId(state) {
    const byId = {};
    for (let a of state.assignments) {
      if (byId[a.tripRequestId]) byId[a.tripRequestId].push(a);
      else byId[a.tripRequestId] = [a];
    }
    return byId;
  },
  isLoading(state) {
    return state.isLoading;
  },
};
