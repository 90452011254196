import approvalLevelApi from '@/apis/approvalLevel';

export const GET_APPROVAL_LEVELS = 'getApprovalLevels';
export const GET_APPROVAL_LEVEL = 'getApprovalLevel';
export const SAVE_APPROVAL_LEVEL = 'saveApprovalLevel';
export const REORDER_APPROVAL_LEVELS = 'reorderApprovalLevels';
export const DELETE_APPROVAL_LEVEL = 'deleteApprovalLevel';
export const DELETE_APPROVAL_LEVELS = 'deleteApprovalLevels';

export const actions = {
  async [GET_APPROVAL_LEVELS]({ commit }) {
    const result = await approvalLevelApi.getApprovalLevels();
    commit('setApprovalLevels', result);
  },
  async [GET_APPROVAL_LEVEL](_, id) {
    const result = await approvalLevelApi.getApprovalLevel(id);
    return result;
  },
  async [SAVE_APPROVAL_LEVEL](_, approvalLevel) {
    return await approvalLevelApi.saveApprovalLevel(approvalLevel);
  },
  async [REORDER_APPROVAL_LEVELS](_, approvalLevels) {
    return await approvalLevelApi.reorderApprovalLevels(approvalLevels);
  },
  async [DELETE_APPROVAL_LEVEL](_, id) {
    return await approvalLevelApi.deleteApprovalLevel(id);
  },
  async [DELETE_APPROVAL_LEVELS](_, ids) {
    return await approvalLevelApi.deleteApprovalLevels(ids);
  },
};
