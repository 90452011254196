export default {
  deleteLocations,
  getLocations,
  getLocationVehicleOrderOfAssignments,
  getSchools,
  getZones,
  saveLocation,
  updateSLA,
};

////////////

import api from '@/shared/api.service';

async function getLocations(options = {}) {
  return await api.get('/api/location' + (options.forTable ? '?table=1' : ''), options);
}

async function getSchools(options) {
  return await api.get('/api/location/schools');
}

async function getLocationVehicleOrderOfAssignments(id) {
  return await api.get(`/api/location/${id}/vehicle-order-of-assignments`);
}

async function saveLocation(location) {
  return await api.post('/api/location', location);
}

async function updateSLA(toUpdate) {
  return await api.put('/api/location/sla', toUpdate);
}

async function deleteLocations(ids) {
  return await api.delete('/api/location', {
    params: { ids },
  });
}

async function getZones() {
  return await api.get('/api/zone');
}
