<template>
  <v-dialog v-model="dialog" width="unset" persistent no-click-animation>
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text class="text-h6">{{ message }}</v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="choose(true)"> {{ yesLabel }} </v-btn>
        <v-btn color="primary" @click="choose(false)"> {{ noLabel }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  data() {
    return {
      dialog: false,
      message: '',
      yesLabel: 'YES',
      noLabel: 'NO',

      resolvePromise: undefined,
      rejectPromise: undefined,
    };
  },
  methods: {
    show(options) {
      this.message = typeof options === 'string' ? options : options.message;
      this.yesLabel = typeof options === 'string' ? 'YES' : options.yes;
      this.noLabel = typeof options === 'string' ? 'NO' : options.no;
      this.dialog = true;
      return new Promise((res, rej) => {
        this.resolvePromise = res;
        this.rejectPromise = rej;
      });
    },
    destroyed() {
      this.dialog = false;
    },
    choose(value) {
      this.dialog = false;
      this.resolvePromise(value);
    },
  },
};
</script>
<style scoped>
@media (max-width: 768px) {
  .v_dialog {
    max-width: 100% !important;
  }
}
</style>
