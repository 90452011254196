export default {
  getVehicleConditionRates,
  getVehicleConditionRate,
  saveVehicleConditionRate,
  deleteVehicleConditionRates,
};

/////////////

import api from '@/shared/api.service';

const route = '/api/vehicle-condition-rate';

async function getVehicleConditionRates(options) {
  return await api.get(route + (options && options.forTable ? '?table=1' : ''));
}

async function getVehicleConditionRate(options) {
  return await api.get(route + (options && options.forTable ? '?table=1' : ''));
}

async function saveVehicleConditionRate(params) {
  return await api.post(route, params);
}

async function deleteVehicleConditionRates(ids) {
  return await api.delete(route, {
    params: { ids },
  });
}
