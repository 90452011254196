import { randomString } from '@/util';
export default {
  uuid: '',

  async init(ea, me) {
    this.uuid = sessionStorage.getItem('window-uuid');
    if (!this.uuid) {
      this.uuid = randomString(16);
      sessionStorage.setItem('window-uuid', this.uuid);
    }

    this.ea = ea;
    this.me = me;
    this.token = this.getCookie('traveltracker-token');

    this.client = window.location.pathname.substr(1);
    this.socket = window.io({ transports: ['websocket'] });
    this.socket.on('server-started', this.login.bind(this));
    this.socket.on('message', this.onMessage.bind(this));
  },

  login() {
    this.socket.emit('login', {
      client: this.client,
      token: this.token,
      uuid: this.uuid,
    });
  },

  onMessage(message) {
    if (!message.userId || message.userId == this.me.id) {
      if (message.name == 'download file') this.downloadFile('/api/report', message.data.uuid);
      else if (message.name == 'download trip ticket') this.downloadFile('/api/trip-ticket/file', message.data.uuid);
      else if (message.name == 'download permission slip')
        this.downloadFile('/api/permission-slip/file', message.data.uuid);
      else if (message.name == 'download trip estimate')
        this.downloadFile('/api/trip-estimate/file', message.data.uuid);
      else if (message.name == 'download printed trips') this.downloadFile('/api/trip-request/file', message.data.uuid);
      else if (message.name == 'download printed assignments')
        this.downloadFile('/api/assignment/file', message.data.uuid);
      else if (message.name == 'download printed invoices') this.downloadFile('/api/invoice/file', message.data.uuid);
      else if (message.name == 'download pdf') this.downloadFile('/api/report', null, message.data.filename);
      else throw Error('Message name cannot be found');
    }
  },
  downloadFile(endpoint, uuid, filename) {
    this.ea.$emit('downloadFile', { endpoint, uuid, filename });
  },
  getCookie(name) {
    var value = '; ' + document.cookie;
    var parts = value.split('; ' + name + '=');
    if (parts.length == 2) return parts.pop().split(';').shift();
  },
};
