export default {
  getTripType,
  getTripTypes,
  saveTripType,
  deleteTripTypes,
};

/////////////

import api from '@/shared/api.service';

async function getTripType(id) {
  return await api.get('/api/trip-type/' + id);
}

async function getTripTypes(options) {
  return await api.get('/api/trip-type' + (options && options.forTable ? '?table=1' : ''));
}

async function saveTripType(params) {
  return await api.post('/api/trip-type', params);
}

async function deleteTripTypes(ids) {
  return await api.delete('/api/trip-type', {
    params: { ids },
  });
}
