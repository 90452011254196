<template>
    <div :class="containerClasses">
        <v-img :src="logoUrl" contain alt="logo" />
    </div>
</template>
<script>
export default {
    name: 'TravelTrackerLogo',
    props: {
        short: { type: Boolean, default: false },
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
        }
    },
    computed: {
        logoUrl() {
            const logoFileName = this.short ? 'logo-short.svg' : 'logo.svg'
            return `${this.publicPath}${logoFileName}`
        },
        containerClasses() {
            return {
                'd-flex': true,
                'justify-center': true,
                'px-4': !this.short,
            };
        }
    }
}
</script>