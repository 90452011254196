export const state = {
  client: window.location.href.match(/\.com\/(\w+)/) ? window.location.href.match(/\.com\/(\w+)/)[1] : '',
  clientConfig: {},
  loading: false,
  isMobile: false,
  currentSemester: {},
  currentFiscalYear: {},
  hasTripTicketTemplate: false,
  hasPermissionSlipEnglishTemplate: false,
  hasPermissionSlipSpanishTemplate: false,
  hasTripEstimateTemplate: false,
  tripRequestListFilters: [{ field: 'date', value: 'upcoming', text: 'Upcoming' }],
  assignmentListFilters: [{ field: 'date', value: 'upcoming', text: 'Upcoming' }],
  tripRequestListSort: { index: 0, order: 'asc' },
  assignmentListSort: { index: 0, order: 'asc' },
  dashboardPanel: null,
  dashboardInvoicePanel: null,
  tripCalendar: {
    active: false,
    type: 'month',
    focus: '',
    locationFilter: 0,
    tripTypeFilter: 0,
    tripEventFilter: 0,
    zoneFilter: 0,
  },
  vehicleCalendar: {
    active: false,
    type: 'month',
    focus: '',
    requestingLocationFilter: 0,
    vehicleLocationFilter: 0,
    vehicleTypeFilter: 0,
    tripTypeFilter: 0,
    zoneFilter: 0,
    vehicleFilter: 0,
    vehicleOwnerFilter: '',
  },
  driverCalendar: {
    active: false,
    type: 'month',
    focus: '',
    requestingLocationFilter: 0,
    vehicleTypeFilter: 0,
    tripTypeFilter: 0,
    driverFilter: 0,
    driverEmailFilter: null,
    zoneFilter: 0,
  },
  nonce: '',
  confirmationOptions: {
    message: '',
    choice: false,
  },
  snackbarOptions: {
    color: 'error',
    message: '',
    timeout: 4000,
  },
};
