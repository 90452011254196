export default {
  getAdditionalTransportations,
  saveAdditionalTransportation,
  deleteAdditionalTransportations,
};

////////////

import api from '@/shared/api.service';

async function getAdditionalTransportations() {
  return await api.get('/api/additional-transportation');
}

async function saveAdditionalTransportation(approvedCharter) {
  return await api.post('/api/additional-transportation', approvedCharter);
}

async function deleteAdditionalTransportations(ids) {
  return await api.delete('/api/additional-transportation', {
    params: { ids },
  });
}
