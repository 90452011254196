import _ from 'lodash';

export const getters = {
  additionalTransportations(state) {
    return state.additionalTransportations;
  },
  additionalTransportationsById(state) {
    const byId = {};
    state.additionalTransportations.forEach((e) => (byId[e.id] = e));
    return byId;
  },
  additionalTransportationsByName(state) {
    const byName = {};
    state.additionalTransportations.forEach((e) => (byName[e.name.toLowerCase()] = e));
    return byName;
  },
  additionalTransportationsByType(state) {
    return _.groupBy(state.additionalTransportations, (e) => e.type);
  },
};
