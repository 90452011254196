import _ from 'lodash';

export const getters = {
  drivers(state) {
    return state.drivers;
  },
  driversById(state) {
    const byId = {};
    state.drivers.forEach((e) => (byId[e.id] = e));
    return byId;
  },
  designations(state) {
    const des = _.uniq(state.drivers.filter((e) => e.designation).map((e) => e.designation));
    if (!des.length) return [];
    else return des.sort();
  },
  designationItems(state) {
    const des = _.uniq(state.drivers.filter((e) => e.designation).map((e) => e.designation));
    if (!des.length) return [{ header: 'No designations exist yet, type in a new one' }];
    else return [{ header: 'Select an existing designation or enter a new one' }, ..._.uniq(des.sort())];
  },
  positions(state) {
    const pos = _.uniq(state.drivers.filter((e) => e.position).map((e) => e.position));
    if (!pos.length) return [];
    else return pos.sort();
  },
  positionItems(state) {
    const pos = _.uniq(state.drivers.filter((e) => e.position).map((e) => e.position));
    if (!pos.length) return [{ header: 'No positions exist yet, type in a new one' }];
    else return [{ header: 'Select an existing position or enter a new one' }, ..._.uniq(pos.sort())];
  },
  indicators(state) {
    let inds = [];
    const mapped = state.drivers.filter((e) => e.indicators && e.indicators.length).map((e) => e.indicators);
    mapped.forEach((e) => (inds = inds.concat(e)));
    if (!mapped.length) return [];
    else return _.uniq(inds);
  },
  indicatorItems(state) {
    let inds = [];
    const mapped = state.drivers.filter((e) => e.indicators && e.indicators.length).map((e) => e.indicators);
    mapped.forEach((e) => (inds = inds.concat(e)));
    if (!mapped.length) return [{ header: 'No indicators exist yet, type in a new one and hit ENTER' }];
    else return [{ header: 'Select an existing indicator or enter a new one' }, ..._.uniq(inds)];
  },
};
