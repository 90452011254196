export default {
  setConfig,
  getConfig,
  getAllConfigs,

  getTripDateTimes,
  saveTripDateTime,
  deleteTripDateTimes,

  getSpecialIndicators,
  saveSpecialIndicator,
  deleteSpecialIndicators,

  getSpecialTravelNeeds,
  saveSpecialTravelNeed,
  deleteSpecialTravelNeeds,

  getDistrictWideRates,
  saveDistrictWideRate,
  deleteDistrictWideRates,

  getFuelPrices,
  saveFuelPrice,
  deleteFuelPrices,

  getAdditionalInvoiceCosts,
  saveAdditionalInvoiceCost,
  deleteAdditionalInvoiceCosts,

  saveCustomFormField,
  getCustomFormFields,
  getCustomFormFieldTypes,
  getCustomFormFieldsForFillup,
  saveCustomFormFieldValues,
};

//////////////////////

import api from '@/shared/api.service';

async function setConfig(name, value) {
  return await api.post('/api/config/set', { name, value });
}

async function getConfig(name) {
  return await api.get('/api/config/get', { params: { name } });
}

async function getAllConfigs(configs) {
  return await api.post('/api/config/startupConfigs', configs );
}

async function getTripDateTimes(type) {
  return await api.get('/api/config/trip-date-time', { params: { type } });
}

async function saveTripDateTime(dt) {
  return await api.post('/api/config/trip-date-time', dt);
}

async function deleteTripDateTimes(ids) {
  return await api.delete('/api/config/trip-date-time', {
    params: { ids },
  });
}

async function getSpecialIndicators() {
  return await api.get('/api/config/special-indicator');
}

async function saveSpecialIndicator(si) {
  return await api.post('/api/config/special-indicator', si);
}

async function deleteSpecialIndicators(ids) {
  return await api.delete('/api/config/special-indicator', {
    params: { ids },
  });
}

async function getSpecialTravelNeeds() {
  return await api.get('/api/config/special-travel-need');
}

async function saveSpecialTravelNeed(si) {
  return await api.post('/api/config/special-travel-need', si);
}

async function deleteSpecialTravelNeeds(ids) {
  return await api.delete('/api/config/special-travel-need', {
    params: { ids },
  });
}

async function getDistrictWideRates() {
  return await api.get('/api/config/district-wide-rate');
}
async function saveDistrictWideRate(dwr) {
  return await api.post('/api/config/district-wide-rate', dwr);
}
async function deleteDistrictWideRates(ids) {
  return await api.delete('/api/config/district-wide-rate', {
    params: { ids },
  });
}

async function getFuelPrices() {
  return await api.get('/api/config/fuel-price');
}
async function saveFuelPrice(fp) {
  return await api.post('/api/config/fuel-price', fp);
}
async function deleteFuelPrices(ids) {
  return await api.delete('/api/config/fuel-price', {
    params: { ids },
  });
}

async function getAdditionalInvoiceCosts() {
  return await api.get('/api/config/additional-invoice-cost');
}
async function saveAdditionalInvoiceCost(aic) {
  return await api.post('/api/config/additional-invoice-cost', aic);
}
async function deleteAdditionalInvoiceCosts(ids) {
  return await api.delete('/api/config/additional-invoice-cost', {
    params: { ids },
  });
}

async function saveCustomFormField(cff) {
  return await api.post('/api/config/store-custom-form-fields', cff);
}

async function getCustomFormFields(module) {
  return await api.get('/api/config/get-custom-form-fields', {
    params: { module },
  });
}

async function getCustomFormFieldTypes() {
  return await api.get('/api/config/get-custom-form-field-types');
}

async function getCustomFormFieldsForFillup(module, section, invoiceId) {
  return await api.get('/api/config/get-custom-form-fields-for-fillup', {
    params: { module, section, invoiceId },
  });
}

async function saveCustomFormFieldValues(cff) {
  return await api.post('/api/config/save-custom-form-field-values', cff);
}
