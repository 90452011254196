import locationApi from '@/apis/location';

export const DELETE_LOCATIONS = 'deleteLocations';
export const GET_LOCATION = 'getLocation';
export const GET_LOCATIONS = 'getLocations';
export const GET_LOCATION_VEHICLE_ORDER_OF_ASSIGNMENTS = 'getLocationVehicleOrderOfAssignments';
export const GET_SCHOOLS = 'getSchools';
export const QUERY_LOCATIONS = 'queryLocations';
export const SAVE_LOCATION = 'saveLocation';
export const UPDATE_SLA = 'updateSLA';

export const GET_ZONES = 'getZones';

export const actions = {
  async [GET_LOCATIONS]({ commit }, options) {
    const result = await locationApi.getLocations(options);
    commit('setLocations', result);

    const byId = {};
    result.forEach((e) => (byId[e.id] = e));

    commit('setLocationsById', byId);
  },
  async [QUERY_LOCATIONS](_, options) {
    return await locationApi.getLocations(options);
  },
  async [GET_LOCATION](_, id) {
    const result = await locationApi.getLocation(id);
    return result;
  },
  async [SAVE_LOCATION](_, location) {
    return await locationApi.saveLocation(location);
  },
  async [UPDATE_SLA](_, toUpdate) {
    return await locationApi.updateSLA(toUpdate);
  },
  async [DELETE_LOCATIONS](_, id) {
    return await locationApi.deleteLocations(id);
  },
  async [GET_LOCATION_VEHICLE_ORDER_OF_ASSIGNMENTS](_, id) {
    return await locationApi.getLocationVehicleOrderOfAssignments(id);
  },
  async [GET_ZONES]({ commit }) {
    const result = await locationApi.getZones();
    commit('setZones', result);
  },
};
