<template>
  <div>
    <v-row>
      <v-col cols="4">
        <div>
          <v-btn color="primary" @click="showNewUser = true">Add User</v-btn>
        </div>      
        <v-list two-line>
          <v-list-item-group>
            <div v-for="(user, index) in users" :key="user.uuid">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ user.firstName }} {{ user.lastName }}</v-list-item-title>
                  <v-list-item-subtitle class="text--primary">{{ user.email }} </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>
                    <v-btn v-if="users.length > 2" icon @click="removeUser(user)">
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
              <v-divider v-if="index < users.length - 1" :key="index"></v-divider>
            </div>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col cols="8">
        <div v-show="showNewUser">
          <v-card>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="12">
                  <v-text-field :value="newUserEmail" v-model.trim="newUserEmail" label="Email"></v-text-field>
                </v-col>
              </v-row>
              <v-btn v-if="!found.email" color="primary" @click="checkExist">Confirm Email</v-btn>
              <v-btn v-else color="success" @click="addUser">Add User</v-btn>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { CHECK_USER_EXIST } from '@/store/modules/User/actions';
import adminApi from '@/apis/admin.js';

export default {
  inject: ['eventHub'],
  components: {},
  data() {
    return {
      users: [],
      showNewUser: false,
      newUserEmail: '',
      found: {},
    };
  },
  computed: {},
  created() {
    this.fetchItems();
  },
  methods: {
    ...mapActions('user', [CHECK_USER_EXIST]),
    async fetchItems() {
      this.users = await adminApi.getAdminUsers();
    },
    async addUser() {
      try {
        const r = await adminApi.addAdminUser({
          email: this.found.email,
          uuid: this.found.uuid,
          firstName: this.found.firstName,
          lastName: this.found.lastName,
        });
        if (r && r.id) {
          this.$myalert.success('User added');
          this.showNewUser = false;
          await this.fetchItems();
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    async removeUser(user) {
      const ok = await this.$myconfirm('Are you sure you want to delete this User?');
      if (ok) {
        try {
          const r = await adminApi.deleteClientUser('admin', user.id);
          if (r.done) {
            this.$myalert.success('User removed');
            this.users = this.users.filter((u) => u.id !== user.id);
          }
        } catch (e) {
          this.$myalert.error(e.message);
        }
      }
    },
    async checkExist() {
      this.found = await this.checkUserExist({ email: this.newUserEmail });
      if (!this.found.email) this.$myalert.error('This email address does not have an account and cannot be added');
    },
  },
};
</script>

<style scoped></style>
