import attachmentApi from '@/apis/attachment';

export const GET_FILES = 'getFiles';
export const UPLOAD_FILE = 'uploadFile';
export const DOWNLOAD_FILE = 'downloadFile';
export const DELETE_FILE = 'deleteFile';
export const DELETE_FILES = 'deleteFiles';

export const actions = {
  async [GET_FILES](_, { type, recordId }) {
    const result = await attachmentApi.getFiles(type, recordId);
    return result;
  },
  async downloadFiles(_, recordIds) {
    const result = await attachmentApi.downloadFiles(recordIds);
    return result;
  },
  async [UPLOAD_FILE](_, file) {
    return await attachmentApi.uploadFile(file);
  },
  async [DOWNLOAD_FILE](_, id) {
    const result = await attachmentApi.downloadFile(id);
    return result;
  },
  async [DELETE_FILE](_, { id, filename }) {
    return await attachmentApi.deleteFile(id, filename);
  },
  async [DELETE_FILES](_, { type, recordId }) {
    return await attachmentApi.deleteFiles(type, recordId);
  },
};
