export const getters = {
  vehicles(state) {
    return state.vehicles;
  },
  vehiclesById(state) {
    const byId = {};
    state.vehicles.forEach((e) => (byId[e.id] = e));
    return byId;
  },
  vehiclesByName(state) {
    const byName = {};
    state.vehicles.forEach((e) => (byName[e.name.toLowerCase()] = e));
    return byName;
  },
};
