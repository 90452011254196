export default {
  getStaffs,
};

import api from '@/shared/api.service';

const apiUrl = `/api/staff`;

async function getStaffs({ active }) {
  const params = {};
  if (active) params.active = true;

  return await api.get(apiUrl, { params });
}
