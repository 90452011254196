export default {
  saveTripRequest,
  submitTripRequest,
  changeSubmitter,
  resetApproval,
  cancelTrips,
  reinstateTrip,
  rescheduleTrip,
  duplicateTripRequest,
  updateAssignmentLocation,
  getTripRequest,
  getTripRequests,
  getTripRequestsForCalendar,
  getTripRequestCompleteStatus,
  deleteTripRequest,
  deleteAllTripRequests,
  printTrips,
  exportTrips,
  getTripRequestStops,
  saveTripRequestStop,
  deleteTripRequestStop,
  saveTripRequestComment,
  sendTripRequestEmail,
  requestQuote,
  saveTripRecurrence,
  downloadTripTicket,
  uploadTripTicketTemplate,
  downloadTripTicketTemplate,
  downloadPermissionSlip,
  uploadPermissionSlipTemplate,
  downloadPermissionSlipTemplate,
  downloadTripEstimate,
  sendScheduledNotifications,
};

//////////////////////

import api from '@/shared/api.service';

async function getTripRequests() {
  try {
    return await api.get('/api/trip-request');
  } catch (error) {
    if (error?.errcode && error?.errcode === '613CA956') return window.location.reload();
    throw error;
  }
}

async function getTripRequest(id) {
  const response = await api.get(`/api/trip-request/${id}`);
  return response;
}

async function getTripRequestCompleteStatus(params) {
  const response = await api.post('/api/trip-request/complete', params);
  return response;
}
async function getTripRequestsForCalendar() {
  return await api.get('/api/calendar/trip-request');
}

async function saveTripRequest(params) {
  return await api.post('/api/trip-request', params);
}

async function submitTripRequest(id) {
  return await api.post('/api/trip-request/submit', { id: id });
}

async function changeSubmitter(params) {
  return await api.put(`/api/trip-request/submit`, params);
}

async function resetApproval(params) {
  return await api.post('/api/trip-request/reset-approval', params);
}

async function cancelTrips(params) {
  return await api.put(`/api/trip-request/cancel`, params);
}

async function reinstateTrip(id) {
  return await api.put(`/api/trip-request/reinstate/${id}`);
}

async function rescheduleTrip(params) {
  return await api.put(`/api/trip-request/reschedule`, params);
}

async function duplicateTripRequest(id) {
  return await api.post(`/api/trip-request/duplicate/${id}`);
}

async function updateAssignmentLocation(params) {
  return await api.put(`/api/trip-request/assignment-location`, params);
}

async function deleteTripRequest(id) {
  return await api.delete('/api/trip-request', {
    params: { id: id },
  });
}

async function deleteAllTripRequests() {
  return await api.delete('/api/trip-request/all');
}

async function printTrips(params) {
  return await api.post('/api/trip-request/print', params);
}

async function exportTrips(params) {
  return await api.post('/api/trip-request/export', params);
}

//////////////////////

async function getTripRequestStops() {
  return await api.get('/api/trip-request/stop');
}

async function saveTripRequestStop(params) {
  return await api.post('/api/trip-request/stop', params);
}

async function deleteTripRequestStop(id) {
  return await api.delete('/api/trip-request/stop', {
    params: { id: id },
  });
}

//////////////////////

async function saveTripRequestComment(params) {
  return await api.post('/api/trip-request/comment', params);
}

async function sendTripRequestEmail(params) {
  return await api.post('/api/trip-request/email', params);
}

//////////////////////

async function requestQuote(params) {
  return await api.post('/api/trip-request/quote', params);
}

//////////////////////

async function saveTripRecurrence(obj) {
  return await api.post('/api/trip-request/recurrence', obj);
}

//////////////////////

async function downloadTripTicket(obj) {
  return await api.post('/api/trip-ticket/run', obj);
}
async function uploadTripTicketTemplate(obj) {
  return await api.post('/api/trip-ticket/upload', obj);
}
async function downloadTripTicketTemplate() {
  return await api.get('/api/trip-ticket/template');
}
async function downloadPermissionSlip(obj) {
  return await api.post('/api/permission-slip/run', obj);
}
async function uploadPermissionSlipTemplate(obj) {
  return await api.post('/api/permission-slip/upload', obj);
}
async function downloadPermissionSlipTemplate() {
  return await api.get('/api/permission-slip/template');
}
async function downloadTripEstimate(obj) {
  return await api.post('/api/trip-estimate/run', obj);
}

//////////////////////

async function sendScheduledNotifications() {
  return await api.post('/api/trip-request/notify-test');
}
