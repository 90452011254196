import geoApi from '@/apis/geo';

export const GEOCODE = 'geocode';
export const REVERSE_GEOCODE = 'reverseGeocode';
export const ROUTE = 'route';

export const actions = {
  async [GEOCODE](_, obj) {
    return await geoApi.geocode(obj);
  },
  async [REVERSE_GEOCODE](_, obj) {
    return await geoApi.reverseGeocode(obj);
  },
  async getAddressFromSchoolDistrict(_, obj) {
    return await geoApi.getAddressFromSchoolDistrict(obj);
  },
  async [ROUTE](_, obj) {
    return await geoApi.route(obj);
  },
};
