import rosterApi from '@/apis/roster';

export const getStudentRoster = 'getStudentRoster';
export const getAdultRoster = 'getAdultRoster';
export const saveStudent = 'saveStudent';

export const actions = {
  async [getStudentRoster]({ commit }, options) {
    const result = await rosterApi.getStudentRoster(options);
    commit('setStudentRoster', result);
  },

  async [getAdultRoster]({ commit }, options) {
    const result = await rosterApi.getAdultRoster(options);
    commit('setAdultRoster', result);
  },

  async [saveStudent](_, roster) {
    return await rosterApi.saveStudent(roster);
  },
};