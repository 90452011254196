export default {
  getApprovalLevels,
  deleteApprovalLevel,
  deleteApprovalLevels,
  saveApprovalLevel,
  reorderApprovalLevels,
  getRequiredApprovalLevels,
};

////////////

import api from '@/shared/api.service';

async function getApprovalLevels() {
  return await api.get('/api/approval-level', {
    params: {},
  });
}

async function deleteApprovalLevel(id) {
  return await api.delete(`/api/approval-level/${id}`);
}

async function deleteApprovalLevels(ids) {
  return await api.delete(`/api/approval-level/bulk/${ids.join(',')}`);
}

async function saveApprovalLevel(params) {
  if (params.id) return await api.put(`/api/approval-level/${params.id}`, params);
  else return await api.post('/api/approval-level', params);
}

async function reorderApprovalLevels(params) {
  return await api.put('/api/approval-level/reorder', params);
}

async function getRequiredApprovalLevels(requestId) {
  return await api.get(`/api/approval-level/required/${requestId}`);
}
