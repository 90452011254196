export default {
  getDestination,
  getDestinations,
  getDestinationCategories,
  saveDestination,
  deleteDestinations,
  checkUnique,
  removeProspectFromDestinations,
};

//////////////

import api from '@/shared/api.service';

async function getDestinations(options = {}) {
  return await api.get('/api/destination' + (options.forTable ? '?table=1' : ''), { params: options });
}

async function getDestination(id) {
  return await api.get(`/api/destination/${id}`);
}

async function deleteDestinations(ids) {
  return await api.delete('/api/destination', {
    params: { ids: ids },
  });
}

async function saveDestination(params) {
  return await api.post('/api/destination', params);
}

async function getDestinationCategories() {
  return await api.get('/api/destination/categories', {
    params: {},
  });
}

async function checkUnique(name) {
  return await api.get('/api/destination/check', {
    params: { name },
  });
}

async function removeProspectFromDestinations(ids) {
  return api.put('/api/destination/remove-prospect', { ids });
}
