export const getters = {
  budgetCodesByLocation(state, getters, rootState, rootGetters) {
    const generateDescription = (budgetCode, locationCode, budgetCodeConfigMapped) => {
      const codeValues = Object.entries(budgetCode.code)
        .map(([key, value]) => {
          const config = budgetCodeConfigMapped[key.toLowerCase()];
          const isLocationDependent = config?.locationDependent;
          const matchesNumChar = locationCode.length === parseInt(config?.numChar, 10);

          if (isLocationDependent && value.includes('?') && matchesNumChar) return locationCode;

          return value;
        })
        .join(' ');

      return `${budgetCode.name} ${codeValues}`;
    };

    const filterBudgetCodes = ({ budgetCodes, locationCode, budgetCodeConfigMapped }) => {
      return budgetCodes.filter((budgetCode) => {
        for (const [key, value] of Object.entries(budgetCode.code)) {
          const config = budgetCodeConfigMapped[key.toLowerCase()];

          if (!config || !config.locationDependent) continue;

          if (value.includes('?') && locationCode.length !== parseInt(config.numChar, 10)) return false;

          if (!value.includes('?')) return false;
        }

        // Add description
        budgetCode.description = generateDescription(budgetCode, locationCode, budgetCodeConfigMapped);
        return true;
      });
    };

    const budgetCodes = state.budgetCodes;
    const locationCode = rootGetters['tripRequest/currentTripLocation']?.code;
    const budgetCodeConfigMapped = rootGetters['config/budgetCodeConfigMapped'];

    return filterBudgetCodes({
      budgetCodes,
      locationCode,
      budgetCodeConfigMapped,
    });
  },
  hasExisingBudgetCodes(state) {
    return state.budgetCodes?.length > 0;
  },
  budgetCodes(state) {
    return state.budgetCodes;
  },
};
