import Alert from '@/components/Alert.vue';

function handleParams(options) {
  if (typeof options === 'string' || Array.isArray(options)) {
    return { message: options };
  } else {
    return options;
  }
}

function install(Vue, options) {
  const vuetify = options.vuetify;
  let lastAlertInstance = null;

  function removePreviousAlert() {
    if (lastAlertInstance && lastAlertInstance.$el) {
      const container = document.body;
      if (container.contains(lastAlertInstance.$el)) {
        container.removeChild(lastAlertInstance.$el);
      }
      lastAlertInstance = null;
    }
  }

  Vue.prototype.$myalert = (options, removePrevious = false) => createAlert(handleParams(options), removePrevious);
  
  Vue.prototype.$myalert.info = (options, removePrevious = false) =>
    createAlert({ ...handleParams(options), type: 'info' }, removePrevious);
  Vue.prototype.$myalert.success = (options, removePrevious = false) =>
    createAlert({ ...handleParams(options), type: 'success' }, removePrevious);
  Vue.prototype.$myalert.error = (options, removePrevious = false) =>
    createAlert({ ...handleParams(options), type: 'error' }, removePrevious);
  Vue.prototype.$myalert.warning = (options, removePrevious = false) =>
    createAlert({ ...handleParams(options), type: 'warning' }, removePrevious);

  function createAlert(options, removePrevious = false) {
    if (removePrevious) {
      removePreviousAlert();
    }

    const container = document.body;
    return new Promise((resolve) => {
      const VueComponent = Vue.extend(Alert);
      const vm = new VueComponent({
        vuetify,
        destroyed: () => {
          if (vm === lastAlertInstance) {
            lastAlertInstance = null;
          }
          container.removeChild(vm.$el);
          resolve(vm.result);
        },
      }).$mount();
      container.appendChild(vm.$el);
      lastAlertInstance = vm;
      resolve(vm.show(options));
    });
  }
}

export default install;
