export default {
  getDrivers,
  getDriverById,
  getAvailableDrivers,
  saveDriver,
  deleteDrivers,
};

////////////

import api from '@/shared/api.service';

async function getDrivers(options) {
  return await api.get('/api/driver' + (options && options.forTable ? '?table=1' : ''));
}

async function getDriverById(id) {
  return await api.get(`/api/driver/${id}`);
}

async function getAvailableDrivers(params) {
  let str = `/api/driver/available?fromDate=${params.fromDate}&fromTime=${params.fromTime}&toDate=${params.toDate}&toTime=${params.toTime}`;
  if (params.sortMethod) str += `&sortMethod=${params.sortMethod}`;
  if (params.override) str += `&override=${params.override}`;
  return await api.get(str);
}

async function saveDriver(driver) {
  return await api.post('/api/driver', driver);
}

async function deleteDrivers(ids) {
  return await api.delete('/api/driver', {
    params: { ids },
  });
}
