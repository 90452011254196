import vehicleApi from '@/apis/vehicle';

export const GET_VEHICLES = 'getVehicles';
export const GET_VEHICLE = 'getVehicle';
export const GET_AVAILABLE_VEHICLES = 'getAvailableVehicles';
export const SAVE_VEHICLE = 'saveVehicle';
export const DELETE_VEHICLES = 'deleteVehicles';

export const actions = {
  async [GET_VEHICLES]({ commit }, options) {
    const result = await vehicleApi.getVehicles(options);
    commit('setVehicles', result);
  },
  async [GET_VEHICLE](_, id) {
    const result = await vehicleApi.getVehicle(id);
    return result;
  },
  async [GET_AVAILABLE_VEHICLES](_, obj) {
    const result = await vehicleApi.getAvailableVehicles(obj);
    return result;
  },
  async [SAVE_VEHICLE](_, vehicle) {
    return await vehicleApi.saveVehicle(vehicle);
  },
  async [DELETE_VEHICLES](_, id) {
    return await vehicleApi.deleteVehicles(id);
  },
};
