export const mutations = {
  setMe(state, me) {
    state.me = me;
  },
  setUsers(state, users) {
    state.users = users;
  },
  setRoles(state, roles) {
    state.roles = roles;
  },
  setRoleAssignments(state, roles) {
    state.roleAssignments = roles;
  },
  setVehicleOwners(state, vos) {
    state.vehicleOwners = vos;
  },
  setYellowfinToken(state, token) {
    state.yellowfinToken = token;
  },
  setYellowfinRedirectUrl(state, url) {
    state.yellowfinRedirectUrl = url;
  },
};
