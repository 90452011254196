import { getLocalStorage, setLocalStorage } from '@/util';
import { cloneDeep, remove, isEqual } from 'lodash';
export const mutations = {
  /**
   * Set invoice items
   * @param {*} state
   * @param {array} invoices
   */
  setInvoices(state, invoices) {
    state.invoices = invoices;
  },

  /**
   * Sets the column's show/hide attribute based on the saved preference
   * 
   * @param {*} state 
   * @param {*} userId 
   * @returns 
   */
  setUserPreference(state, userId) {
    const savedColumns = getLocalStorage(`columnPreference-${userId}`);
    if (!savedColumns) return;

    const columns = cloneDeep(state.columns);

    for (const column of columns) {
      const columnPreference = savedColumns[column.text];
      if (columnPreference == undefined) {
        return;
      }

      column.show = columnPreference.show;
    }

    state.columns = columns;
  },

  /**
   * Set invoice filters
   * @param {*} state
   * @param {object} filters
   */
  setInvoiceFilters(state, filters = {}) {
    state.filters = filters;
  },

  /**
   * Set invoice read only state
   * @param {*} state
   * @param {boolean} readOnly
   */
  setInvoiceReadOnly(state, readOnly) {
    state.readOnly = readOnly;
  },

  /**
   * Set invoice loading
   * @param {*} state
   * @param {booleanbject} loading
   */
  setLoading(state, { type, isLoading }) {
    state.loading[type] = isLoading;
  },

  /**
   * Setter for isFilterDisabled
   * @param {*} state
   */
  setInvoiceFilterDisabled(state, disabled) {
    state.isFilterDisabled = disabled;
  },

  /**
   * Set invoice pagination
   * @param {*} state
   */
  setInvoicePagination(state, options) {
    state.searchOptions.page = options;
  },

  /**
   * Set invoice sort
   * @param {*} state
   */
  setInvoiceSort(state, sort) {
    state.searchOptions.sort = sort;
  },

  /**
   * Set the selectd invoice
   * @param {*} state
   * @param {*} invoice
   */
  setSelectedInvoice(state, invoice) {
    state.selectedInvoice = invoice;
  },

  /**
   * Set invoice details
   * @param {*} state
   */
  setInvoiceDetails(state, { type, details }) {
    state.details[type] = details;
  },

  /**
   * Sets the invoice columns and saved it as a user preference
   * @param {*} state 
   * @param {*} param1 
   */
  setColumns(state, { columns, userId}) {
    state.columns = columns;
    const map = {};
    for (const column of columns) map[column.text] = { show: column.show };
    setLocalStorage(`columnPreference-${userId}`, map);
  },

  getAttachments(state, attachments) {
    state.details.attachments = attachments;
  },

  removeAttachment(state, attachment) {
    remove(state.details.attachments, function (n) {
      return isEqual(n, attachment);
    });
    state.details.attachments = [...state.details.attachments];
  },

  addAdditionalCharge(state, additionalCharge) {
    const tab = 'additionalCharge';
    const existingAdditionalCharge = state.details[tab]?.invoiceAdditionalCharge || [];
    state.details[tab].invoiceAdditionalCharge = [...existingAdditionalCharge, additionalCharge];
  },
};
