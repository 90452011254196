import invoiceApi from '@/apis/invoice';

const _loadingMap = {
  staff_cost: 'staffCost',
  travel_cost: 'travelCost',
  additional_charge: 'additionalCharge',
  history: 'history',
  payment: 'payment',
};

export const actions = {
  setInvoiceReadOnlyState({ state, commit, rootState }) {
    const { me } = rootState.user;

    const isAdmin = me.is.limitedAdmin || me.is.siteAdmin || me.is.superAdmin || me.is.transportationAdmin;
    const isNonAdminFundingManager = me.is.fundingManager && !isAdmin;
    const isNonAdminSchoolFinance = me.is.schoolFinance && !isAdmin;
    commit('setInvoiceReadOnly', !rootState.user.me.can.edit.invoice || isNonAdminFundingManager || isNonAdminSchoolFinance);
  },

  async getInvoices({ state, commit }) {
    const options = {
      ...state.searchOptions,
      filter: state.filters,
    };

    commit('setLoading', { type: 'invoice', isLoading: true });
    const invoices = await invoiceApi.getInvoices({ options });

    commit('setInvoices', invoices);
    commit('setLoading', { type: 'invoice', isLoading: false });
  },
  async queryInvoices(store, options) {
    return await invoiceApi.getInvoices(options);
  },
  async getInvoiceDetails({ commit }, { invoiceId, tab, manageLoading = true }) {
    if (manageLoading) commit('setLoading', { type: _loadingMap[tab], isLoading: true });

    const details = await invoiceApi.getInvoiceDetails({ invoiceId, tab });

    commit('setInvoiceDetails', { type: _loadingMap[tab] || 'invoice', details });
    if (manageLoading) commit('setLoading', { type: _loadingMap[tab], isLoading: false });
  },

  async saveInvoice({ state, dispatch, commit }, { body, invoiceId, fullRefresh = false }) {
    try {
      commit('setLoading', { type: 'invoice', isLoading: true });
      const result = await invoiceApi.saveInvoice({ body, invoiceId });

      if (fullRefresh) {
        dispatch('getInvoices');
      } else {
        commit('setInvoices', {
          ...state.invoices,
          data: [
            ...state.invoices.data.map((invoice) => {
              if (invoice.id === invoiceId) {
                return {
                  ...invoice,
                  ...body,
                  ...result,
                };
              }

              return { ...invoice };
            }),
          ],
        });

        // update the selected invoice if it exists
        if (state.selectedInvoice && state.selectedInvoice.id === invoiceId) {
          commit('setSelectedInvoice', {
            ...state.selectedInvoice,
            ...body,
            ...result,
          });
        }
      }
    } finally {
      commit('setLoading', { type: 'invoice', isLoading: false });
    }
  },

  async saveInvoiceStaffCost({ dispatch, commit }, { body, invoiceId }) {
    try {
      commit('setLoading', { type: 'staffCost', isLoading: true });
      await invoiceApi.saveInvoiceStaffCost({ body, invoiceId });
      dispatch('getInvoiceDetails', { invoiceId, tab: 'staff_cost', manageLoading: false });
    } finally {
      commit('setLoading', { type: 'staffCost', isLoading: false });
    }
  },

  async deleteInvoiceStaffById({ commit, dispatch }, { invoiceId, staffCostId }) {
    try {
      commit('setLoading', { type: 'staffCost', isLoading: true });
      await invoiceApi.deleteInvoiceStaffById({ staffCostId, invoiceId });
      dispatch('getInvoiceDetails', { invoiceId, tab: 'staff_cost', manageLoading: false });
    } finally {
      commit('setLoading', { type: 'staffCost', isLoading: false });
    }
  },

  async getAttachments({ commit }, attachmentID) {
    const attachments = await invoiceApi.getAttachmentsOfInvoice(attachmentID);
    commit('getAttachments', attachments);
  },

  async removeAttachment({ commit }, attachment) {
    commit('removeAttachment', attachment);
  },

  async saveInvoiceTravelCost({ dispatch, commit }, { body, invoiceId }) {
    try {
      commit('setLoading', { type: 'travelCost', isLoading: true });
      await invoiceApi.saveInvoiceTravelCost({ body, invoiceId });
      dispatch('getInvoiceDetails', { invoiceId, tab: 'travel_cost', manageLoading: false });
    } finally {
      commit('setLoading', { type: 'travelCost', isLoading: false });
    }
  },

  async saveInvoiceAdditionalCharge({ dispatch, commit }, { body, invoiceId }) {
    try {
      commit('setLoading', { type: 'additionalCharge', isLoading: true });
      await invoiceApi.saveInvoiceAdditionalCharge({ body, invoiceId });
      dispatch('getInvoiceDetails', { invoiceId, tab: 'additional_charge', manageLoading: false });
    } finally {
      commit('setLoading', { type: 'additionalCharge', isLoading: false });
    }
  },

  async saveInvoicePayment({ dispatch, commit }, { body, invoiceId }) {
    try {
      commit('setLoading', { type: 'payment', isLoading: true });
      await invoiceApi.saveInvoicePayment({ body, invoiceId });
      dispatch('getInvoiceDetails', { invoiceId, tab: 'payment', manageLoading: false });
    } finally {
      commit('setLoading', { type: 'payment', isLoading: false });
    }
  },

  async deleteInvoicePaymentById({ dispatch, commit }, { invoiceId, id }) {
    try {
      commit('setLoading', { type: 'payment', isLoading: true });
      await invoiceApi.deleteInvoicePaymentById({
        invoiceId,
        id,
      });
      dispatch('getInvoiceDetails', { invoiceId, tab: 'payment', manageLoading: false });
    } finally {
      commit('setLoading', { type: 'payment', isLoading: false });
    }
  },

  async deleteInvoiceCommentById({ dispatch }, { invoiceId, commentId }) {
    await invoiceApi.deleteInvoiceCommentById({
      invoiceId,
      commentId,
    });
    dispatch('getInvoiceDetails', { invoiceId, tab: 'history', manageLoading: false });
  },

  async addInvoiceComment({ dispatch }, { invoiceId, commentForm }) {
    await invoiceApi.addInvoiceComment({ invoiceId, body: commentForm });
    dispatch('getInvoiceDetails', { invoiceId, tab: 'history', manageLoading: false });
  },

  async getSetupTableTravelCost(_, params) {
    return await invoiceApi.getSetupTableTravelCost(params);
  },

  async deleteInvoiceTravelById({ commit, dispatch }, { invoiceId, id }) {
    try {
      commit('setLoading', { type: 'travelCost', isLoading: true });
      await invoiceApi.deleteInvoiceTravelById({ travelCostId: id, invoiceId });
      dispatch('getInvoiceDetails', { invoiceId, tab: 'travel_cost', manageLoading: false });
    } finally {
      commit('setLoading', { type: 'travelCost', isLoading: false });
    }
  },
  async printInvoices(_, params) {
    await invoiceApi.printInvoices(params);
  },
};
