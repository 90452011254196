import { actions } from '@/store/modules/App/actions';
import { getters } from '@/store/modules/App/getters';
import { mutations } from '@/store/modules/App/mutations';
import { state } from '@/store/modules/App/state';

const AppModule = {
  actions,
  getters,
  mutations,
  state,
  namespaced: true,
};

export default AppModule;
