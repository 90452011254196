export const state = {
  invoices: {
    data: [],
    total: 0,
  },
  filters: { text: null, status: 'pending' },
  isFilterDisabled: false,
  readOnly: false,
  searchOptions: {
    page: {
      currentPage: 1,
      pageSize: 20,
    },
    sort: {
      column: 'tripRequestId',
      order: 'desc',
    },
  },
  sortOptions: [
    { text: 'Trip #', value: 'tripRequestId', order: 'asc' },
    { text: 'Leave Date', value: 'leaveDate', order: 'asc' },
    { text: 'Trip Type', value: 'tripType', order: 'asc' },
  ],
  // should fetch columms from api
  columns: [
    { text: 'Trip #', value: 'tripRequestId', show: true, default: true, },
    { text: 'Trip Date', value: 'tripDate', show: true },
    { text: 'Location', value: 'requestingLocation', show: true },
    { text: 'Driver', value: 'driver', show: true },
    { text: 'Trip Type', value: 'tripType', show: true },
    { text: 'Staff Costs', value: 'staffCost', align: 'end', show: true },
    { text: 'Travel Costs', value: 'travelCost', align: 'end', show: true },
    { text: 'Additional Costs', value: 'additionalCost', align: 'end', show: true },
    { text: 'Totals', value: 'total', align: 'end', show: true, default: true, },
    { text: 'Funding', value: 'invoicePayments', show: true },
    { text: 'Check #', value: 'invoicePayments', show: false },
    { text: 'Batch/Journal #', value: 'invoicePayments', show: false },
    { text: 'Status', value: 'status', type: 'chips', show: true, default: true, },
  ],
  statusConfig: {
    paid: { color: '#C7E6B8', text: 'Paid' },
    pending: { color: '#D5D5D5', text: 'Pending' },
    approved: { color: '#FDDDBB', text: 'Approved' },
    sent: { color: '#88d3e3', text: 'Sent' },
    do_not_bill: { color: '#F7EFF4', text: 'Do Not Bill' },
  },
  selectedInvoice: null,
  details: {
    invoice: null, // a way to store the invoice details regardless of tab
    staffCost: null,
    travelCost: null,
    additionalCharge: null,
    funding: null,
    attachments: [],
    payment: null,
    history: null,
  },
  loading: {
    staffCost: false,
    travelCost: false,
    additionalCharge: false,
    invoice: false,
    history: false,
    payment: false,
  },
};
