export default {
  getMechanics,
  getMechanicById,
  saveMechanic,
  deleteMechanics,
};

////////////

import api from '@/shared/api.service';

async function getMechanics(options) {
  return await api.get('/api/mechanic' + (options && options.forTable ? '?table=1' : ''));
}

async function getMechanicById(id) {
  return await api.get(`/api/mechanic/${id}`);
}

async function saveMechanic(mechanic) {
  return await api.post('/api/mechanic', mechanic);
}

async function deleteMechanics(ids) {
  return await api.delete('/api/mechanic', {
    params: { ids },
  });
}
