export const getters = {
  tripEvents(state) {
    return state.tripEvents;
  },
  tripEventsById(state) {
    const byId = {};
    state.tripEvents.forEach((e) => (byId[e.id] = e));
    return byId;
  },
  tripEventsByName(state) {
    const byName = {};
    state.tripEvents.forEach((e) => (byName[e.name.toLowerCase()] = e));
    return byName;
  },
};
