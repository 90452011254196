import fiscalYearApi from '@/apis/fiscalYear';

export const GET_FISCAL_YEARS = 'getFiscalYears';
export const SAVE_FISCAL_YEAR = 'saveFiscalYear';
export const DELETE_FISCAL_YEAR = 'deleteFiscalYear';

export const actions = {
  async [GET_FISCAL_YEARS]({ commit }, options) {
    const result = await fiscalYearApi.getFiscalYears(options);
    commit('setFiscalYears', result);
    return result;
  },
  async [SAVE_FISCAL_YEAR](_, fiscalYear) {
    if (fiscalYear.id) return await fiscalYearApi.updateFiscalYear(fiscalYear);
    else return await fiscalYearApi.createFiscalYear(fiscalYear);
  },
  async [DELETE_FISCAL_YEAR](_, id) {
    return await fiscalYearApi.deleteFiscalYear(id);
  },
};
