export const getters = {
  locations(state) {
    return state.locations;
  },
  schools(state) {
    return state.locations.filter((e) => e.isSchool);
  },
  vehicleLocations(state) {
    return state.locations.filter((e) => e.isVehicleLocation);
  },
  locationsById(state) {
    return state.locationsById;
  },
  locationsByName(state) {
    const byName = {};
    state.locations.forEach((e) => (byName[e.name.toLowerCase()] = e));
    return byName;
  },
  schoolsById(state) {
    const byId = {};
    state.locations.filter((e) => e.isSchool).forEach((e) => (byId[e.schoolId] = e.school));
    console.log(byId);
    return byId;
  },
  depotsById(state) {
    const byId = {};
    state.locations.filter((e) => e.isVehicleLocation).forEach((e) => (byId[e.depotId] = e.depot));
    return byId;
  },
  zones(state) {
    return state.zones;
  },
  zoneItems(state) {
    return [{ header: 'Select an existing zone or enter a new one' }, ...state.zones];
  },
};
