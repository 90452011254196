export default {
  getFiles,
  uploadFile,
  downloadFile,
  checkRecordsAttachments,
  deleteFile,
  deleteFiles,
  downloadFiles,
};

//////////////////////

import api from '@/shared/api.service';

async function uploadFile(file) {
  return await api.post('/api/attachment', file);
}

async function getFiles(type, recordId) {
  return await api.get(`/api/attachment/${type}/${recordId}`);
}

async function downloadFile(id) {
  return await api.get(`/api/attachment/${id}`);
}

async function checkRecordsAttachments(recordType) {
  return await api.get(`/api/attachment/check/${recordType}`);
}

async function deleteFile(id, filename) {
  return await api.delete(`/api/attachment/${id}/${filename}`);
}

async function deleteFiles(type, recordId) {
  return await api.delete(`/api/attachment/${type}/${recordId}`);
}

async function downloadFiles(client, ids) {
  return await api.post(`/${client}/attachment/batchdownload`, { ids }, { responseType: 'blob' });
}
