import additionalVehicleChargeApi from '@/apis/additionalVehicleCharge';

export const GET_ADDITIONAL_VEHICLE_CHARGES = 'getAdditionalVehicleCharges';
export const GET_ADDITIONAL_VEHICLE_CHARGE = 'getAdditionalVehicleCharge';
export const SAVE_ADDITIONAL_VEHICLE_CHARGE = 'saveAdditionalVehicleCharge';
export const DELETE_ADDITIONAL_VEHICLE_CHARGES = 'deleteAdditionalVehicleCharges';

export const actions = {
  async [GET_ADDITIONAL_VEHICLE_CHARGES]({ commit }, options) {
    const result = await additionalVehicleChargeApi.getAdditionalVehicleCharges(options);
    commit('setAdditionalVehicleCharge', result);
  },
  async [GET_ADDITIONAL_VEHICLE_CHARGE](_, id) {
    const result = await additionalVehicleChargeApi.getAdditionalVehicleCharge(id);
    return result;
  },
  async [SAVE_ADDITIONAL_VEHICLE_CHARGE](_, additionalVehicleCharge) {
    return await additionalVehicleChargeApi.saveAdditionalVehicleCharge(additionalVehicleCharge);
  },
  async [DELETE_ADDITIONAL_VEHICLE_CHARGES](_, ids) {
    return await additionalVehicleChargeApi.deleteAdditionalVehicleCharges(ids);
  },
};
