<template>
  <div>
    <v-row>
      <v-col cols="4" class="panel">
        <v-btn color="primary" @click="createClient">Create Client</v-btn>
        <v-list two-line>
          <v-list-item-group v-model="selectedClient" active-class="colorBorder" class="transparentBorder">
            <div v-for="(client, index) in clients" :key="index">
              <v-list-item @click="showForm = false">
                <v-list-item-content>
                  <v-list-item-title class="d-flex justify-space-between">
                    <p>{{ client.districtName }}</p>
                    <div>
                      <v-icon v-if="client.data.modules.routing" large>mdi-alpha-r-circle</v-icon>
                      <v-chip color="blue" label outlined class="ml-2">{{
                        client.data.modules.tier.toLocaleUpperCase()
                      }}</v-chip>
                    </div>
                  </v-list-item-title>
                  <v-list-item-subtitle class="text--primary">{{ client.name }} </v-list-item-subtitle>
                  <v-list-item-subtitle>{{ client.org }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="index < clients.length - 1" :key="index"></v-divider>
            </div>
          </v-list-item-group>
        </v-list>
      </v-col>

      <v-col cols="8" v-show="showForm" class="panel">
        <v-card>
          <v-form>
            <v-container>
              <v-row dense>
                <v-col cols="12" md="3">
                  <v-text-field
                    :value="form.name"
                    v-model.trim="form.name"
                    label="Name"
                    placeholder="e.g. lancasterpa"
                    required
                    :error-messages="getValidationErrors"
                    @blur="validateName"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="7">
                  <v-combobox
                    :value="form.districtName"
                    v-model.trim="$v.form.districtName.$model"
                    :items="districts"
                    item-text="label"
                    item-value="name"
                    label="Search District"
                    @update:search-input="searchDistricts"
                    @change="handleDistrictChange"
                  >
                  </v-combobox>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    :value="form.state"
                    v-model.trim="$v.form.state.$model"
                    label="State"
                    required
                    :error-messages="handleErrors($v.form.state)"
                    @blur="$v.form.state.$touch()"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" md="3">
                  <span>Center Point</span>
                  <v-text-field
                    :value="form.locationLng"
                    v-model.number="$v.form.locationLng.$model"
                    required
                    :error-messages="handleErrors($v.form.locationLng)"
                    @blur="$v.form.locationLng.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <span class="white--text">-</span>
                  <v-text-field
                    :value="form.locationLat"
                    v-model.number="$v.form.locationLat.$model"
                    required
                    :error-messages="handleErrors($v.form.locationLat)"
                    @blur="$v.form.locationLat.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <span>BBox</span>
                  <p>({{ $v.form.bbox.$model.join(', ') }})</p>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" md="6">
                  <v-select
                    :items="ezrDomains"
                    v-model="$v.form.routingHost.$model"
                    label="Routing Host"
                    clearable
                    hint="Syncing data from routing will take a moment - please be patient"
                    persistent-hint
                  ></v-select>
                </v-col>
              </v-row>
              <v-btn color="success" class="mt-2" @click="create" :loading="saving">Save</v-btn>
            </v-container>
          </v-form>
        </v-card>
      </v-col>

      <v-col cols="8" v-if="selectedClient != null" class="panel">
        <v-btn color="success" class="mb-2" @click="save">Save</v-btn>
        <v-tabs v-model="tab" fixed-tabs background-color="primary" dark>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#general"> General </v-tab>
          <v-tab href="#modules"> Tiers & Addons </v-tab>
          <v-tab href="#users"> Users </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="tabs">
          <v-tab-item :key="1" value="general">
            <v-card flat>
              <v-card-text>
                <div class="mb-4">
                  <h4>District Name</h4>
                  <v-text-field
                    :value="clients[selectedClient].districtName"
                    v-model.trim="clients[selectedClient].districtName"
                  ></v-text-field>

                  <h4>Address</h4>
                  <v-row>
                    <v-col>
                      <v-text-field
                        :value="clients[selectedClient]?.streetAddress"
                        v-model="clients[selectedClient].streetAddress"
                        label="Street Address"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                      <v-text-field
                        :value="clients[selectedClient]?.apartmentUnitFloor"
                        v-model="clients[selectedClient].apartmentUnitFloor"
                        label="Apartment, Unit, Floor, etc."
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4" lg="4">
                      <v-text-field
                        :value="clients[selectedClient]?.city"
                        v-model="clients[selectedClient].city"
                        label="City"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" lg="4">
                      <v-text-field
                        :value="clients[selectedClient]?.state"
                        v-model="clients[selectedClient].state"
                        label="State"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" lg="4">
                      <v-text-field
                        :value="clients[selectedClient]?.zipCode"
                        v-model="clients[selectedClient].zipCode"
                        label="Zip Code"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <h4>Coordinates</h4>
                  <v-row>
                    <v-col>
                      <v-text-field
                        :value="clients[selectedClient]?.data.default.location[1]"
                        v-model="clients[selectedClient].data.default.location[1]"
                        label="Latitude (y)"
                      ></v-text-field>
                    </v-col>

                    <v-col>
                      <v-text-field
                        :value="clients[selectedClient]?.data.default.location[0]"
                        v-model="clients[selectedClient].data.default.location[0]"
                        label="Longitude (x)"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <h4>Boundaries</h4>
                  <v-row>
                    <v-col>
                      <v-text-field
                        :value="clients[selectedClient]?.data.default.bbox[1]"
                        v-model="clients[selectedClient].data.default.bbox[1]"
                        label="Latitude (y)"
                      ></v-text-field>
                    </v-col>

                    <v-col>
                      <v-text-field
                        :value="clients[selectedClient]?.data.default.bbox[0]"
                        v-model="clients[selectedClient].data.default.bbox[0]"
                        label="Longitude (x)"
                      ></v-text-field>
                    </v-col>

                    <v-col>
                      <v-text-field
                        :value="clients[selectedClient]?.data.default.bbox[3]"
                        v-model="clients[selectedClient].data.default.bbox[3]"
                        label="Latitude (y)"
                      ></v-text-field>
                    </v-col>

                    <v-col>
                      <v-text-field
                        :value="clients[selectedClient]?.data.default.bbox[2]"
                        v-model="clients[selectedClient].data.default.bbox[2]"
                        label="Longitude (x)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>

                <div class="mb-4">
                  <h4>Site</h4>
                  <a :href="`https://${host}/${clients[selectedClient].name}#/`" target="_blank">
                    https://{{ host }}/{{ clients[selectedClient].name }}
                  </a>
                </div>

                <div class="mb-4">
                  <h4>Authentication</h4>
                  <v-btn-toggle v-model="clients[selectedClient].data.authentication" row class="mt-2 mb-4">
                    <v-btn value="gdic">GDIC</v-btn>
                    <v-btn value="tam">TAM</v-btn>
                  </v-btn-toggle>
                </div>

                <div class="mb-4">
                  <h4>Legacy Customer Migration</h4>
                  <v-checkbox
                    v-model="clients[selectedClient].data.legacy.isLegacyCustomer"
                    label="Import customer legacy content"
                    :disabled="
                      clients[selectedClient].data.legacy.legacyCustomerMigrationStatus === 'complete' ||
                      clients[selectedClient].data.legacy.legacyCustomerMigrationStatus === 'migrating' ||
                      disableMigrationButton
                    "
                  ></v-checkbox>
                  <v-row>
                    <v-col cols="12" md="6" lg="3">
                      <v-btn
                        v-if="clients[selectedClient].data.legacy.isLegacyCustomer"
                        color="success"
                        :disabled="
                          clients[selectedClient].data.legacy.legacyCustomerMigrationStatus === 'complete' ||
                          clients[selectedClient].data.legacy.legacyCustomerMigrationStatus === 'migrating' ||
                          disableMigrationButton
                        "
                        @click="startMigration()"
                        >{{ migrationButtonStatus }}</v-btn
                      >
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      lg="4"
                      v-if="clients[selectedClient].data.legacy.isLegacyCustomer && showMigrationStatus"
                    >
                      <p class="mb-1">{{ migrationStatus }}</p>
                      <v-progress-linear
                        color="light-green darken-4"
                        height="10"
                        :value="migrationStatusValue"
                      ></v-progress-linear>
                    </v-col>
                  </v-row>
                </div>

                <div class="mb-4">
                  <h4>Email Server</h4>
                  <v-row dense class="mt-2">
                    <v-col cols="3">
                      <v-text-field
                        :value="clients[selectedClient].data.emailServer.server"
                        v-model.trim="clients[selectedClient].data.emailServer.server"
                        label="Server"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        :value="clients[selectedClient].data.emailServer.port"
                        v-model.trim="clients[selectedClient].data.emailServer.port"
                        label="Port"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-checkbox
                        :value="clients[selectedClient].data.emailServer.secure"
                        v-model="clients[selectedClient].data.emailServer.secure"
                        label="Secure"
                        dense
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="3">
                      <v-text-field
                        :value="clients[selectedClient].data.emailServer.username"
                        v-model.trim="clients[selectedClient].data.emailServer.username"
                        label="Username"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        :value="clients[selectedClient].data.emailServer.password"
                        v-model.trim="clients[selectedClient].data.emailServer.password"
                        label="Password"
                        type="password"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        :value="clients[selectedClient].data.emailServer.email"
                        v-model.trim="clients[selectedClient].data.emailServer.email"
                        label="From Email"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>

                <div class="mb-4" v-if="env !== 'production'">
                  <h4>Twilio</h4>

                  <v-row dense class="mt-2">
                    <v-col cols="12" md="3">
                      <v-text-field
                        :value="clients[selectedClient].data.twilioOverride"
                        label="SMS Phone Number Override"
                        v-model.trim="clients[selectedClient].data.twilioOverride"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-btn
                        v-if="clients[selectedClient].data.twilioOverride?.length"
                        color="primary"
                        :disabled="clients[selectedClient].data.twilioOverride?.length != 10"
                        @click="sendTestText"
                      >
                        Send Test Message
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>

                <h4>Yellowfin</h4>
                <v-row dense>
                  <v-col cols="12" md="3">
                    <v-text-field
                      :value="clients[selectedClient].data.yellowfin.clientOrgRef"
                      v-model.trim="clients[selectedClient].data.yellowfin.clientOrgRef"
                      label="Client Org Ref"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      :value="clients[selectedClient].data.yellowfin.organizationId"
                      v-model.trim="clients[selectedClient].data.yellowfin.organizationId"
                      label="Organization ID"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      :value="clients[selectedClient].data.yellowfin.userGroupId"
                      v-model.trim="clients[selectedClient].data.yellowfin.userGroupId"
                      label="User Group ID"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <h4>Support</h4>
                <v-checkbox
                  :value="clients[selectedClient].data.support.showZoho"
                  v-model="clients[selectedClient].data.support.showZoho"
                  label="Show Zendesk support"
                  dense
                ></v-checkbox>
                <v-row>
                  <v-col cols="12">
                    <v-btn color="error" class="mb-2 mt-12" @click="openDeleteConfirmation"> Delete Client </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="2" value="modules">
            <v-card flat>
              <v-card-text>
                <div class="mb-4">
                  <h4>Tier</h4>
                  <v-btn-toggle v-model="clients[selectedClient].data.modules.tier" row class="mt-2 mb-4">
                    <v-btn value="pro">PRO</v-btn>
                    <v-btn value="elite">ELITE</v-btn>
                  </v-btn-toggle>
                </div>
                <h4>Addons</h4>
                <v-checkbox
                  v-if="clients[selectedClient].data.modules.tier === 'elite'"
                  :value="clients[selectedClient].data.modules.roster.sync"
                  v-model="clients[selectedClient].data.modules.roster.sync"
                  label="Roster Sync"
                  dense
                ></v-checkbox>
                <v-btn-toggle
                  v-if="
                    clients[selectedClient].data.modules.roster.sync &&
                    clients[selectedClient].data.modules.tier === 'elite'
                  "
                  v-model="clients[selectedClient].data.modules.roster.syncType"
                  row
                  class="mt-2 mb-4"
                >
                  <v-btn value="oneroster">OneRoster</v-btn>
                  <v-btn value="ldap">LDAP</v-btn>
                </v-btn-toggle>
                <div
                  v-if="
                    clients[selectedClient].data.modules.roster.sync &&
                    clients[selectedClient].data.modules.tier === 'elite' &&
                    clients[selectedClient].data.modules.roster.syncType === 'ldap'
                  "
                >
                  <v-row dense>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :value="clients[selectedClient].data.modules.roster.server"
                        v-model.trim="clients[selectedClient].data.modules.roster.server"
                        label="Server"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :value="clients[selectedClient].data.modules.roster.port"
                        v-model="clients[selectedClient].data.modules.roster.port"
                        label="Port"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :value="clients[selectedClient].data.modules.roster.dn"
                        v-model="clients[selectedClient].data.modules.roster.dn"
                        label="Bind DN"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :value="clients[selectedClient].data.modules.roster.password"
                        v-model.trim="clients[selectedClient].data.modules.roster.password"
                        label="Password"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>

                <div
                  v-if="
                    clients[selectedClient].data.modules.roster.sync &&
                    clients[selectedClient].data.modules.tier === 'elite' &&
                    clients[selectedClient].data.modules.roster.syncType === 'oneroster'
                  "
                  class="mb-6"
                >
                  <v-row dense>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :value="clients[selectedClient].data.modules.roster.onerosterHost"
                        v-model.trim="clients[selectedClient].data.modules.roster.onerosterHost"
                        label="Host"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :value="clients[selectedClient].data.modules.roster.onerosterClient"
                        v-model="clients[selectedClient].data.modules.roster.onerosterClient"
                        type="password"
                        label="OneRoster Client"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :value="clients[selectedClient].data.modules.roster.onerosterSecret"
                        v-model.trim="clients[selectedClient].data.modules.roster.onerosterSecret"
                        type="password"
                        label="OneRoster Secret"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-checkbox
                      :value="clients[selectedClient].data.modules.roster.adultRoster"
                      v-model="clients[selectedClient].data.modules.roster.adultRoster"
                      label="Use Adult Roster"
                      dense
                    ></v-checkbox>
                    <div class="d-flex" v-if="clients[selectedClient].data.modules.roster.adultRoster">
                      <v-checkbox
                        :value="clients[selectedClient].data.modules.roster.teachers"
                        v-model="clients[selectedClient].data.modules.roster.teachers"
                        label="Teachers"
                        class="ml-3"
                        dense
                      ></v-checkbox>
                      <v-checkbox
                        :value="clients[selectedClient].data.modules.roster.aides"
                        v-model="clients[selectedClient].data.modules.roster.aides"
                        label="Aides"
                        class="ml-3"
                        dense
                      ></v-checkbox>
                    </div>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" md="2" lg="1">
                      <v-btn color="primary" @click="testOneRoster()">Test</v-btn>
                    </v-col>
                    <v-col cols="12" md="2" lg="1" class="d-flex align-center">
                      <span>{{ oneRosterTestMessage }}</span>
                    </v-col>
                  </v-row>
                </div>

                <v-checkbox
                  :value="clients[selectedClient].data.modules.routing"
                  v-model="clients[selectedClient].data.modules.routing"
                  label="Routing"
                  dense
                ></v-checkbox>
                <v-row dense v-if="clients[selectedClient].data.modules.routing">
                  <v-col cols="12" md="6">
                    <v-select
                      :items="ezrDomains"
                      v-model="clients[selectedClient].data.routing.host"
                      label="Routing Host"
                      clearable
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn
                      v-if="clients[selectedClient].data.modules.routing"
                      color="primary"
                      :loading="syncing"
                      :disabled="syncing"
                      @click="syncEzr"
                    >
                      Sync with Routing
                    </v-btn>
                  </v-col>
                </v-row>

                <v-checkbox
                  :value="clients[selectedClient].data.modules.yellowbus"
                  v-model="clients[selectedClient].data.modules.yellowbus"
                  label="Yellow Bus"
                  dense
                ></v-checkbox>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="3" value="users">
            <v-card flat class="relative">
              <setup-table
                :items="users"
                :headers="headers"
                noForm
                hideCreate
                showSearch
                hideRowSelect
                hideMenu
                class="adminUserTable"
              >
                <template #[`item.actions`]="{ item }">
                  <div class="d-flex">
                    <v-btn color="primary" class="mr-3" v-if="isATAMUser(item)" @click="impersonateUser(item)"
                      >impersonate</v-btn
                    >
                    <v-btn color="red" dark @click="removeUser(item)">remove</v-btn>
                  </div>
                </template>
              </setup-table>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <!-- Delete Confirmation Dialog -->
    <v-dialog class="warnbox" v-model="showDeleteConfirmation" max-width="490">
      <v-card>
        <v-card-title class="text-h5 red white--text">
          <v-icon class="white--text px-1">mdi-alert</v-icon>
          Delete Client
          {{ selectedClient > 0 ? clients[selectedClient].name : '' }}?
        </v-card-title>
        <v-card-text>
          <p class="pt-2">
            Are you sure you want to delete
            <strong>{{ selectedClient > 0 ? clients[selectedClient].name : '' }}</strong>
            ?
          </p>
          <p><strong>This action is irreversible!</strong></p>
          <p>
            <strong class="pr-1">Confirmation Code:</strong>
            <span class="font-weight-bold red--text text-h6">
              {{ randomCode }}
            </span>
          </p>
          <v-text-field
            v-model="confirmationCode"
            label="Enter Confirmation Code"
            outlined
            :rules="[
              (v) => !!v || 'Confirmation code is required',
              (v) => v === randomCode || 'Confirmation code doest not match',
            ]"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="deleteClient"
            :disabled="confirmationCode !== randomCode || saving"
            :loading="saving"
          >
            Delete
          </v-btn>
          <v-btn text @click="showDeleteConfirmation = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

import { handleErrors } from '@/util';
import adminApi from '@/apis/admin.js';
import SetupTable from '../Tables/SetupTable.vue';

const newClient = {
  id: 0,
  name: '',
  districtName: '',
  state: '',
  locationLng: 0,
  locationLat: 0,
  bbox: [],
  routingHost: '',
};

export default {
  inject: ['eventHub'],
  components: { SetupTable },
  data() {
    return {
      env: process.env.NODE_ENV,
      validationErrors: [],
      host: window.location.host,
      clients: new Array(),
      selectedClient: null,
      tab: 'general',
      showForm: false,
      handleErrors,
      id: null,
      form: newClient,
      saved: false,
      saving: false,
      showDeleteConfirmation: false,
      confirmationCode: '',
      randomCode: '',
      syncing: false,
      districts: [],
      users: [],
      headers: [
        { text: 'Name', value: 'displayName' },
        { text: 'Email', value: 'email' },
        { text: 'Roles', value: 'roleList' },
        { text: '', value: 'actions' },
      ],
      ezrDomains: [
        'https://ezrouting.com',
        'https://routing.app-garden.com',
        'http://localhost:3000',
        'https://beta.ezrouting.com',
      ],
      migrationButtonStatus: 'Start Migration',
      showMigrationStatus: false,
      disableMigrationButton: false,
      migrationStatus: '',
      migrationStatusValue: 0,
      oneRosterTestMessage: '',
    };
  },
  validations: {
    form: {
      name: { required },
      districtName: { required },
      state: { required },
      locationLat: {},
      locationLng: {},
      bbox: {},
      routingHost: {},
    },
  },
  computed: {
    getValidationErrors() {
      return this.validationErrors.join('. ');
    },
  },
  created() {
    this.fetchItems();
  },
  methods: {
    async deleteClient() {
      this.saving = true;
      try {
        if (this.confirmationCode === this.randomCode) {
          await adminApi.deleteClient(`${this.clients[this.selectedClient].name}`);
          this.$myalert.success('Client Deleted');
          this.fetchItems();
          this.selectedClient = null;
          this.showDeleteConfirmation = false;
        }
      } catch (e) {
        this.$myalert.error(`Failed to delete client ${this.clients[this.selectedClient].name}`);
      }
      this.saving = false;
    },
    async syncEzr() {
      const ok = await this.$myconfirm(
        `Are you sure you want to sync ${this.clients[this.selectedClient].name} with its routing database?`
      );
      if (ok) {
        this.syncing = true;
        try {
          this.$myalert.warning('Sync initiated - this may take a moment');
          const r = await adminApi.syncEzr(this.clients[this.selectedClient].name);
          if (r.done) this.$myalert.success('Sync completed!');
        } catch (e) {
          this.$myalert.error('Sync failed');
        }
        this.syncing = false;
      }
    },
    async sendTestText() {
      const client = this.clients[this.selectedClient];
      return await adminApi.sendTestText(client, client.data.twilioOverride);
    },
    validateName() {
      this.validationErrors = [];
      const regex = /^[a-z0-9]+$/;

      const clientNames = this.clients.map((client) => client.name);
      const reservedNames = [...clientNames, 'admin'];

      if (!this.form.name) {
        this.validationErrors.push('Name is required.');
      } else if (!regex.test(this.form.name)) {
        this.validationErrors.push('Name must contain only lowercase letters and numbers.');
      } else if (reservedNames.includes(this.form.name)) {
        this.validationErrors.push('This name is already in use.');
      }
    },
    async startMigration() {
      this.showMigrationStatus = true;
      this.disableMigrationButton = true;
      this.migrationButtonStatus = 'Starting Migration';
      this.migrationStatus = 'Fetching Migration Files...';
      this.migrationStatusValue = 10;

      const client = this.clients[this.selectedClient];
      const response = await adminApi.findMigrationFolder(client.name);
      if (!response || !response.status) {
        this.migrationStatusValue = 0;
        this.migrationStatus = response.message ? response.message : 'Failed. Please try again';
        this.disableMigrationButton = false;
        this.migrationButtonStatus = 'Start Migration';
        return;
      }
      this.migrationStatus = `Folder found. Migrating ${response.files} file(s)...`;
      this.migrationStatusValue = 90;
      this.migrationButtonStatus = 'Migrating...';

      client.data.legacy.legacyCustomerMigrationStatus = 'migrating';
      await this.save(false);
      try {
        const migrationResponse = await adminApi.runLegacyMigration(client.name, client.data.modules.routing);
        if (migrationResponse.done) {
          this.migrationStatusValue = 100;
          this.migrationStatus = response.message ? response.message : 'Success. Migration Completed';
          this.disableMigrationButton = true;
          this.migrationButtonStatus = 'Migration Complete';
        } else {
          this.disableMigrationButton = false;
          this.migrationButtonStatus = 'Start Migration';
          this.migrationStatusValue = 0;
          this.migrationStatus = 'Failed. Please try again';
          if (migrationResponse.message) console.log('Migration Error', migrationResponse.message);
        }
        await this.fetchItems();
      } catch (error) {
        client.data.legacy.legacyCustomerMigrationStatus = '';
        await this.save(false);
      }
    },
    async fetchItems() {
      this.clients = await adminApi.getClients();
      this.clients.forEach((e) => {
        e.data.emailServer = e.data.emailServer || {};
        e.data.modules = e.data.modules || {};
      });
    },
    createClient() {
      this.selectedClient = null;
      this.form = JSON.parse(JSON.stringify(newClient));
      this.validationErrors = [];
      this.showForm = true;
    },
    async save(showAlert = true) {
      this.saving = true;
      try {
        if (this.clients[this.selectedClient].data.modules.tier === 'pro') {
          this.clients[this.selectedClient].data.modules.roster = {
            sync: false,
            syncType: 'oneroster',
            server: '',
            port: '',
            dn: '',
            password: '',
            onerosterClient: '',
            onerosterHost: '',
            onerosterSecret: '',
          };
        }
        if (
          this.clients[this.selectedClient].data.modules.tier === 'elite' &&
          this.clients[this.selectedClient].data.modules.roster.sync
        ) {
          let rosterModded = this.clients[this.selectedClient].data.modules.roster;
          if (this.clients[this.selectedClient].data.modules.roster.syncType === 'oneroster') {
            rosterModded = {
              ...rosterModded,
              server: '',
              port: '',
              dn: '',
              password: '',
            };
          } else if (this.clients[this.selectedClient].data.modules.roster.syncType === 'ldap') {
            rosterModded = {
              ...rosterModded,
              onerosterClient: '',
              onerosterHost: '',
              onerosterSecret: '',
            };
          }
          this.clients[this.selectedClient].data.modules.roster = rosterModded;
        }
        const r = await adminApi.updateClient(this.clients[this.selectedClient]);
        if (r && r.id) {
          if (showAlert) this.$myalert.success('Client saved');
          await this.fetchItems();
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
      this.saving = false;
    },
    async create() {
      if (this.validationErrors.length) {
        this.$myalert.error('Invalid district name');
        return;
      }
      if (this.$v.form.$invalid) {
        this.$myalert.error('Please complete all required fields');
        return;
      }
      this.saving = true;
      try {
        const r = await adminApi.createClient({
          name: this.form.name,
          districtName: this.form.districtName.name,
          state: this.form.state,
          location: [this.form.locationLng, this.form.locationLat],
          bbox: this.form.bbox,
          routingHost: this.form.routingHost,
        });
        if (r && r.id) {
          this.$myalert.success('Client created');
          this.showForm = false;
          await this.fetchItems();
          this.selectedClient = this.clients.findIndex((client) => client.id === r.id) ?? null;
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
      this.saving = false;
      this.saved = true;
    },
    isATAMUser(user) {
      return (
        this.clients[this.selectedClient].data.authentication === 'gdic' ||
        user.email.includes('@transact.com') ||
        user.email.includes('@ezrouting.com')
      );
    },
    async impersonateUser(user) {
      try {
        const r = await adminApi.impersonateUser({ client: this.clients[this.selectedClient].name, user });
        window.open('https://' + r.url, '_blank').focus();
      } catch (error) {
        return this.$myalert.error('Could not impersonate User');
      }
    },
    async removeUser(user) {
      const ok = await this.$myconfirm('Are you sure you want to delete this User?');
      if (ok) {
        try {
          const r = await adminApi.deleteClientUser(this.clients[this.selectedClient].name, user.id);
          if (r.done) {
            this.$myalert.success('User removed');
            this.users = this.users.filter((u) => u.id !== user.id);
          }
        } catch (e) {
          this.$myalert.error(e.message);
        }
      }
    },
    openDeleteConfirmation() {
      this.randomCode = this.clients[this.selectedClient].name;
      this.confirmationCode = '';
      this.showDeleteConfirmation = true;
    },
    async searchDistricts(keyword) {
      if (keyword) {
        const list = await adminApi.searchDistricts({ name: keyword });
        list.forEach((e) => (e.label = `${e.name}, ${e.city}, ${e.state} ${e.zip}`));
        this.districts = list;
      }
    },
    async testOneRoster() {
      const clientRoster = this.clients[this.selectedClient].data.modules.roster;
      if (
        clientRoster.onerosterHost !== 'undefined' &&
        clientRoster.onerosterClient !== 'undefined' &&
        clientRoster.onerosterSecret !== 'undefined'
      ) {
        const response = await adminApi.testOneRosterCredentials(clientRoster);
        this.oneRosterTestMessage = response.done ? 'Success!' : 'Failed!';
      }
    },
    async handleDistrictChange(e) {
      this.$v.form.state.$model = e.state;
      this.$v.form.locationLng.$model = e.lng;
      this.$v.form.locationLat.$model = e.lat;
      const r = await adminApi.getDistrictBoundary({ id: e.id });
      this.$v.form.bbox.$model = r.bbox;
    },
    getUserRoleList(user) {
      let list = user.roles.map((e) => e.name).join(', ');
      if (user.approver.length) list += list.length ? ', Approver' : 'Approver';
      return list;
    },
  },
  watch: {
    async selectedClient(value) {
      if (value == null) return;

      this.users = await adminApi.getClientUsers(this.clients[value].name);
      for (let u of this.users) {
        u.name = u.firstName + ' ' + u.lastName + ' (' + u.displayName + ')';
        u.roleList = this.getUserRoleList(u);
      }

      const client = this.clients[this.selectedClient].data;
      if (typeof client.legacy === 'undefined') {
        this.clients[this.selectedClient].data.legacy = {
          legacyCustomerMigrationStatus: '',
          isLegacyCustomer: false,
        };
      }
      if (typeof client.modules.tier === 'undefined') {
        this.clients[this.selectedClient].data.modules.tier = 'pro';
      }

      if (typeof client.modules.roster === 'undefined') {
        this.clients[this.selectedClient].data.modules.roster = {
          sync: false,
          syncType: 'oneroster',
          server: '',
          port: '',
          dn: '',
          password: '',
          onerosterClient: '',
          onerosterHost: '',
          onerosterSecret: '',
        };
      }

      if (client.legacy.legacyCustomerMigrationStatus === 'migrating') {
        this.disableMigrationButton = true;
        this.migrationButtonStatus = 'Migrating...';
        this.migrationStatus = `Folder found. Migrating file(s)...`;
        this.migrationStatusValue = 90;
        this.showMigrationStatus = true;
      } else if (client.legacy.legacyCustomerMigrationStatus === 'complete') {
        this.disableMigrationButton = true;
        this.migrationButtonStatus = 'Migration Complete';
        this.showMigrationStatus = false;
      } else {
        this.disableMigrationButton = false;
        this.showMigrationStatus = false;
        this.migrationButtonStatus = 'Start Migration';
        this.migrationStatus = '';
        this.migrationStatusValue = 0;
      }
    },
  },
};
</script>

<style scoped>
.panel {
  overflow-y: auto;
  max-height: calc(100vh - 60px);
}
.transparentBorder .v-list-item {
  border-left: 4px solid transparent;
}
.colorBorder.v-list-item.v-list-item--active {
  border-left: 4px solid #009eec;
}
.adminUserTable {
  position: relative;
  top: -20px;
  padding: 0px;
}
.tabs .v-window-item {
  max-height: calc(100vh - 185px);
  overflow-y: scroll;
}
</style>
