export default {
  getVehicleTypes,
  saveVehicleType,
  deleteVehicleTypes,
};

/////////////

import api from '@/shared/api.service';

async function getVehicleTypes(options) {
  return await api.get('/api/vehicle-type' + (options && options.forTable ? '?table=1' : ''));
}

async function saveVehicleType(params) {
  return await api.post('/api/vehicle-type', params);
}

async function deleteVehicleTypes(ids) {
  return await api.delete('/api/vehicle-type', {
    params: { ids },
  });
}
