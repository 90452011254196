export default {
  getInvoices,
  getInvoiceDetails,
  saveInvoice,
  saveInvoiceStaffCost,
  getAttachmentsOfInvoice,
  saveInvoiceTravelCost,
  saveInvoiceAdditionalCharge,
  saveInvoicePayment,
  deleteInvoicePaymentById,
  deleteInvoiceCommentById,
  addInvoiceComment,
  deleteInvoiceStaffById,
  getSetupTableTravelCost,
  deleteInvoiceTravelById,
  batchUpdateInvoice,
  printInvoices,
};

import api from '@/shared/api.service';

const apiUrl = `/api/invoice`;

async function getInvoices(body) {
  return await api.post(apiUrl, body);
}

async function getInvoiceDetails({ invoiceId, tab }) {
  const url = `${apiUrl}/${invoiceId}?tab=${tab}`;
  return await api.get(url);
}

async function saveInvoice({ body, invoiceId }) {
  const url = `${apiUrl}/${invoiceId}`;
  return await api.post(url, body);
}

async function saveInvoiceStaffCost({ body, invoiceId }) {
  const url = `${apiUrl}/${invoiceId}/staff_cost`;
  return await api.post(url, body);
}

// record type for invoice in attachments is 359
async function getAttachmentsOfInvoice(invoiceId) {
  const url = `/api/attachment/invoice/${invoiceId}`;
  return await api.get(url);
}

async function saveInvoiceTravelCost({ body, invoiceId }) {
  const url = `${apiUrl}/${invoiceId}/travel_cost`;
  return await api.post(url, body);
}

async function saveInvoiceAdditionalCharge({ body, invoiceId }) {
  const url = `${apiUrl}/${invoiceId}/additional_charge`;
  return await api.post(url, body);
}

async function saveInvoicePayment({ body, invoiceId }) {
  const url = `${apiUrl}/${invoiceId}/payment`;
  return await api.post(url, body);
}

async function deleteInvoicePaymentById({ id, invoiceId }) {
  const url = `${apiUrl}/${invoiceId}/payment/${id}`;
  return await api.delete(url);
}

async function deleteInvoiceCommentById({ commentId, invoiceId }) {
  const url = `${apiUrl}/${invoiceId}/history/${commentId}`;
  return await api.delete(url);
}

async function addInvoiceComment({ invoiceId, body }) {
  const url = `${apiUrl}/${invoiceId}/history`;
  return await api.post(url, body);
}

async function deleteInvoiceStaffById({ staffCostId, invoiceId }) {
  const url = `${apiUrl}/${invoiceId}/staff-cost/${staffCostId}`;
  return await api.delete(url);
}

async function getSetupTableTravelCost(params) {
  const url = `${apiUrl}/travel-cost-setup/${params.invoiceId}?onlyPickUpMiles=${params.onlyPickUpMiles}`;
  return await api.get(url);
}

async function deleteInvoiceTravelById({ travelCostId, invoiceId }) {
  const url = `${apiUrl}/${invoiceId}/travel-cost/${travelCostId}`;
  return await api.delete(url);
}

async function batchUpdateInvoice(body) {
  const url = `${apiUrl}/batch-update`;
  return await api.post(url, body);
}

async function printInvoices(params) {
  const url = `${apiUrl}/print`;
  return await api.post(url, params);
}
