import fundingSourceApi from '@/apis/fundingSource';

export const GET_FUNDING_SOURCES = 'getFundingSources';
export const GET_FUNDING_SOURCE = 'getFundingSource';
export const SAVE_FUNDING_SOURCE = 'saveFundingSource';
export const DELETE_FUNDING_SOURCES = 'deleteFundingSources';

export const actions = {
  async [GET_FUNDING_SOURCES]({ commit }, options) {
    const result = await fundingSourceApi.getFundingSources(options);
    commit('setFundingSources', result);
  },
  async [GET_FUNDING_SOURCE](_, id) {
    const result = await fundingSourceApi.getFundingSource(id);
    return result;
  },
  async [SAVE_FUNDING_SOURCE](_, fundingSource) {
    return await fundingSourceApi.saveFundingSource(fundingSource);
  },
  async [DELETE_FUNDING_SOURCES](_, ids) {
    return await fundingSourceApi.deleteFundingSources(ids);
  },
};
