export default {
  geocode,
  getAddressFromSchoolDistrict,
  reverseGeocode,
  route,
  printDirections,
};

//////////////

import api from '@/shared/api.service';

async function geocode(obj) {
  return await api.post('/api/geo/geocode', obj);
}

async function reverseGeocode(obj) {
  return await api.post('/api/geo/reverse-geocode', obj);
}

async function getAddressFromSchoolDistrict(obj) {
  return await api.post('/api/geo/get-address-from-school-district', obj);
}

async function route(obj) {
  return await api.post('/api/geo/route', obj);
}

async function printDirections(obj) {
  return await api.post('/api/geo/print-directions', obj, { responseType: 'blob' });
}
