export default {
  getFundingSources,
  saveFundingSource,
  deleteFundingSources,
  getBudgetCodes,
  saveBudgetCode,
  deleteBudgetCodes,
};

/////////////

import api from '@/shared/api.service';

async function getFundingSources(options) {
  return await api.get('/api/funding-source' + (options && options.forTable ? '?table=1' : ''));
}

async function saveFundingSource(params) {
  return await api.post('/api/funding-source', params);
}

async function deleteFundingSources(ids) {
  return await api.delete('/api/funding-source', {
    params: { ids },
  });
}

async function getBudgetCodes(id) {
  return await api.get('/api/budget-code', {
    params: { requestId: id },
  });
}

async function saveBudgetCode(params) {
  return await api.post('/api/budget-code', params);
}

async function deleteBudgetCodes(ids) {
  return await api.delete('/api/budget-code', {
    params: { ids },
  });
}
