export const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setClientConfig(state, config) {
    state.clientConfig = config;
  },
  setIsMobile(state, config) {
    state.isMobile = config;
  },
  setClient(state, client) {
    state.client = client;
  },
  setCurrentSemester(state, currentSemester) {
    state.currentSemester = currentSemester;
  },
  setCurrentFiscalYear(state, currentFiscalYear) {
    state.currentFiscalYear = currentFiscalYear;
  },
  setHasTripTicketTemplate(state, hasTripTicketTemplate) {
    state.hasTripTicketTemplate = hasTripTicketTemplate;
  },
  setHasPermissionSlipEnglishTemplate(state, hasPermissionSlipEnglishTemplate) {
    state.hasPermissionSlipEnglishTemplate = hasPermissionSlipEnglishTemplate;
  },
  setHasPermissionSlipSpanishTemplate(state, hasPermissionSlipSpanishTemplate) {
    state.hasPermissionSlipSpanishTemplate = hasPermissionSlipSpanishTemplate;
  },
  setHasTripEstimateTemplate(state, hasTripEstimateTemplate) {
    state.hasTripEstimateTemplate = hasTripEstimateTemplate;
  },
  setTripRequestListFilters(state, filters) {
    state.tripRequestListFilters = filters;
  },
  setAssignmentListFilters(state, filters) {
    state.assignmentListFilters = filters;
  },
  setTripRequestListSort(state, sort) {
    state.tripRequestListSort = sort;
  },
  setAssignmentListSort(state, sort) {
    state.assignmentListSort = sort;
  },
  setDashboardPanel(state, panel) {
    state.dashboardPanel = panel;
  },
  setDashboardInvoicePanel(state, panel) {
    state.dashboardInvoicePanel = panel;
  },
  setTripCalendar(state, cal) {
    state.tripCalendar = cal;
  },
  setVehicleCalendar(state, cal) {
    state.vehicleCalendar = cal;
  },
  setDriverCalendar(state, cal) {
    state.driverCalendar = cal;
  },
  setNonce(state, nonce) {
    state.nonce = nonce;
  },
  setConfirmation(state, options) {
    state.confirmationOptions = options;
  },
  setSnackbar(state, options) {
    state.snackbarOptions = options;
  },
};
