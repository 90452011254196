export default {
  approve,
};

////////////

import api from '@/shared/api.service';

async function approve(params) {
  return await api.post('/api/trip-request/approve', params);
}
