export default {
  getThirdPartyPayments,
  saveThirdPartyPayment,
  deleteThirdPartyPayments,
};

import api from '@/shared/api.service';

const apiUrl = `/api/third-party-payment`;

async function getThirdPartyPayments(options) {
  return await api.get(`${apiUrl}` + (options && options.forTable ? '?table=1' : ''));
}

async function saveThirdPartyPayment(params) {
  return await api.post(`${apiUrl}`, params);
}

async function deleteThirdPartyPayments(thirdPartyPaymentIds) {
  return await api.delete(`${apiUrl}`, {
    params: { thirdPartyPaymentIds },
  });
}
