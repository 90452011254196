export const mutations = {
  setLocations(state, locations) {
    state.locations = locations;
  },
  setSchools(state, schools) {
    state.schools = schools;
  },
  setLocationsById(state, locationsById) {
    state.locationsById = locationsById;
  },
  setZones(state, zones) {
    if (!zones.length) state.zones = [{ text: 'No Zones yet', disabled: true }];
    else state.zones = zones;
  },
};
