import budgetCodeApi from '@/apis/fundingSource';

export const GET_BUDGET_CODES = 'getBudgetCodes';
export const GET_BUDGET_CODE = 'getBudgetCode';
export const SAVE_BUDGET_CODE = 'saveBudgetCode';
export const DELETE_BUDGET_CODES = 'deleteBudgetCodes';

export const actions = {
  async [GET_BUDGET_CODES]({ commit }) {
    const result = await budgetCodeApi.getBudgetCodes();
    for (const code of result) {
      const config = Object.values(code.code).join(' ');
      if (config?.length > 0) code.description = config.trim();
      else code.description = code.name.trim();
    }
    commit('setBudgetCodes', result);
    return result;
  },
  async [GET_BUDGET_CODE](_, id) {
    const result = await budgetCodeApi.getBudgetCode(id);
    return result;
  },
  async [SAVE_BUDGET_CODE](_, budgetCode) {
    return await budgetCodeApi.saveBudgetCode(budgetCode);
  },
  async [DELETE_BUDGET_CODES](_, ids) {
    return await budgetCodeApi.deleteBudgetCodes(ids);
  },
};
