import auditApi from '@/apis/audit';

export const GET_AUDIT_HISTORY = 'getAuditHistory';
export const GET_SYNC_AUDIT_HISTORY = 'getSyncAuditHistory';

export const actions = {
  async [GET_AUDIT_HISTORY](_, options) {
    const logs = await auditApi.getAuditHistory(options);
    return logs.filter((e) => e.recordType !== 400);
  },
  async [GET_SYNC_AUDIT_HISTORY](_, options) {
    const logs = await auditApi.getAuditHistory(options);
    return logs.filter((e) => e.recordType === 400);
  },
};
