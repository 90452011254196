<template>
  <v-app>
    <v-app-bar app clipped-left dark color="primary" height="60" elevate-on-scroll class="z-10">
      <!-- <img class="logo" :src="`${publicPath}logo.png`" /> -->
      <h3>Admin Dashboard</h3>
      <v-spacer></v-spacer>
      <v-menu offset-y :rounded="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon right v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title class="center-text">
              <strong>{{ me.firstName && me.lastName ? `${me.firstName} ${me.lastName}` : me.displayName }}</strong>
              <p>{{ me.email }}</p>
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="logoutUser()">
            <v-list-item-title>
              <v-icon left>mdi-exit-to-app</v-icon>
              Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer clipped permanent expand-on-hover absolute>
      <v-list dense nav class="mt-15">
        <v-list-item-group v-model="activePage" active-class="colorBorder" class="transparentBorder">
          <v-list-item v-for="item in items" :key="item.title">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main class="pl-15">
      <v-container fluid>
        <clients v-if="activePage == 0"></clients>
        <users v-if="activePage == 1"></users>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { LOGOUT } from '@/store/modules/User/actions';
import Clients from './Clients.vue';
import Users from './Users.vue';

export default Vue.extend({
  inject: ['eventHub'],
  components: { Clients, Users },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      activePage: 0,
      items: [
        { title: 'Clients', icon: 'mdi-home-city' },
        { title: 'Users', icon: 'mdi-account-group-outline' },
      ],
    };
  },
  computed: {
    ...mapGetters('user', ['me']),
  },
  methods: {
    ...mapActions('user', [LOGOUT]),
    async logoutUser() {
      const { done } = await this.logout();
      if (done) this.$router.go();
    },
  },
});
</script>

<style scoped>
.wrapper {
  margin-top: 84px;
}
.topbar-wrapper {
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 10;
}
.logo {
  height: 84px;
  max-width: 320px;
  background-color: white;
  z-index: 20;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%);
}
.toolbar {
  flex-grow: 1;
  z-index: 150 !important;
}
.nav-button {
  margin: 0;
}
.search-field {
  max-width: 267px;
}
.center-text {
  text-align: center;
}
.z-10 {
  z-index: 10;
}
.w-full {
  width: 100%;
}
.transparentBorder .v-list-item {
  border-left: 4px solid transparent;
}
.colorBorder.v-list-item.v-list-item--active {
  border-left: 4px solid #009eec;
}
</style>
