export const getters = {
  vehicleTypes(state) {
    return state.vehicleTypes;
  },
  vehicleTypesById(state) {
    const byId = {};
    state.vehicleTypes.forEach((e) => (byId[e.id] = e));
    return byId;
  },
  vehicleTypesByName(state) {
    const byName = {};
    state.vehicleTypes.forEach((e) => (byName[e.name.toLowerCase()] = e));
    return byName;
  },
};
