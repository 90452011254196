import semesterApi from '@/apis/semester';

export const GET_SEMESTERS = 'getSemesters';
export const SAVE_SEMESTER = 'saveSemester';
export const DELETE_SEMESTER = 'deleteSemester';

export const actions = {
  async [GET_SEMESTERS]({ commit }, options) {
    const result = await semesterApi.getSemesters(options);
    commit('setSemesters', result);
    return result;
  },
  async [SAVE_SEMESTER](_, semester) {
    if (semester.id) return await semesterApi.updateSemester(semester);
    else return await semesterApi.createSemester(semester);
  },
  async [DELETE_SEMESTER](_, id) {
    return await semesterApi.deleteSemester(id);
  },
};
