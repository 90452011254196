import additionalInvoiceCostApi from '@/apis/additionalInvoiceCost';

export const GET_ADDITIONAL_INVOICE_COSTS = 'getAdditionalInvoiceCosts';
export const GET_ADDITIONAL_INVOICE_COST = 'getAdditionalInvoiceCost';
export const SAVE_ADDITIONAL_INVOICE_COST = 'saveAdditionalInvoiceCost';
export const DELETE_ADDITIONAL_INVOICE_COSTS = 'deleteAdditionalInvoiceCosts';

export const actions = {
  async [GET_ADDITIONAL_INVOICE_COSTS]({ commit }, options) {
    const result = await additionalInvoiceCostApi.getAdditionalInvoiceCosts(options);
    commit('setAdditionalInvoiceCost', result);
  },
  async [GET_ADDITIONAL_INVOICE_COST](_, id) {
    const result = await additionalInvoiceCostApi.getAdditionalInvoiceCost(id);
    return result;
  },
  async [SAVE_ADDITIONAL_INVOICE_COST](_, additionalInvoiceCost) {
    return await additionalInvoiceCostApi.saveAdditionalInvoiceCost(additionalInvoiceCost);
  },
  async [DELETE_ADDITIONAL_INVOICE_COSTS](_, ids) {
    return await additionalInvoiceCostApi.deleteAdditionalInvoiceCosts(ids);
  },
};
