<template>
  <v-snackbar v-model="alert" :timeout="3500" :color="colors[type]" absolute top @input="handleInput">
    <v-icon color="#FFF">{{ icons[type] }}</v-icon>
    <div class="snackbar-message-wrapper">
      <div>{{ message }}</div>
      <div v-if="messageArray.length">
        <p v-for="message in messageArray" :key="message" class="mb-1">
          {{ message }}
        </p>
      </div>
      <div v-if="subMessage" class="snack-sub-message">{{ subMessage }}</div>
    </div>
    <template v-slot:action="{ attrs }">
      <v-btn v-if="buttonLabel" color="#fff" outlined text v-bind="attrs" @click="handleButton">
        {{ buttonLabel }}
      </v-btn>
      <v-btn color="#fff" small fab text @click="close"><v-icon>mdi-close</v-icon></v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Alert',
  data() {
    return {
      alert: false,
      type: 'info',
      message: '',
      messageArray: [],
      subMessage: '',
      buttonLabel: '',
      dismissible: true,

      resolvePromise: undefined,
      rejectPromise: undefined,

      colors: {
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
      icons: {
        error: 'mdi-alert-circle',
        info: 'mdi-information',
        success: 'mdi-check-circle',
        warning: 'mdi-alert',
      },
    };
  },
  methods: {
    show(options) {
      if (options.message && typeof options.message === 'string') this.message = options.message || '';
      if (options.message && Array.isArray(options.message)) this.messageArray = options.message;
      this.subMessage = options.subMessage || '';
      this.buttonLabel = options.buttonLabel || '';
      this.type = options.type || 'info';
      if (options.dismissible === false) this.dismissible = false;
      this.alert = true;

      return new Promise((res, rej) => {
        this.resolvePromise = res;
        this.rejectPromise = rej;
        if (!options.buttonLabel) res(true);
      });
    },
    destroyed() {
      this.resolvePromise(false);
      this.alert = false;
    },
    handleButton() {
      this.resolvePromise(true);
      this.alert = false;
    },
    handleInput() {
      this.resolvePromise(false);
    },
    close() {
      this.resolvePromise(false);
      this.alert = false;
    },
  },
};
</script>

<style>
.v-snack__wrapper {
  font-family: sans-serif;
  position: fixed;
}
.v-snack--absolute {
  margin-top: 84px;
  height: calc(100% - 84px) !important;
  z-index: 500 !important;
}
.v-snack__btn {
  margin-right: 8px;
}
.v-snack__content {
  display: flex;
  font-weight: 600;
}
.v-snack__content .snackbar-message-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
}
.v-snack__content .snack-sub-message {
  font-size: smaller;
}
</style>
