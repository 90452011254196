export default {
  getFiscalYears,
  createFiscalYear,
  updateFiscalYear,
  deleteFiscalYear,
};

////////////

import api from '@/shared/api.service';

async function getFiscalYears() {
  return await api.get('/api/fiscal-year');
}

async function createFiscalYear(fiscalYear) {
  return await api.post('/api/fiscal-year', fiscalYear);
}

async function updateFiscalYear(fiscalYear) {
  return await api.put(`/api/fiscal-year/${fiscalYear.id}`, fiscalYear);
}

async function deleteFiscalYear(id) {
  return await api.delete(`/api/fiscal-year/${id}`);
}
