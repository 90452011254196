export const getters = {
  stops(state) {
    return state.stops;
  },
  stopsById(state) {
    const byId = {};
    state.stops.forEach((e) => (byId[e.id] = e));
    return byId;
  },
};
