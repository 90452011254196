import { actions } from './actions';
import { mutations } from './mutations';
import { state } from './state';
import { getters } from './getters';
const ApproverModule = {
  actions,
  namespaced: true,
  mutations,
  getters,
  state,
};

export default ApproverModule;
