import mechanicApi from '@/apis/mechanic';

export const GET_MECHANICS = 'getMechanics';
export const GET_MECHANIC = 'getMechanic';
export const SAVE_MECHANIC = 'saveMechanic';
export const DELETE_MECHANICS = 'deleteMechanics';

export const actions = {
  async [GET_MECHANICS]({ commit }, options) {
    const result = await mechanicApi.getMechanics(options);
    commit('setMechanics', result);
  },
  async [GET_MECHANIC](_, id) {
    const result = await mechanicApi.getMechanic(id);
    return result;
  },
  async [SAVE_MECHANIC](_, mechanic) {
    return await mechanicApi.saveMechanic(mechanic);
  },
  async [DELETE_MECHANICS](_, ids) {
    return await mechanicApi.deleteMechanics(ids);
  },
};
