import Confirm from '@/components/ConfirmDialog.vue';

function install(Vue, options) {
  const vuetify = options.vuetify;
  Vue.prototype.$myconfirm = createConfirm;
  function createConfirm(options) {
    const container = document.body;
    return new Promise((resolve) => {
      var VueComponent = Vue.extend(Confirm);
      var vm = new VueComponent({
        vuetify,
        destroyed: () => {
          container.removeChild(vm.$el);
          resolve(vm.result);
        },
      }).$mount();
      container.appendChild(vm.$el);
      resolve(vm.show(options));
    });
  }
}

export default install;
