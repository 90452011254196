import additionalTransportationApi from '@/apis/additionalTransportation';

export const GET_ADDITIONAL_TRANSPORTATIONS = 'getAdditionalTransportations';
export const GET_ADDITIONAL_TRANSPORTATION = 'getAdditionalTransportation';
export const SAVE_ADDITIONAL_TRANSPORTATION = 'saveAdditionalTransportation';
export const DELETE_ADDITIONAL_TRANSPORTATIONS = 'deleteAdditionalTransportations';

export const actions = {
  async [GET_ADDITIONAL_TRANSPORTATIONS]({ commit }) {
    const result = await additionalTransportationApi.getAdditionalTransportations();
    commit('setAdditionalTransportations', result);
  },
  async [GET_ADDITIONAL_TRANSPORTATION](_, id) {
    const result = await additionalTransportationApi.getAdditionalTransportation(id);
    return result;
  },
  async [SAVE_ADDITIONAL_TRANSPORTATION](_, additionalTransportation) {
    return await additionalTransportationApi.saveAdditionalTransportation(additionalTransportation);
  },
  async [DELETE_ADDITIONAL_TRANSPORTATIONS](_, ids) {
    return await additionalTransportationApi.deleteAdditionalTransportations(ids);
  },
};
