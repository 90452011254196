export const getters = {
  tripRequests(state) {
    return state.tripRequests;
  },
  tripRequestsById(state) {
    const byId = {};
    state.tripRequests.forEach((e) => (byId[e.id] = e));
    return byId;
  },
  currentTripRequestApprovalStatus(state) {
    if (!state.currentTripRequest) return null;

    const { approval, assignments } = state.currentTripRequest;

    const historyApproved = approval?.history.filter((h) => h.approved === 1);
    const isFullyApproved = historyApproved?.length === approval?.requiredApprovalLevels?.length;
    const isPartiallyApproved = !!historyApproved?.length;
    const isAutoApproved = approval.approved && approval.requiredApprovalLevels.length === 0;
    const isAssigned = assignments?.length > 0;

    let status = 'pending';

    if (isAutoApproved) status = 'auto';
    else if (isFullyApproved) status = 'full';
    else if (isPartiallyApproved) status = 'partial';
    else if (isAssigned) status = 'assigned';

    return status;
  },
  currentTripRequestPermissions(state) {
    if (!state.currentTripRequest) return null;

    return Object.keys(state.currentTripRequest.permissions);
  },
  isCurrentTripRequestApproved(state, getters) {
    const status = getters.currentTripRequestApprovalStatus;
    return ['auto', 'full', 'partial', 'assigned'].includes(status);
  },
  isFieldReadonlyAfterApproval(state, getters) {
    if (!state.currentTripRequest) return false;

    const editPermissions = [
      'canEdit',
      'canEditMidApproval',
      'canEditPreApproval',
      'canEditApproved',
      'canEditSiteAdmin',
    ];

    const isApproved = getters.isCurrentTripRequestApproved;

    if (!isApproved) return false;

    const permissions = getters.currentTripRequestPermissions;

    const hasEditPermission = editPermissions.some((permission) => permissions.includes(permission));

    if (isApproved && hasEditPermission) return false;

    return true;
  },
  isTripApprovedAndSubmittedByRequester(state, getters, rootState) {
    if (!state.currentTripRequest) return false;
    const isApproved = getters.isCurrentTripRequestApproved;
    if (!isApproved) return false;

    const me = rootState.user.me;
    if (isApproved && me.is.requester) return true;

    return false;
  },
  currentTripRequest(state) {
    return state.currentTripRequest;
  },
  currentTripLocation(state) {
    return state.currentTripLocation;
  },
  isCurrentTripFundingNoBudgetCode(state, getters, rootState, rootGetters) {
    return ({ fundingSourceIndex }) => {
      const currentTripRequest = state.currentTripRequest;

      if (!currentTripRequest) return false;

      const tripFundingSource = currentTripRequest.fundingSources[fundingSourceIndex];
      const fundingSourcesById = rootGetters['fundingSource/fundingSourcesById'];
      const fundingSource = fundingSourcesById[tripFundingSource.fundingSourceId];

      if (fundingSource.type !== 1) return false;

      if (!currentTripRequest) return false;

      const isTripNoBudgetCode = !tripFundingSource.budgetCodeId && !tripFundingSource.budgetCode;
      const isFundingSourceNoBudgetCode = !fundingSource.budgetCodeId;

      const hasNoBudgetCode = isTripNoBudgetCode || isFundingSourceNoBudgetCode;
      const isFundingSourceEditable = fundingSource.type === 4;

      if (hasNoBudgetCode && !isFundingSourceEditable) return true;

      return false;
    };
  },
  canApproveCurrentTrip(state, getters, rootState, rootGetters) {
    const me = rootGetters['user/me'];
    const tripApproval = state.currentTripRequest?.approval || null;

    if (!tripApproval) return false;

    const hasApprovers = tripApproval.requiredApprovalLevels?.length > 0;

    if (!hasApprovers && me.is.approver) return true;

    const tripApprovers = tripApproval.requiredApprovalLevels?.flatMap((level) =>
      level.primaryApprovers?.map((approver) => approver.userId)
    );

    return tripApprovers?.includes(me.id) || false;
  },
  isLoading(state) {
    return state.isLoading;
  },
};
