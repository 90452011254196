<template>
  <v-toolbar-items>
    <sub-menu name="Setup Tables Menu" :menu-items="pages" @sub-menu-click="onMenuItemClick" showDropDown />

    <v-btn
      @click="manageLocationSLA"
      class="nav-button text-capitalize white--text"
      color="primary"
      elevation="0"
      v-if="showSLAManager"
    >
      Manage Site Level Authority
    </v-btn>

    <v-btn
      @click="showProspectiveDestinations"
      class="nav-button text-capitalize white--text"
      color="primary"
      elevation="0"
      v-if="currentPage == 'destinations'"
    >
      Show {{ prospective ? '' : 'Prospective' }} Destinations
    </v-btn>
  </v-toolbar-items>
</template>

<script>
import { mapGetters } from 'vuex';
import SubMenu from '@/components/SubMenu.vue';

export default {
  name: 'TableMenu',
  inject: ['eventHub'],
  components: { SubMenu },
  data() {
    return {
      currentPage: '',
      prospective: false,
    };
  },
  computed: {
    ...mapGetters('user', ['me']),
    pages() {
      const items = [
        { name: 'Locations', page: 'locations', show: !this.onlyDriverTable },
        { name: 'Destinations', page: 'destinations', show: !this.onlyDriverTable },
        { name: 'Trip Types', page: 'trip-types', show: !this.onlyDriverTable },
        { name: 'Trip Events', page: 'trip-events', show: !this.onlyDriverTable },
        { name: 'Special Indicators / Travel Needs', page: 'special-indicators-needs', show: !this.onlyDriverTable },
        {
          name: 'Finance',
          show: !this.onlyDriverTable,
          menu: [
            {
              name: 'Funding Sources',
              page: 'funding-sources',
            },
            {
              name: 'Budget Codes',
              page: 'budget-codes',
            },
          ],
        },
        { name: 'Vehicles', page: 'vehicles', show: !this.onlyDriverTable },
        { name: 'Vehicle Types', page: 'vehicle-types', show: !this.onlyDriverTable },
        {
          name: 'External Agency Vehicles',
          show: !this.onlyDriverTable,
          menu: [
            {
              name: 'Approved Charters',
              page: 'approved-charters',
            },
            {
              name: 'Contractors',
              page: 'contractors',
            },
            {
              name: 'Rental / Dealership Agencies',
              page: 'rental-dealership-agencies',
            },
          ],
        },
        {
          name: 'Staff',
          show: this.showDriversTable,
          menu: [
            { name: 'Drivers', page: 'drivers' },
            { name: 'Mechanics', page: 'mechanics' },
          ],
        },
        { name: 'Additional Invoice Costs', page: 'additional-invoice-costs', show: !this.onlyDriverTable },
        { name: 'Third Party Payment Options', page: 'third-party-payment-options', show: !this.onlyDriverTable },
      ];
      return items.filter((e) => e.show);
    },
    menuLabel() {
      const curr = this.pages
        .concat(this.pages.flatMap((page) => (page.menu ? page.menu : [])))
        .find((e) => e.page === this.currentPage);
      return curr ? curr.name : 'Settings';
    },
    onUsersPage() {
      return this.currentPage == 'users';
    },
    showDriversTable() {
      return (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        (this.me.is.limitedAdmin && this.me.roles.find((e) => e.roleId == 3).setupTables) ||
        this.me.is.driverTableOnly
      );
    },
    onlyDriverTable() {
      return (
        this.me.is.driverTableOnly &&
        !this.me.is.superAdmin &&
        !this.me.is.transportationAdmin &&
        !this.me.is.limitedAdmin
      );
    },
    showSLAManager() {
      return (
        !this.onlyDriverTable &&
        (this.currentPage == 'locations' ||
          (this.currentPage == 'users' && (this.me.is.superAdmin || this.me.is.transportationAdmin)))
      );
    },
  },
  created() {
    this.currentPage = this.$route.name;
  },
  methods: {
    onMenuItemClick(item) {
      this.$router.push(`/tables/${item.page}`);
    },
    manageLocationSLA() {
      this.eventHub.$emit('manageLocationSLARequested');
    },
    showProspectiveDestinations() {
      this.prospective = !this.prospective;
      this.eventHub.$emit('toggleProspectiveDestinations');
    },
  },
  watch: {
    $route(to, from) {
      this.currentPage = to.name;
    },
  },
};
</script>

<style scoped>
.nav-button {
  margin: 0;
}

.search-field {
  max-width: 267px;
}
</style>
