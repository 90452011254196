export default {
  logout,
  getMyself,
  checkUserExist,
  saveUser,
  removeUser,
  updateMyself,
  savePreference,
  getUsers,
  getUserEmails,
  assignRoles,
  assignLocationRoles,
  removeRole,

  getRoles,
  getRoleAssignments,
  saveRole,
  deleteRole,
  getAllRoles,

  updatePassword,
  resetPassword,
  checkPassword,
  findRegisteredUser,

  getVehicleOwners,
};

////////////

import api from '@/shared/api.service';

async function logout() {
  return await api.post('/logout');
}

async function getMyself() {
  return await api.get('/api/me');
}

async function checkUserExist(params) {
  return await api.post('/api/user/find', params);
}

async function saveUser(params) {
  if (params.id > 0) return await api.put('/api/user/' + params.id, params);
  else return await api.post('/api/user', params);
}

async function removeUser(id) {
  return await api.delete('/api/user/' + id);
}

async function updateMyself(params) {
  return await api.put('/api/user', params);
}

async function savePreference(params) {
  return await api.post('/api/user/preference', params);
}

async function getUsers() {
  return await api.get('api/user');
}

async function getUserEmails() {
  return await api.get('api/user/email');
}

async function assignRoles(params) {
  return await api.post('api/user/assign', params);
}

async function assignLocationRoles(params) {
  return await api.post('api/user/assign/location', params);
}

async function removeRole(params) {
  return await api.post('api/user/remove', params);
}

async function getRoles() {
  return await api.get('api/role');
}

async function getRoleAssignments() {
  const r = await api.get('api/role-assignment');
  return r;
}

async function saveRole(params) {
  if (params.id) return await api.put(`/api/role/${params.id}`, params);
  else return await api.post('/api/role', params);
}

async function deleteRole(id) {
  return await api.delete(`api/role/${id}`);
}

async function getAllRoles() {
  return await api.get(`api/role/getAllRoles`);
}

async function updatePassword(params) {
  return await api.post(`/api/password/update`, params);
}

async function resetPassword(params) {
  return await api.post(`/api/password/reset`, params);
}

async function checkPassword(params) {
  return await api.post(`/api/password/check`, params);
}

async function findRegisteredUser(params) {
  return await api.post(`/api/user/find`, params);
}

async function getVehicleOwners() {
  return await api.get('/api/role/vehicle-owner');
}
