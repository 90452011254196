export const getters = {
  app(state) {
    return state;
  },
  client(state) {
    return state.client;
  },
  clientLatLng(state) {
    return state.clientConfig.data.default.location;
  },
  clientBounds(state) {
    return state.clientConfig.data.default.bbox;
  },
  clientAddress(state) {
    const { streetAddress, apartmentUnitFloor, city, state: addressState, zipCode } = state.clientConfig;
    return [streetAddress, apartmentUnitFloor, city, addressState, zipCode].filter((v) => !!v).join(' ');
  },
  clientConfig(state) {
    return state.clientConfig;
  },
  isMobile(state) {
    return state.isMobile;
  },
  currentSemester(state) {
    return state.currentSemester;
  },
  currentFiscalYear(state) {
    return state.currentFiscalYear;
  },
  hasTripTicketTemplate(state) {
    return state.hasTripTicketTemplate;
  },
  hasPermissionSlipEnglishTemplate(state) {
    return state.hasPermissionSlipEnglishTemplate;
  },
  hasPermissionSlipSpanishTemplate(state) {
    return state.hasPermissionSlipSpanishTemplate;
  },
  hasTripEstimateTemplate(state) {
    return state.hasTripEstimateTemplate;
  },
  confirmation(state) {
    return state.confirmationOptions.choice;
  },
  tripRequestListFilters(state) {
    return state.tripRequestListFilters;
  },
  assignmentListFilters(state) {
    return state.assignmentListFilters;
  },
  tripRequestListSort(state) {
    return state.tripRequestListSort;
  },
  assignmentListSort(state) {
    return state.assignmentListSort;
  },
  dashboardPanel(state) {
    return state.dashboardPanel;
  },
  dashboardInvoicePanel(state) {
    return state.dashboardInvoicePanel;
  },
  tripCalendar(state) {
    return state.tripCalendar;
  },
  vehicleCalendar(state) {
    return state.vehicleCalendar;
  },
  driverCalendar(state) {
    return state.driverCalendar;
  },
  nonce(state) {
    return state.nonce;
  },
  isRoutingClient(state) {
    return state.clientConfig.data.modules.routing;
  },
};
