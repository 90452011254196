import userApi from '@/apis/user';
import yellowfinApi from '@/apis/yellowfin';

export const GET_USERS = 'getUsers';
export const GET_MYSELF = 'getMyself';
export const GET_USER_EMAILS = 'getUserEmails';
export const CHECK_USER_EXIST = 'checkUserExist';
export const SAVE_USER = 'saveUser';
export const REMOVE_USER = 'removeUser';
export const UPDATE_MYSELF = 'updateMyself';
export const SAVE_PREFERENCE = 'savePreference';
export const GET_YELLOWFIN_TOKEN = 'getYellowfinToken';
export const LOGOUT = 'logout';
export const ASSIGN_ROLES = 'assignRoles';
export const ASSIGN_LOCATION_ROLES = 'assignLocationRoles';
export const REMOVE_ROLE = 'removeRole';
export const GET_ROLES = 'getRoles';
export const GET_ROLE_ASSIGNMENTS = 'getRoleAssignments';
export const SAVE_ROLE = 'saveRole';
export const DELETE_ROLE = 'deleteRole';
export const GET_ALL_ROLES = 'getAllRoles';

export const UPDATE_PASSWORD = 'updatePassword';
export const RESET_PASSWORD = 'resetPassword';
export const CHECK_PASSWORD = 'checkPassword';
export const FIND_REGISTERED_USER = 'findRegisteredUser';

export const GET_VEHICLE_OWNERS = 'getVehicleOwners';

export const actions = {
  async [GET_USERS]({ commit }) {
    const result = await userApi.getUsers();
    commit('setUsers', result);
  },
  async [GET_MYSELF]({ commit }) {
    const result = await userApi.getMyself();
    commit('setMe', result);
  },
  async [GET_USER_EMAILS]() {
    const result = await userApi.getUserEmails();
    return result;
  },
  async [CHECK_USER_EXIST](_, params) {
    const result = await userApi.checkUserExist(params);
    return result;
  },
  async [SAVE_USER]({ commit }, params) {
    const result = await userApi.saveUser(params);
    // commit('setMe', result);
    return result;
  },
  async [REMOVE_USER]({ commit }, params) {
    const result = await userApi.removeUser(params);
    return result;
  },
  async [UPDATE_MYSELF]({ dispatch }, params) {
    const result = await userApi.updateMyself(params);
    dispatch(GET_MYSELF);
    return result;
  },
  async [SAVE_PREFERENCE]({ commit }, params) {
    const result = await userApi.savePreference(params);
    // commit('setMe', result);
    return result;
  },
  async [GET_YELLOWFIN_TOKEN]({ commit }) {
    const r = await yellowfinApi.getRedirectURL();
    commit('setYellowfinToken', r.token);
    commit('setYellowfinRedirectUrl', r.url);
    return r;
  },
  async [LOGOUT]({ commit }) {
    const result = await userApi.logout();
    commit('setMe', {});
    return result;
  },
  async [ASSIGN_ROLES](_, params) {
    const result = await userApi.assignRoles(params);
    return result;
  },
  async [ASSIGN_LOCATION_ROLES](_, params) {
    const result = await userApi.assignLocationRoles(params);
    return result;
  },
  async [REMOVE_ROLE](_, params) {
    const result = await userApi.removeRole(params);
    return result;
  },
  async [GET_ROLES]({ commit }) {
    const result = await userApi.getRoles();
    commit('setRoles', result);
    return result;
  },
  async [GET_ROLE_ASSIGNMENTS]({ commit }) {
    const result = await userApi.getRoleAssignments();
    commit('setRoleAssignments', result);
    return result;
  },
  async [SAVE_ROLE](_, role) {
    return await userApi.saveRole(role);
  },
  async [DELETE_ROLE](_, id) {
    return await userApi.deleteRole(id);
  },
  async [GET_ALL_ROLES]() {
    return await userApi.getAllRoles();
  },

  async [UPDATE_PASSWORD](_, params) {
    return await userApi.updatePassword(params);
  },
  async [RESET_PASSWORD](_, params) {
    return await userApi.resetPassword(params);
  },
  async [CHECK_PASSWORD](_, params) {
    return await userApi.checkPassword(params);
  },
  async [FIND_REGISTERED_USER](_, params) {
    return await userApi.findRegisteredUser(params);
  },

  async [GET_VEHICLE_OWNERS]({ commit }) {
    const result = await userApi.getVehicleOwners();
    commit('setVehicleOwners', result);
    return result;
  },
};
