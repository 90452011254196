export default {
  getDriverLogs,
  getDriverLogByDriverId,
  saveDriverLog,
};

////////////

import api from '@/shared/api.service';

async function getDriverLogs() {
  return await api.get('/api/driver-log');
}

async function getDriverLogByDriverId(id) {
  return await api.get(`/api/driver-log/${id}`);
}

async function saveDriverLog(obj) {
  return await api.post('/api/driver-log', obj);
}
