<template>
  <v-app>
    <template v-if="loading">
      <div class="loading-screen">
        <TravelTrackerLogo short />
        <v-progress-linear rounded color="primary" height="10" indeterminate class="progress"></v-progress-linear>
      </div>
    </template>

    <admin v-else-if="showAdminPortal"></admin>

    <main-layout v-else class="blue-grey lighten-5"></main-layout>

    <v-snackbar top v-model="notify" :timeout="snackbarOptions.timeout">
      {{ snackbarOptions.message ? snackbarOptions.message : '' }}

      <template v-slot:action="{ attrs }">
        <v-btn :color="snackbarOptions.color" @click="notify = false" text v-bind="attrs"> Close </v-btn>
      </template>
    </v-snackbar>

    <component
      :is="'script'"
      :src="`${yellowfinURL}/JsAPI/v3?token=${yfToken}&clientOrg=${yfClientOrg}`"
      v-if="yellowfinURL && yfToken && yfToken.length"
    ></component>

    <component
      :is="'script'"
      id="ze-snippet"
      :nonce="nonce"
      defer
      src="https://static.zdassets.com/ekr/snippet.js?key=c29fe58e-94ed-4971-be34-bb3499bd1d0c"
      v-if="clientConfig.data && clientConfig.data.support.showZoho && !me.isTTAdmin"
    ></component>
  </v-app>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue';
import Admin from '@/views/Admin';
import TravelTrackerLogo from '@/components/shared/Logo.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import store from '@/store';
import { GET_CLIENT_CONFIG, CHECK_TEMPLATES, CLEAR_SNACKBAR } from '@/store/modules/App/actions';
import { GET_ADDITIONAL_TRANSPORTATIONS } from '@/store/modules/AdditionalTransportation/actions';
import { GET_APPROVAL_LEVELS } from '@/store/modules/ApprovalLevel/actions';
import { GET_ASSIGNMENTS } from '@/store/modules/Assignment/actions';
import { GET_DESTINATIONS, GET_PROSPECTIVE_DESTINATIONS } from '@/store/modules/Destination/actions';
import { GET_DRIVERS } from '@/store/modules/Driver/actions';
import { GET_DRIVER_LOGS } from '@/store/modules/DriverLog/actions';
import { GET_FISCAL_YEARS } from '@/store/modules/FiscalYear/actions';
import { GET_FUNDING_SOURCES } from '@/store/modules/FundingSource/actions';
import { GET_LOCATIONS, GET_ZONES } from '@/store/modules/Location/actions';
import { GET_SEMESTERS } from '@/store/modules/Semester/actions';
import { GET_STOPS } from '@/store/modules/Stop/actions';
import { GET_TRIP_REQUESTS } from '@/store/modules/TripRequest/actions';
import { GET_TRIP_EVENTS } from '@/store/modules/TripEvent/actions';
import { GET_TRIP_TYPES } from '@/store/modules/TripType/actions';
import { GET_MYSELF, GET_ROLES, GET_ROLE_ASSIGNMENTS, GET_YELLOWFIN_TOKEN } from '@/store/modules/User/actions';
import { GET_VEHICLES } from '@/store/modules/Vehicle/actions';
import { GET_VEHICLE_TYPES } from '@/store/modules/VehicleType/actions';
import { GET_CONFIGS } from '@/store/modules/Config/actions';
import yellowfinApi from '@/apis/yellowfin';
import cryptoJS from 'crypto-js';

export default {
  name: 'App',
  inject: ['eventHub'],
  components: { MainLayout, Admin, TravelTrackerLogo },
  computed: {
    ...mapGetters('app', ['client', 'clientConfig', 'isMobile', 'nonce']),
    ...mapGetters('user', ['me', 'yellowfinToken']),
    snackbarOptions() {
      return this.$store.state.app.snackbarOptions;
    },
    showAdminDashboard() {
      return (
        this.client == 'admin' &&
        (this.$route.fullPath == '/' || this.$route.fullPath === '/dashboard') &&
        this.me.isTTAdmin
      );
    },
    yellowfinURL() {
      return process.env.VUE_APP_YELLOWFIN_SERVER;
    },
  },
  data: () => ({
    confirm: false,
    notify: false,
    loading: true,
    showAdminPortal: false,
    yfToken: undefined,
    yfClientOrg: undefined,
  }),
  methods: {
    ...mapActions('app', [GET_CLIENT_CONFIG, CHECK_TEMPLATES, CLEAR_SNACKBAR]),
    ...mapActions('additionalTransportation', [GET_ADDITIONAL_TRANSPORTATIONS]),
    ...mapActions('approvalLevel', [GET_APPROVAL_LEVELS]),
    ...mapActions('assignment', [GET_ASSIGNMENTS]),
    ...mapActions('destination', [GET_DESTINATIONS, GET_PROSPECTIVE_DESTINATIONS]),
    ...mapActions('driver', [GET_DRIVERS]),
    ...mapActions('driverLog', [GET_DRIVER_LOGS]),
    ...mapActions('fiscalYear', [GET_FISCAL_YEARS]),
    ...mapActions('fundingSource', [GET_FUNDING_SOURCES]),
    ...mapActions('location', [GET_LOCATIONS, GET_ZONES]),
    ...mapActions('semester', [GET_SEMESTERS]),
    ...mapActions('stop', [GET_STOPS]),
    ...mapActions('tripRequest', [GET_TRIP_REQUESTS]),
    ...mapActions('tripEvent', [GET_TRIP_EVENTS]),
    ...mapActions('tripType', [GET_TRIP_TYPES]),
    ...mapActions('user', [GET_MYSELF, GET_ROLES, GET_ROLE_ASSIGNMENTS, GET_YELLOWFIN_TOKEN]),
    ...mapActions('vehicle', [GET_VEHICLES]),
    ...mapActions('vehicleType', [GET_VEHICLE_TYPES]),
    ...mapActions('config', [GET_CONFIGS]),
    ...mapActions('invoice', ['setInvoiceReadOnlyState']),
    ...mapMutations('app', ['setIsMobile', 'setCurrentSemester', 'setClient']),
    ...mapMutations('invoice', ['setInvoiceReadOnly']),

    async setStore() {
      try {
        await this.getConfigs();

        const semesters = await this.getSemesters();
        this.setSemester(semesters);

        const promises = [
          this.getClientConfig(this.client),
          this.getAdditionalTransportations(),
          this.getApprovalLevels(),
          // this.getAssignments({ includeStops: true }),
          // this.getUsers(),
          this.getDestinations(),
          this.getProspectiveDestinations(),
          this.getDrivers(),
          this.getDriverLogs(),
          this.getFundingSources(),
          this.getLocations(),
          this.getZones(),
          this.getFiscalYears(),
          this.getStops(),
          // this.getTripRequests(),
          this.getTripEvents(),
          this.getTripTypes(),
          this.getRoles(),
          this.getRoleAssignments(),
          this.getVehicles(),
          this.getVehicleTypes(),
          this.checkTemplates(),
        ];

        await Promise.all(promises);
      } catch (error) {
        console.log('Failed Store Fetch');
      }

      this.loading = false;
    },
    setSemester(semesters) {
      const selectedSemester = semesters.findIndex((e) => e.id == this.me.semesterId);
      const currentSemester = semesters[selectedSemester];
      this.setCurrentSemester(currentSemester);
    },
    setUserInAppcues() {
      const rolesMap = new Map();
      this.me.roles.forEach((role) => rolesMap.set(role.roleId, role));

      const obj = {
        id: this.me.id,
        firstName: this.me.firstName,
        lastName: this.me.lastName,
        displayName: this.me.displayName,
        email: this.me.email,
        isSuperAdmin: rolesMap.has(1),
        isTransportationAmin: rolesMap.has(2),
        isLimitedAdmin: rolesMap.has(3),
        isFinance: rolesMap.has(4),
        isDriver: rolesMap.has(5),
        isDriverTableOnly: rolesMap.has(6),
        isReadOnly: rolesMap.has(7),
        isOvernightOOSOnly: rolesMap.has(8),
        isRequester: rolesMap.has(9),
        isApprover: rolesMap.has(10),
        isSiteAdmin: !!this.me.is.siteAdmin,
        isSchoolFinance: !!this.me.is.schoolFinance,
        isVehicleOwner: !!this.me.is.vehicleOwner,
        isSpecialNeedsVehicleOwner: !!this.me.is.specialNeedsVehicleOwner,
        isNurse: !!this.me.is.nurse,
        isNutritionist: !!this.me.is.nutritionist,
      };
      window.Appcues.identify(`user${this.me.id}`, obj);
    },
    setDefaultPermissions() {
      this.setInvoiceReadOnlyState();
    },
    async initYellowfin() {
      await yellowfinApi.login();
      const { token, clientOrg } = await this.getYellowfinToken();
      this.yfToken = token;
      this.yfClientOrg = clientOrg;
    },
    async initZoho() {
      const nonce = cryptoJS.lib.WordArray.random(16).toString(cryptoJS.enc.Base64);
      await store.commit('app/setNonce', nonce);
    },
    downloadFile(endpoint, uuid, filename) {
      const link = document.createElement('a');

      link.href = filename ? `${endpoint}/${filename}?useUuid=false` : `${endpoint}/${uuid ?? ''}`;
      link.target = '_blank';

      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    initAdmin() {
      this.showAdminPortal = true;
      this.loading = false;
    },
  },
  created() {
    const path = window.location.href;
    if (path.includes('/#/')) this.$router.push(`${path.split('#').slice(-1)}`);

    if (this.me.isInDevEnvironment) {
      this.setClient(window.location.pathname.substring(1));
    }
    if (this.showAdminDashboard) {
      this.initAdmin();
    } else {
      this.setStore();
      this.setDefaultPermissions();
      this.setUserInAppcues();
      this.initYellowfin();
      this.initZoho();
      this.eventHub.$on('downloadFile', ({ endpoint, uuid, filename }) => this.downloadFile(endpoint, uuid, filename));
    }
  },
  beforeDestroy() {
    this.eventHub.$off('downloadFile');
  },
  watch: {
    notify(value) {
      if (value !== false) return;
      this.clearSnackbar();
    },
    '$vuetify.breakpoint.name': {
      immediate: true,
      handler(value) {
        this.setIsMobile(value == 'xs' || value == 'sm');
      },
    },
    '$store.state.app.snackbarOptions': {
      immediate: true,
      handler() {
        if (this.snackbarOptions && this.snackbarOptions.message) {
          this.notify = true;
        }
      },
    },
  },
};
</script>

<style lang="scss">
.progress {
  margin-top: 5px;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
}

.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

#zohohc-asap-web-launcherbox {
  margin: 0px 0px 75px 0px;
}

.v-menu__content {
  z-index: 250 !important;
}

.v-item-group.v-bottom-navigation {
  justify-content: space-around;
}

.v-item-group.v-bottom-navigation .v-btn {
  min-width: 70px !important;
}
</style>
