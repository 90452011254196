<template>
  <v-app>
    <!-- header bar -->
    <top-bar :class="isMobile ? 'mobile-wrapper' : 'wrapper'">
      <template #submenu>
        <router-view name="submenu"></router-view>
      </template>
    </top-bar>
    <!-- main -->
    <v-main>
      <router-view />
    </v-main>
    <!-- footer -->
    <!-- <v-footer app></v-footer> -->
  </v-app>
</template>

<script>
import Vue from 'vue';
import TopBar from '@/layouts/TopBar';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'MainLayout',
  inject: ['eventHub'],
  components: { TopBar },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters('app', ['isMobile']),
  },
});
</script>

<style scoped>
.wrapper {
  margin: 0px;
  position: sticky;
  top: 0px;
  z-index: 200;
}

.mobile-wrapper {
  margin: 0px 0px 56px 0px;
}
</style>
