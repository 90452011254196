<template>
  <v-app>
    <gdic-login v-if="authentication === 'gdic'" :token="resetToken" :client="client" />
    <tam-login v-if="authentication === 'tam'" :token="resetToken" :client="client" />
    <div v-if="!authentication" class="defaultLayout">
      <TravelTrackerLogo class="mainLogo" />
    </div>
  </v-app>
</template>

<script>
import gdicLogin from '@/views/Login/gdicLogin';
import tamLogin from '@/views/Login/tamLogin';
import TravelTrackerLogo from '@/components/shared/Logo.vue';
import api from '../src/shared/api.service';
export default {
  name: 'Login',
  components: {
    gdicLogin,
    tamLogin,
    TravelTrackerLogo,
  },
  data: () => ({
    client: '',
    resetToken: '',
    authentication: '',
  }),
  async mounted() {
    const path = window.location.pathname.split('/')[1];
    if (!path) return;
    const { client, authentication, token } = await api.get(`/startup/${path}`);
    this.client = client;
    this.authentication = authentication;
    this.resetToken = token;
  },
};
</script>

<style scoped>
.defaultLayout {
  background: linear-gradient(to bottom, #00294d 0%, #009eec 100%);
  display: grid;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.mainLogo {
  padding: 10px;
  border-radius: 10px;
  background-color: whitesmoke;
  max-width: 800px;
  width: 25vw;
}
</style>
