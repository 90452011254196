import Vue from 'vue';
import Vuelidate from 'vuelidate';
import { marked } from 'marked';

import GoogleMapsLoader from '@/libs/google-maps';

import App from './App.vue';
import Login from './Login.vue';
import router from './router';
import store from './store';

import '@/sass/style.scss';

import socket from '@/shared/socket-io';
import api from '@/shared/api.service';
import vuetify from '@/plugins/vuetify';
import confirm from '@/plugins/confirm.js';
import alert from '@/plugins/alert.js';

Vue.use(Vuelidate);

Vue.use(confirm, { vuetify });
Vue.use(alert, { vuetify });

Vue.config.productionTip = false;
Vue.config.devtools = process.env.VUE_APP_SHOW_DEV_FEATURES === 'true';

Vue.mixin({
  methods: {
    md: (input) => {
      return marked(input);
    },
  },
});

api.init();

const preload = async () => {
  // need logged in user permissions for router
  try {
    await store.dispatch('user/getMyself');

    const googleCore = await GoogleMapsLoader.importLibrary('core');
    const googlePlaces = await GoogleMapsLoader.importLibrary('places');

    new Vue({
      router,
      store,
      vuetify,
      data: () => ({
        eventHub: new Vue(),
        googleCore: googleCore,
        googlePlaces: googlePlaces,
      }),
      mounted() {
        socket.init(this.eventHub, store.getters['user/me']);
      },
      render: (h) => h(App),
      provide() {
        return {
          eventHub: this.eventHub,
          googleCore: this.googleCore,
          googlePlaces: this.googlePlaces,
        };
      },
    }).$mount('#app');
  } catch (err) {
    new Vue({
      vuetify,
      render: (h) => h(Login),
    }).$mount('#app');
  }
};

preload();
