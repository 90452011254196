export default {
  getAdditionalVehicleCharges,
  saveAdditionalVehicleCharge,
  deleteAdditionalVehicleCharges,
};

import api from '@/shared/api.service';

async function getAdditionalVehicleCharges(options) {
  return await api.get('/api/additional-vehicle-charge' + (options && options.forTable ? '?table=1' : ''));
}

async function saveAdditionalVehicleCharge(params) {
  return await api.post('/api/additional-vehicle-charge', params);
}

async function deleteAdditionalVehicleCharges(ids) {
  return await api.delete('/api/additional-vehicle-charge', {
    params: { ids },
  });
}
