import driverLogApi from '@/apis/driverLog';

export const GET_DRIVER_LOGS = 'getDriverLogs';
export const SAVE_DRIVER_LOG = 'saveDriverLog';

export const actions = {
  async [GET_DRIVER_LOGS]({ commit }) {
    const result = await driverLogApi.getDriverLogs();
    commit('setDriverLogs', result);
  },
  async [SAVE_DRIVER_LOG](_, obj) {
    return await driverLogApi.saveDriverLog(obj);
  },
};
