import _ from 'lodash';

export const getters = {
  allDestinations(state) {
    return [...state.destinations, ...state.prospectiveDestinations];
  },
  destinations(state) {
    return state.destinations;
  },
  destinationsById(state) {
    const byId = { 0: { name: 'No Destination' } };
    [...state.destinations, ...state.prospectiveDestinations].forEach((e) => (byId[e.id] = e));
    return byId;
  },
  destinationsByName(state) {
    const byName = {};
    state.destinations.forEach((e) => (byName[e.name.toLowerCase()] = e));
    return byName;
  },
  destinationCategories(state) {
    const items = _.uniq(state.destinations.filter((e) => e.category).map((e) => e.category));
    return [{ header: 'Select an existing category or enter a new one' }, ...items];
  },
  prospectiveDestinations(state) {
    return state.prospectiveDestinations;
  },
};
