import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { state } from './state';

const BudgetCodeModule = {
  actions,
  state,
  namespaced: true,
  mutations,
  getters,
};

export default BudgetCodeModule;
